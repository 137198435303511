import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AccessTokenRequest, getAccessToken } from '../api/auth'

export type AuthState = {
   token: string
   isRefreshing: boolean
}

const initialState: AuthState = {
   token: '',
   isRefreshing: false,
}

export const refreshToken = createAsyncThunk('refreshToken', async (request: AccessTokenRequest) => {
   const response = await getAccessToken(request)
   return response
})

export const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {},
   extraReducers(builder) {
      builder
         .addCase(refreshToken.pending, (state) => {
            state.isRefreshing = true
         })
         .addCase(refreshToken.fulfilled, (state, action) => {
            state.token = action.payload.access_token
            state.isRefreshing = false
         })
   },
})

export default authSlice.reducer
