import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { Button, CounterSelector } from 'ui'
import { Controller, useFormContext } from 'react-hook-form'
import { BookerContext, ServiceBookerFormValues } from '../Booker'
import { DateRangePicketPropsType, DateRangeSelector } from '../Booker/components/DateRangeSelector'
import { getPropertyValue } from 'utils/validation'
import classNames from 'classnames'
import { SingleDateSelector } from '../Booker/components/SingleDateSelector'
import { ChildrenAgesSelectorProps, Guest, RoomsSelectorProps } from './Guest'

export type ServiceFormType = {
   hotelId?: number
   currency: string
   type?: 'TRANSFER' | 'GENERIC' | 'DAY_PASS'
   serviceId?: number
   date?: string
   startDate?: string
   endDate?: string
   guests?: {
      adults: number
      children: number
      childrenAges: Array<{ age?: number }>
   }
}

export type DetailedServiceFormType = ServiceFormType & {
   maxAdults: number | null
   maxChildren: number | null
   hasQuantity: boolean | null
   maxQuantity: number | null
   minQuantity: number | null
   quantity: number | null
   hasNumberPersons: boolean | null
   allotmentDates: Array<string>
   isRangeDate: boolean | null
   onSubmit: Function
   startDateId?: string
   endDateId?: string
   minChildrenAge: number | null
   maxChildrenAge: number | null
   noChildrenAges: boolean | null
   dateId?: string
}

type Props = {
   hasNumberPersons: boolean | null
   hasQuantity?: boolean | null
   isRangeDate?: boolean | null
   isLoading?: boolean
   childrenAgesSelectorProps: ChildrenAgesSelectorProps
   roomsSelectorProps: RoomsSelectorProps
   datePickerProps?: {
      placeholder?: string
      displayFormat?: string
      showClearDate: boolean
      showDefaultInputIcon: boolean
      isDayBlocked?: Function
   }
   dateRangePickerProps?: DateRangePicketPropsType
   minQuantity?: number | null
   maxQuantity?: number | null
}

const ServiceForm = ({
   hasNumberPersons,
   hasQuantity,
   isRangeDate,
   isLoading,
   roomsSelectorProps,
   childrenAgesSelectorProps,
   dateRangePickerProps,
   datePickerProps,
   minQuantity,
   maxQuantity,
}: Props) => {
   const intl = useIntl()
   const { useTailwindPrefix, locale, isMobile } = useContext(BookerContext)
   const {
      formState: { errors },
   } = useFormContext<ServiceBookerFormValues>()

   return (
      <div
         className={classNames({
            'w-full min-w-[250px]': !useTailwindPrefix,
            'tw-w-full tw-min-w-[250px]': useTailwindPrefix,
         })}
      >
         <div
            className={classNames({
               'mb-2.5': !useTailwindPrefix,
               'tw-mb-2.5': useTailwindPrefix,
            })}
         >
            {intl.formatMessage({ id: `booker.dayPass.${isRangeDate ? 'chooseDates' : 'chooseDate'}` })}
            <div
               className={classNames({
                  'mt-2.5': !useTailwindPrefix,
                  'tw-mt-2.5': useTailwindPrefix,
               })}
            >
               {isRangeDate && dateRangePickerProps && (
                  <Controller
                     name="dates"
                     rules={{
                        required: intl.formatMessage({ id: 'booker.errors.dates' }),
                        validate: {
                           emptyStart: (v) => {
                              return (
                                 (v && v.startDate && v.startDate !== '') ||
                                 intl.formatMessage({ id: `booker.errors.select.start` })
                              )
                           },
                           emtpyEnd: (v) =>
                              (v && v.endDate && v.endDate !== '') ||
                              intl.formatMessage({ id: `booker.errors.select.end` }),
                        },
                     }}
                     render={({ field: { value, onChange } }) => (
                        <DateRangeSelector
                           id="dates"
                           value={value}
                           onChange={onChange}
                           locale={locale}
                           dateRangePickerProps={dateRangePickerProps}
                           myErrors={getPropertyValue(errors, 'dates')}
                           isMobile={isMobile}
                           numberOfMonths={1}
                           useTailwindPrefix={useTailwindPrefix}
                           vertical
                        />
                     )}
                  />
               )}
               {!isRangeDate && datePickerProps && (
                  <Controller
                     name="date"
                     rules={{
                        required: intl.formatMessage({ id: 'booker.dayPass.errors.date' }),
                     }}
                     render={({ field: { value, onChange } }) => (
                        <SingleDateSelector
                           id="date"
                           value={value}
                           onChange={onChange}
                           locale={locale}
                           singleDatePickerProps={datePickerProps}
                           myErrors={getPropertyValue(errors, 'date')}
                           isMobile={isMobile}
                           numberOfMonths={1}
                           useTailwindPrefix={useTailwindPrefix}
                           vertical
                        />
                     )}
                  />
               )}
            </div>
         </div>

         {hasNumberPersons && roomsSelectorProps && (
            <Guest roomsSelectorProps={roomsSelectorProps} childrenAgesSelectorProps={childrenAgesSelectorProps} />
         )}
         {hasQuantity && minQuantity && maxQuantity && (
            <div
               className={classNames({
                  'py-4 flex items-center justify-between': !useTailwindPrefix,
                  'tw-py-4 tw-flex tw-items-center tw-justify-between': useTailwindPrefix,
               })}
            >
               <div>
                  {intl.formatMessage({
                     id: 'booker.quantity',
                  })}
               </div>
               <Controller
                  name={`quantity`}
                  render={({ field: { value, onChange } }) => (
                     <CounterSelector
                        value={value}
                        minValue={minQuantity}
                        maxValue={maxQuantity}
                        onChange={onChange}
                        useTailwindPrefix={useTailwindPrefix}
                     />
                  )}
               />
            </div>
         )}

         <Button
            isLoading={isLoading}
            type="submit"
            showArrow={false}
            className={classNames({
               'mt-2.5 w-full text-primary-a11y hover:bg-primary-10 bg-primary whitespace-nowrap overflow-hidden text-ellipsis border border-primary':
                  !useTailwindPrefix,
               'tw-mt-2.5 tw-w-full tw-text-primary-a11y hover:tw-bg-primary-10 tw-bg-primary tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-border tw-border-primary':
                  useTailwindPrefix,
            })}
         >
            {intl.formatMessage({ id: 'booker.dayPass.continue' })}
         </Button>
      </div>
   )
}

export default ServiceForm
