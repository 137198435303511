import { AddOnQuoteType, AddOnType, AddOnVariationType } from "../partials/AddOn";
import { QuoteDataType } from "../partials/Services/ServiceSelector";

type AddOnQuoteRequestType = {
   addonId: number
   currency: string
   language: string
   startDate?: string
   endDate?: string
   date?: string
   people?: number
   adults?: number
   children?: Array<number>
   quantity?: number
}

export function getQuoteRequest(data: any, addon: AddOnType): AddOnQuoteRequestType {
   const { currency, language, guests, date, startDate, endDate, quantity } = data;
   const result = {
      addonId: addon.id,
      currency,
      language,
   } as AddOnQuoteRequestType;

   switch (addon.dateType) {
      case 'DATE_RANGE':
         result.startDate = startDate;
         result.endDate = endDate;
         break;
      case 'ONLY_DATE':
         result.date = date;
         break;
   }
   if (addon.hasNumberPersons) {
      switch (addon.chargeType) {
         case 'PERSON_PRICE':
            result.people = guests.adults;
            break;
         default:
            result.adults = guests.adults;
            result.children = guests.childrenAges.map((c: any) => c.age);
            break;
      }
   }
   if (addon.hasQuantity) {
      result.quantity = quantity;
   }
   return result;
}


export function getSaveTripRequest(addOn: AddOnType, item: AddOnQuoteType, currency: string, hotelId: number, language: string, quoteData: QuoteDataType) {
   let quantities = {};
   switch (addOn.chargeType) {
      case 'AGES_PRICE':
         quantities = {
            type: "PEOPLE",
            adults: quoteData.guests.adults,
            children: quoteData.guests.childrenAges.map(c => c.age)
         };
         break;
      case 'PERSON_PRICE':
         quantities = {
            type: "FIXED_PEOPLE",
            people: quoteData.guests.adults
         };
         break;
      case 'UNIT_PRICE':
         quantities = {
            type: "FIXED",
            value: quoteData.quantity
         };
         break;
   }
   return {
      total: item.amount, // 313.2,
      currency, // "MXN",
      language,
      hotelId, //1,
      items: [
         {
            serviceType: 'ADDON',
            id: item.id,
            schedule: {
               ...addOn.dateType === 'DATE_RANGE' ? {
                  type: 'TIME_FRAME',
                  startDate: quoteData.startDate,
                  endDate: quoteData.endDate
               } : {
                  type: 'DATE',
                  startDate: quoteData.date,
               }
            },
            quantities: [quantities]
         }
      ]
   };
}
