import classNames from 'classnames'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { BookerContext } from '../Booker'
import { Button } from 'ui'

const Counter = ({ children }: { children: React.ReactNode }) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   return (
      <div
         className={classNames({
            'py-2.5 px-0 flex items-center': !useTailwindPrefix,
            'tw-py-2.5 tw-px-0 tw-flex tw-items-center': useTailwindPrefix,
         })}
      >
         {children}
      </div>
   )
}
const Label = ({ children }: { children: React.ReactNode }) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   return (
      <div
         className={classNames({
            'flex-grow inline-block': !useTailwindPrefix,
            'tw-flex-grow tw-inline-block': useTailwindPrefix,
         })}
      >
         {children}
      </div>
   )
}
const Spacer = () => {
   const { useTailwindPrefix } = useContext(BookerContext)
   return (
      <div
         className={classNames({
            'h-[30px] inline-block': !useTailwindPrefix,
            'tw-h-[30px] tw-inline-block': useTailwindPrefix,
         })}
      ></div>
   )
}

export const ServiceBookerLoading = () => {
   const { useTailwindPrefix } = useContext(BookerContext)
   const intl = useIntl()
   return (
      <div>
         <div className={classNames({ 'mb-2.5': !useTailwindPrefix, 'tw-mb-2.5': useTailwindPrefix })}>
            {intl.formatMessage({ id: 'booker.dayPass.chooseDate' })}
         </div>
         <div
            className={classNames({
               'border py-[13px] px-3 mb-2.5 text-transparent': !useTailwindPrefix,
               'tw-border tw-py-[13px] tw-px-3 tw-mb-2.5 tw-text-transparent': useTailwindPrefix,
            })}
         >
            {intl.formatMessage({ id: 'booker.dayPass.date' })}
         </div>
         <Counter>
            <Label>{intl.formatMessage({ id: 'booker.adults' })}</Label>
            <Spacer />
         </Counter>
         <Counter>
            <Label>
               {intl.formatMessage({ id: 'booker.minors' })}
               <div
                  className={classNames({
                     'text-[85%] text-zinc-400': !useTailwindPrefix,
                     'tw-text-[85%] tw-text-zinc-400': useTailwindPrefix,
                  })}
               >
                  0 -{' '}
               </div>
            </Label>
            <Spacer />
         </Counter>
         <Button className={classNames({ 'w-full': !useTailwindPrefix, 'tw-w-full': useTailwindPrefix })} disabled>
            {intl.formatMessage({ id: 'booker.dayPass.continue' })}
         </Button>
      </div>
   )
}
