import classNames from 'classnames'
import React, { useContext } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { getPropertyValue } from 'utils/validation'
import { BookerContext, ServiceBookerFormValues } from '../Booker'
import { useIntl } from 'react-intl'
import { CounterSelector } from 'ui'
import { Select } from 'ui/fields'

export type ChildrenAgesSelectorProps = {
   minChildrenAge: number
   maxChildrenAge: number
}
export type RoomsSelectorProps = {
   maxRooms: number
   maxAdults: number
   maxChildren: number
   noChildrenAges?: boolean | null
}

type Props = {
   roomsSelectorProps: RoomsSelectorProps
   childrenAgesSelectorProps: ChildrenAgesSelectorProps
}

export const Guest = ({ roomsSelectorProps, childrenAgesSelectorProps }: Props) => {
   const intl = useIntl()
   const { useTailwindPrefix } = useContext(BookerContext)
   const {
      formState: { errors },
      control,
   } = useFormContext<ServiceBookerFormValues>()
   const { fields, append, remove } = useFieldArray({
      control: control,
      name: 'guests.childrenAges',
   })

   const myErrors = getPropertyValue(errors, 'guests')
   return (
      <>
         <div
            className={classNames({
               'py-3.5 flex items-center justify-between': !useTailwindPrefix,
               'tw-py-3.5 tw-flex tw-items-center tw-justify-between': useTailwindPrefix,
            })}
         >
            <div>
               {intl.formatMessage({
                  id: roomsSelectorProps.maxChildren === 0 ? 'booker.persons' : 'booker.adults',
               })}
            </div>
            <Controller
               name={`guests.adults`}
               render={({ field: { value, onChange } }) => (
                  <CounterSelector
                     value={value}
                     minValue={1}
                     maxValue={roomsSelectorProps.maxAdults}
                     onChange={onChange}
                     useTailwindPrefix={useTailwindPrefix}
                  />
               )}
            />
         </div>
         {roomsSelectorProps.maxChildren > 0 && (
            <div
               className={classNames({
                  'relative mb-2.5': !useTailwindPrefix,
                  'tw-relative tw-mb-2.5': useTailwindPrefix,
               })}
            >
               <div
                  className={classNames({
                     'py-3.5 flex items-center justify-between': !useTailwindPrefix,
                     'tw-py-3.5 tw-flex tw-items-center tw-justify-between': useTailwindPrefix,
                  })}
               >
                  <div>
                     {intl.formatMessage({
                        id: 'booker.minors',
                     })}
                     <span
                        className={classNames({
                           'block text-base text-gray-400': !useTailwindPrefix,
                           'tw-block tw-text-base tw-text-gray-400': useTailwindPrefix,
                        })}
                     >
                        {intl.formatMessage(
                           { id: 'booker.rooms.upToAge' },
                           { maxAge: childrenAgesSelectorProps.maxChildrenAge },
                        )}
                     </span>
                  </div>
                  <Controller
                     name={`guests.children`}
                     render={({ field: { value, onChange } }) => {
                        function onChangeEvent(count: number) {
                           const currentCount = fields.length
                           if (count > currentCount) {
                              Array.from({ length: count - currentCount }, (_, i) => i).forEach(() =>
                                 append({
                                    age: undefined,
                                 }),
                              )
                           } else {
                              for (let i = fields.length; i > fields.length - (currentCount - count); i--) {
                                 remove(i - 1)
                              }
                           }
                           onChange(count)
                        }
                        return (
                           <CounterSelector
                              value={value}
                              minValue={childrenAgesSelectorProps.minChildrenAge}
                              maxValue={childrenAgesSelectorProps.maxChildrenAge}
                              onChange={onChangeEvent}
                              useTailwindPrefix={useTailwindPrefix}
                           />
                        )
                     }}
                  />
               </div>

               {fields.length > 0 && (
                  <div
                     className={classNames({
                        'pt-2 pb-4 flex flex-wrap gap-4': !useTailwindPrefix,
                        'tw-pt-2 tw-pb-4 tw-flex tw-flex-wrap tw-gap-4': useTailwindPrefix,
                     })}
                  >
                     <div
                        className={classNames({
                           'basis-full': !useTailwindPrefix,
                           'tw-basis-full': useTailwindPrefix,
                        })}
                     >
                        {intl.formatMessage({ id: 'booker.rooms.minors.age' })}
                     </div>
                     {fields.map((item, ageIndex) => (
                        <div key={item.id}>
                           <Controller
                              name={`guests.childrenAges.${ageIndex}.age`}
                              rules={{ required: true }}
                              render={({ field: { value, onChange } }) => (
                                 <Select
                                    value={value}
                                    onChange={onChange}
                                    error={
                                       getPropertyValue(errors, `guests.childrenAges.${ageIndex}.age`)
                                          ? 'error'
                                          : undefined
                                    }
                                    showErrorText={false}
                                    useTailwindPrefix={useTailwindPrefix}
                                 >
                                    <Select.Option value="">--</Select.Option>
                                    {Array.from(
                                       { length: childrenAgesSelectorProps.maxChildrenAge + 1 },
                                       (_, i) => i + childrenAgesSelectorProps.minChildrenAge,
                                    ).map((age) => (
                                       <Select.Option key={age} value={age}>
                                          {age === 0 ? '< 1' : age}
                                       </Select.Option>
                                    ))}
                                 </Select>
                              )}
                           />
                        </div>
                     ))}
                  </div>
               )}
               {myErrors && (
                  <div
                     className={classNames({
                        'text-red-400 -mt-2.5 text-sm overflow-hidden text-ellipsis absolute': !useTailwindPrefix,
                        'tw-text-red-400 -tw-mt-2.5 tw-text-sm tw-overflow-hidden tw-text-ellipsis tw-absolute':
                           useTailwindPrefix,
                     })}
                  >
                     {myErrors.childrenAges?.some((c: any) => c?.age?.type === 'required')
                        ? intl.formatMessage({ id: 'booker.errors.selectMinorsAge' })
                        : intl.formatMessage({ id: 'booker.errors.correctFields' })}
                  </div>
               )}
            </div>
         )}
      </>
   )
}
