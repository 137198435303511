import { useEffect } from 'react'
import useEventListener from '../hooks/useEventListener'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { clear, closePopUpPromotion, getPopUpPromotionThunk, showPopUpPromotion } from '../features/popUpPromotionSlice'
import useInactivityUser from '../hooks/useInactivityUser'
import { PromotionPopup, PopupBehaviourType, Promotion } from 'booker'
import { Modal } from 'ui'

type Props = {
   onApplyPromo: any
   hotelId?: number
}

const PromotionModal = (props: Props) => {
   const { onApplyPromo, hotelId } = props
   const popUpPromotion = useAppSelector((state) => state.popUpPromotion)
   const [isAlmosExit] = useEventListener()
   const [isInactivity] = useInactivityUser(popUpPromotion.value?.popupInactiveInSeconds || null)
   const dispatch = useAppDispatch()

   function hidePopUp() {
      dispatch(closePopUpPromotion())
   }

   useEffect(() => {
      if (popUpPromotion.value?.popupBehaviourType === PopupBehaviourType.EXIT_POPUP) {
         if (isAlmosExit) {
            dispatch(showPopUpPromotion())
         }
      }
      if (popUpPromotion.value?.popupBehaviourType === PopupBehaviourType.INACTIVITY) {
         if (isInactivity) {
            dispatch(showPopUpPromotion())
         }
      }
   }, [dispatch, isAlmosExit, isInactivity, popUpPromotion.value])

   function applyPromo(promotion: Promotion) {
      onApplyPromo(promotion.code)
      hidePopUp()
   }

   useEffect(() => {
      dispatch(getPopUpPromotionThunk({ hotelId }))
      return () => {
         dispatch(clear())
      }
   }, [dispatch, hotelId])

   if (
      popUpPromotion.isFetching ||
      !popUpPromotion.value ||
      (popUpPromotion.alreadyAplied && popUpPromotion.hasBeenShown)
   ) {
      return null
   }

   return (
      <Modal
         visible={popUpPromotion.isOpen && !popUpPromotion.hasBeenShown}
         onClose={hidePopUp}
         outerClassName={'md:tw-w-[600px] md:tw-my-[30px] lg:tw-w-[600px]'}
         useTailwindPrefix
         portalClassName={'itm-promotion-portal'}
         size="md"
      >
         <PromotionPopup promotion={popUpPromotion.value} onApply={applyPromo} onCancel={hidePopUp} useTailwindPrefix />
      </Modal>
   )
}

export default PromotionModal
