import moment, { Moment } from 'moment'
import { ChainBookerType, DestinationBookerType, HotelBookerType } from '../partials/Booker/components/HotelSelector'
import { groupBy, sortBy } from 'utils'
import { RoomFormType } from '../partials/Booker/components/RoomsSelector'
import { DetailedServiceFormType, ServiceFormType } from '../partials/Services/ServiceForm'
import { AddOnType } from '../partials/AddOn'

export function getGroupedPlaces(hotelList: Array<HotelBookerType>) {
   const grouped = groupBy(hotelList, (hotel: HotelBookerType) => hotel.destination.id)
   const result = Object.entries(grouped)
      .map((item: any) => ({
         destination: item[1].find((h: any) => h.destination.id === parseInt(item[0])).destination as DestinationBookerType,
         hotels: item[1].sort(sortBy('name')) as Array<HotelBookerType>,
         nonSelectable: item[1].every((h: any) => h.isExternal)
      }))
      .sort(sortBy((item: any) => item.destination.name))
   return result
}

export function getGroupedChains(hotelList: Array<HotelBookerType>) {
   const grouped = groupBy(hotelList, (hotel: HotelBookerType) => hotel.chain?.id || 0)
   const result = Object.entries(grouped)
      .map((item: any) => ({
         chain: parseInt(item[0]) > 0 ? item[1].find((h: any) => h.chain?.id === parseInt(item[0])).chain as ChainBookerType : null,
         items: getGroupedPlaces(item[1].sort(sortBy('name')) as Array<HotelBookerType>)
      }))
      .sort(sortBy((item: any) => !item.chain))
   return result
}

function getMonthsBetweenDates(strDate: Moment, eDate: Moment, lastProcessedDate?: string) {
   const startDate = moment(strDate);
   const endDate = moment(eDate);
   const lastDay = lastProcessedDate ? moment(lastProcessedDate) : moment().add(6, 'months');
   let months = [];

   while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
      if (startDate.startOf('day').isBefore(lastDay) || startDate.isSame(lastDay)) {
         months.push(startDate.format('YYYY-MM'));
      }
      startDate.add(1, 'month');
   }

   return months;
}

type Props = {
   lastProcessedDate?: string
   months: Array<string>
}

export function getDatesToQuote(dates: { startDate?: string, endDate?: string } | null, hotelBookerRates: Props) {
   const format = 'YYYY-MM-DD';
   let sDay = moment();
   let lDay = moment().add(3, 'months').date(0);

   if (dates && dates.startDate && dates.endDate) {
      sDay = moment(dates.startDate);
      lDay = moment(dates.endDate);
   }
   const months = getMonthsBetweenDates(sDay, lDay, hotelBookerRates.lastProcessedDate);

   const monthsToQuote = months.filter(value => !hotelBookerRates.months.includes(value))
   const datesToQuote: Array<Moment> = [];


   monthsToQuote.forEach(month => {
      const d = month.split('/');
      const date = moment(`${d[1]}-${d[0]}-01`, format);
      if (moment().startOf('month').isBefore(date) || moment().startOf('month').isSame(date)) {
         datesToQuote.push(date);
      }
   });

   if (datesToQuote.length > 0) {
      const initialDate = moment(moment.min(datesToQuote).format(format), format);
      const finalDate = moment(moment.max(datesToQuote).endOf('month').format(format), format);

      return {
         startDate: initialDate.format(format),
         endDate: finalDate.format(format),
         months: monthsToQuote
      };
   }

   return null;
}

export function getTotalAdults(rooms: Array<RoomFormType>) {
   let adults: number = rooms.reduce((totalAdults, room: RoomFormType) => {
      return (totalAdults += room.adults ? room.adults : 0)
   }, 0)
   return adults > 0 ? adults : 0
}

export function getTotalChildren(rooms: Array<RoomFormType>) {
   let childrens: number = rooms.reduce((totalChildren: number, room: RoomFormType) => {
      return (totalChildren += room.children ? room.children : 0)
   }, 0)
   return childrens > 0 ? childrens : 0
}

export function getDetailedServiceForm(serviceForm: ServiceFormType, addOn: AddOnType, onSubmit: Function): DetailedServiceFormType {
   let newData = Object.assign({}, serviceForm) as DetailedServiceFormType;
   switch (addOn.numberPersonsStandaloneType) {
      case 'NOT_RESTRICTED':
         newData.maxAdults = -1;
         newData.maxChildren = -1;
         break;
      case 'RESTRICTED':
         if ((addOn.maxAdults && addOn.maxAdults > 0) || (addOn.maxChildren && addOn.maxChildren > 0)) {
            newData.maxAdults = addOn.maxAdults;
            newData.maxChildren = addOn.maxChildren;
         }
         break;
   }
   switch (addOn.chargeType) {
      case 'PERSON_PRICE':
         newData.maxChildren = 0;
         break;
   }
   if (addOn.hasQuantity) {
      newData.hasQuantity = true;
      newData.maxQuantity = addOn.maxQuantity;
      newData.minQuantity = addOn.minQuantity;
      newData.quantity = addOn.minQuantity;
   }
   newData.hasNumberPersons = addOn.hasNumberPersons;
   newData.allotmentDates = addOn.allotmentDates;
   newData.isRangeDate = addOn.dateType === 'DATE_RANGE';

   return {
      ...newData,
      onSubmit: onSubmit(addOn),
   };
}

export function getExtraParamsFromQuery(query: Record<string, any>, others?: Record<string, any>, utmOnly?: boolean): Record<string, any> | null {
   if (!query) {
      return null;
   }

   const utm_keys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content"
   ];

   const keys = utmOnly ? utm_keys : [
      "_ga",
      "_gl",
      "_ga_session_id",
      "_ga_session_number",
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
      "gclid",
      "dclid",
      "_gac",
      "_gi",
      "_gp",
      "_gid",
      "fbclid",
      "no_redirect",
      "channelId"
   ];

   let result: Record<string, any> | null = keys.reduce((acc, key) => {
      if (query[key]) {
         acc[key] = query[key];
      }
      return acc;
   }, {} as Record<string, any>);

   if (others) {
      Object.entries(others).forEach(([key, value]) => {
         if (value) {
            if (!result) {
               result = {};
            }
            result[key] = value;
         }
      });
   }

   return result;
}