import React, { ChangeEvent, ReactNode } from 'react'
import './switch.css'

type Props = {
   children: ReactNode
   id: string
   disabled?: boolean
   checked: boolean
   onChange: Function
   className?: string
}

export const Switch = ({ children, id, disabled, checked, onChange, className }: Props) => {
   function onChangeEvent(event: ChangeEvent<HTMLInputElement>) {
      onChange(event.target.checked)
   }

   return (
      <div {...(className && { className })}>
         <input
            type="checkbox"
            className="react-switch-checkbox"
            id={id}
            disabled={disabled}
            onChange={onChangeEvent}
            checked={checked}
         />
         <label className="inline-block react-switch-label" htmlFor={id}>
            {children}
         </label>
      </div>
   )
}
