import classNames from 'classnames'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { Amount, Button } from 'ui'
import { BookerContext } from '../Booker'

export type AddOnVariationType = {
   id: number
   name: string
   description: string | null
   amount: number
}

export type AddOnType = {
   id: number
   title: string
   description: string
   addonType: 'TRANSFER' | 'GENERIC' | 'DAY_PASS'
   chargeType: 'AGES_PRICE' | 'PERSON_PRICE' | 'UNIT_PRICE'
   dateType: 'DATE_RANGE' | 'ONLY_DATE' | null
   isActive: boolean
   variations: Array<AddOnVariationType>
   hasQuantity: boolean | null
   hasNumberPersons: boolean | null
   maxAdults: number | null
   maxChildren: number | null
   maxQuantity: number | null
   minQuantity: number | null
   allotmentDates: Array<string>
   numberPersonsStandaloneType: 'RESTRICTED' | 'NOT_RESTRICTED' | null
}

export type AddOnQuoteType = {
   id: number
   addonType: 'TRANSFER' | 'GENERIC' | 'DAY_PASS'
   error: boolean
   errors: Array<string>
   quantity: number
   people: number
   amount: number
}

type Props = {
   addOn: AddOnType
   item: AddOnQuoteType
   content: {
      title: string
      description: string
   }
   currency: string
   selectedVariationId: number
   onSelect: Function
}

export const AddOn = ({ item, content, currency, onSelect, selectedVariationId, addOn }: Props) => {
   const intl = useIntl()
   const { useTailwindPrefix } = useContext(BookerContext)

   const onSelectEvent = () => {
      onSelect(item)
   }

   return (
      <div>
         <div className={classNames({ 'mb-3': !useTailwindPrefix, 'tw-mb-3': useTailwindPrefix })}>
            <strong>{content.title}</strong>
         </div>
         <ul
            className={classNames({ 'list-none pl-0': !useTailwindPrefix, 'tw-list-none tw-pl-0': useTailwindPrefix })}
         >
            <li>
               {content.description && (
                  <div
                     className={classNames({
                        'text-[95%] mb-4 opacity-80 leading-6': !useTailwindPrefix,
                        'tw-text-[95%] tw-mb-4 tw-opacity-80 tw-leading-6': useTailwindPrefix,
                     })}
                  >
                     {content.description.length > 130
                        ? `${content.description.substring(0, 130)}...`
                        : content.description}
                  </div>
               )}
               <div
                  className={classNames({
                     'flex justify-between': !useTailwindPrefix,
                     'tw-flex tw-justify-between': useTailwindPrefix,
                  })}
               >
                  {addOn.hasQuantity && (
                     <span>{intl.formatMessage({ id: 'daypass.numberOf.item' }, { total: item.quantity })}</span>
                  )}
                  {addOn.hasNumberPersons && (
                     <span>{intl.formatMessage({ id: 'daypass.numberOf' }, { total: item.people })}</span>
                  )}
                  <Amount amount={item.amount} currency={currency} />
               </div>
            </li>
         </ul>
         <Button
            className={classNames({ 'w-full mt-2.5': !useTailwindPrefix, 'tw-w-full tw-mt-2.5': useTailwindPrefix })}
            bsStyle="primary"
            onClick={onSelectEvent}
            showArrow={false}
            isLoading={selectedVariationId === item.id}
            disabled={selectedVariationId > 0}
         >
            {intl.formatMessage({ id: 'daypass.book' })}
         </Button>
      </div>
   )
}
