import { createAsyncThunk } from '@reduxjs/toolkit'
import { CreateTripRequest, createTrip } from '../api/trip'

export const createTripThunk = createAsyncThunk(
   'createTrip',
   async (
      request: CreateTripRequest,
      { rejectWithValue },
   ) => {
      const response = await createTrip(request)
      if (response) {
         return response
      }
      return rejectWithValue(null)
   },
)
