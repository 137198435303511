import { apiCall } from "../utils/api"

export type CreateTripRequest = {
   total: number
   currency: string
   language: string
   hotelId: number
   items: Array<{
      id: number
      serviceType: 'ADD_ON'
      schedule: {
         type: 'DATE' | 'TIME_FRAME'
         startDate: string
         endDate?: string
      }
      quantities: Array<{
         type: 'PEOPLE' | 'FIXED_PEOPLE' | 'FIXED'
         value: number
         adults?: number
         children?: Array<number>
      }>
   }>
}

export type CreateTripResonse = {
   uuid: string
}

export async function createTrip(request: CreateTripRequest): Promise<CreateTripResonse | null> {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_BOOKING_API}/trips`,
         method: 'POST',
         data: request
      })
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (error) {
      return null
   }
}

