import React, { useContext, useEffect, useState } from 'react'
import { BookerContext, ServiceBookerFormValues } from '../Booker'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { HotelBookerType } from '../Booker/components/HotelSelector'
import ServiceForm, { ServiceFormType } from './ServiceForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { getQuoteRequest, getSaveTripRequest } from '../../utils/addOnUtils'
import { AddOn, AddOnQuoteType, AddOnType, AddOnVariationType } from '../AddOn'
import { AddOnEmpty } from '../AddOn/AddOnEmpty'
import { getDetailedServiceForm } from '../../utils/bookerUtils'
import { ServiceBooker } from './ServiceBooker'
import { ServiceBookerLoading } from './ServiceBookerLoading'

export type AddOnQuoteListType = {
   items: Array<AddOnQuoteType>
   amount: number
   error: boolean
   errors: Array<string>
   hotelId: number | null
}

export type AddOnListWrapper = {
   isFetching: boolean
   isPristine: boolean
   items: Array<AddOnType>
}

export type QuoteListWrapper = {
   data: AddOnQuoteListType | null
   isFetching: boolean
   isPristine: boolean
   hasError: boolean
}

export type QuoteDataType = {
   startDate?: string
   endDate?: string
   date?: string
   quantity: number
   guests: {
      adults: number
      childrenAges: Array<{
         age: number
      }>
   }
}

type Props = {
   getAddOnList: Function
   serviceForm: ServiceFormType
   hotelList: Array<HotelBookerType>
   onSubmit: Function
   addOnListWrapper: AddOnListWrapper
   clearAddOnList: Function
   quoteListWrapper: QuoteListWrapper
   clearQuoteList: Function
   onQuote: Function
}

const Group = ({ children }: { children: React.ReactNode }) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   return (
      <div
         className={classNames({
            'flex flex-col pl-0 mb-0 last:rounded-b': !useTailwindPrefix,
            'tw-flex tw-flex-col tw-pl-0 tw-mb-0 last:tw-rounded-b': useTailwindPrefix,
         })}
      >
         {children}
      </div>
   )
}

Group.Item = ({ children, onClick }: { children: React.ReactNode; onClick: Function }) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   const onClickEvent = () => {
      onClick()
   }
   return (
      <div
         className={classNames({
            'relative flex justify-between items-center py-3 px-5 bg-white border-b last:border-b-0 last:rounded-b-[inherit] cursor-pointer':
               !useTailwindPrefix,
            'tw-relative tw-flex tw-justify-between tw-items-center tw-py-3 tw-px-5 tw-bg-white tw-border-b last:tw-border-b-0 last:tw-rounded-b-[inherit] tw-cursor-pointer':
               useTailwindPrefix,
         })}
         {...(onClick && { onClick: onClickEvent })}
      >
         {children}
      </div>
   )
}

const Title = ({ children, single }: { children: React.ReactNode; single?: boolean }) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   return (
      <div
         className={classNames({
            'relative flex justify-between py-3 px-5 bg-white border-b last:border-b-0 last:rounded-b-[inherit] font-semibold':
               !useTailwindPrefix,
            'tw-relative tw-flex tw-justify-between tw-py-3 tw-px-5 tw-bg-white tw-border-b last:tw-border-b-0 last:tw-rounded-b-[inherit] tw-font-semibold':
               useTailwindPrefix,
         })}
      >
         {single ? (
            <div
               className={classNames({
                  'grow text-center': !useTailwindPrefix,
                  'tw-grow tw-text-center': useTailwindPrefix,
               })}
            >
               {children}
            </div>
         ) : (
            children
         )}
      </div>
   )
}

const Back = ({ children, onClick }: { children: React.ReactNode; onClick: Function }) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   const onClickEvent = () => {
      onClick()
   }
   return (
      <div
         className={classNames({
            'cursor-pointer px-[10px] py-[20px] -mx-[12px] -my-[20px]': !useTailwindPrefix,
            'tw-cursor-pointer tw-px-[10px] tw-py-[20px] -tw-mx-[12px] -tw-my-[20px]': useTailwindPrefix,
         })}
         {...(onClick && { onClick: onClickEvent })}
      >
         {children}
      </div>
   )
}

const QuoteList = ({ children }: { children: React.ReactNode }) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   return (
      <ul
         className={classNames({
            'flex flex-col pl-0 mb-2 rounded-none': !useTailwindPrefix,
            'tw-flex tw-flex-col tw-pl-0 tw-mb-2 tw-rounded-none': useTailwindPrefix,
         })}
      >
         {children}
      </ul>
   )
}

QuoteList.Item = ({ children }: { children: React.ReactNode }) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   return (
      <li
         className={classNames({
            'border-b relative block py-3 px-5 bg-white last:border-b-0': !useTailwindPrefix,
            'tw-border-b tw-relative tw-block tw-py-3 tw-px-5 tw-bg-white last:tw-border-b-0': useTailwindPrefix,
         })}
      >
         {children}
      </li>
   )
}

const ServiceSelector = ({
   serviceForm,
   hotelList,
   getAddOnList,
   addOnListWrapper,
   clearAddOnList,
   quoteListWrapper,
   clearQuoteList,
   onQuote,
   onSubmit,
}: Props) => {
   const { hotelId, currency, type, serviceId: addOnId } = serviceForm
   const { useTailwindPrefix, locale } = useContext(BookerContext)
   const [selectedHotelId, setSelectedHotelId] = useState(0)
   const [serviceId, setServiceId] = useState(0)
   const [variationId, setVariationId] = useState(0)
   const [quoteData, setQuoteData] = useState<QuoteDataType>({} as QuoteDataType)
   const [showResume, setShowResume] = useState(false)
   const intl = useIntl()

   useEffect(() => {
      // if ((serviceForm.hotelId && serviceForm.hotelId !== 0) || selectedHotelId !== 0) {
      getAddOnList({
         hotelId: hotelId || selectedHotelId,
         type,
         currency,
         standalone: true,
      })
      // }
   }, [selectedHotelId])

   function onReturnServices() {
      setSelectedHotelId(0)
      clearAddOnList()
   }

   function onReturn(returnToService: boolean) {
      if (quoteListWrapper.isPristine) {
         setServiceId(0)
      }
      setShowResume(false)
      clearQuoteList()
      if (!returnToService) {
         setSelectedHotelId(0)
      }
   }

   function onChangeDates(value: boolean) {
      setShowResume(value)
      if (!value) {
         clearQuoteList()
      }
   }

   const onQuoteEvent = (addOn: AddOnType) => (data: any) => {
      setQuoteData(data)
      const request = getQuoteRequest(
         {
            currency,
            language: locale,
            ...data,
         },
         addOn,
      )
      onQuote && onQuote(request)
   }

   const reserve = (addOn: AddOnType, dataHotelId: number | null) => (item: AddOnQuoteType) => {
      let defaultHotelId = dataHotelId
      if (!defaultHotelId || defaultHotelId <= 0) {
         defaultHotelId = hotelId || selectedHotelId
      }
      const request = getSaveTripRequest(
         addOn,
         item,
         currency,
         defaultHotelId, // en caso de que el addon no traiga hotelId se usa el seleccionado
         locale,
         quoteData,
      )
      setVariationId(item.id)
      if (onSubmit && typeof onSubmit === 'function') {
         onSubmit(request, () => setVariationId(0))
      }
   }

   function drawQuoteList(addOn: AddOnType) {
      const items = quoteListWrapper.data ? quoteListWrapper.data.items : []
      const error = quoteListWrapper.data && quoteListWrapper.data.error
      const hotelId = quoteListWrapper.data ? quoteListWrapper.data.hotelId : null

      if (!quoteListWrapper.isFetching && quoteListWrapper.data && (items.length === 0 || error)) {
         return (
            <QuoteList>
               <QuoteList.Item>
                  <AddOnEmpty
                     error={quoteListWrapper.hasError || error}
                     errorText={error ? quoteListWrapper.data.errors.join('. ') : null}
                  />
               </QuoteList.Item>
            </QuoteList>
         )
      } else {
         return (
            <QuoteList>
               {items.map((item) => {
                  let content = {
                     title: addOn.title,
                     description: addOn.description,
                  }
                  if (addOn.variations.length > 1) {
                     let variation = addOn.variations.find((variation) => variation.id === item.id)
                     if (variation) {
                        content = { title: variation.name, description: variation.description || '' }
                     }
                  }
                  if (item.error) {
                     return (
                        <QuoteList.Item key={item.id}>
                           <AddOnEmpty error={item.error} />
                        </QuoteList.Item>
                     )
                  }
                  return (
                     <QuoteList.Item key={item.id}>
                        <AddOn
                           item={item}
                           content={content}
                           currency={currency}
                           onSelect={reserve(addOn, hotelId)}
                           selectedVariationId={variationId}
                           addOn={addOn}
                        />
                     </QuoteList.Item>
                  )
               })}
            </QuoteList>
         )
      }
   }

   function drawBooker() {
      const services = addOnListWrapper.items.filter((item) => {
         return item.isActive === true && (addOnId && addOnId !== 0 ? item.id === addOnId : true)
      })
      if (serviceId > 0 || services.length === 1) {
         const service = serviceId > 0 ? services.find((service) => service.id === serviceId) : services[0]
         return (
            <>
               {(services.length > 1 || (hotelList?.length > 0 && selectedHotelId > 0)) && (
                  <Title>
                     <Back onClick={() => onReturn(services.length > 1)}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                     </Back>
                     {quoteListWrapper.isPristine ? (
                        <div>{intl.formatMessage({ id: 'daypass.dateAndPeople' })}</div>
                     ) : (
                        <div>{intl.formatMessage({ id: 'daypass.options' })}</div>
                     )}
                     <div>&nbsp;</div>
                  </Title>
               )}
               {service && (
                  <>
                     <div
                        className={classNames({
                           'p-5': !useTailwindPrefix,
                           'tw-p-5': useTailwindPrefix,
                        })}
                     >
                        <div
                           className={classNames({
                              ' mb-3': !useTailwindPrefix,
                              'tw-mb-3': useTailwindPrefix,
                           })}
                        >
                           <strong>{service.title}</strong>
                        </div>
                        <ServiceBooker
                           serviceForm={getDetailedServiceForm(serviceForm, service, onQuoteEvent)}
                           showResume={showResume}
                           setResume={onChangeDates}
                           onSubmit={onSubmit}
                        />
                     </div>
                     <div
                        className={classNames({
                           'relative min-h-[7em] border-t': !useTailwindPrefix,
                           'tw-relative tw-min-h-[7em] tw-border-t': useTailwindPrefix,
                        })}
                        {...(quoteListWrapper.isPristine && { style: { display: 'none' } })}
                     >
                        {quoteListWrapper.isFetching && (
                           <div
                              className={classNames({
                                 'absolute inset-0 bg-[#ddd] z-[1] opacity-50 pt-10 text-center': !useTailwindPrefix,
                                 'tw-absolute tw-inset-0 tw-bg-[#ddd] tw-z-[1] tw-opacity-50 tw-pt-10 tw-text-center':
                                    useTailwindPrefix,
                              })}
                           >
                              <FontAwesomeIcon icon={faCircleNotch} size="2x" spin />
                           </div>
                        )}
                        {drawQuoteList(service)}
                     </div>
                  </>
               )}
            </>
         )
      }
      return (
         <Group>
            {hotelList && hotelList.length > 0 && selectedHotelId > 0 ? (
               <Title>
                  <Back onClick={onReturnServices}>
                     <FontAwesomeIcon icon={faChevronLeft} />
                  </Back>
                  <div>{intl.formatMessage({ id: 'daypass.chooseService' })}</div>
                  <div>&nbsp;</div>
               </Title>
            ) : (
               <Title single>{intl.formatMessage({ id: 'daypass.chooseService' })}</Title>
            )}
            {services.length === 0 ? (
               <Title single>{intl.formatMessage({ id: 'daypass.noService' })}</Title>
            ) : (
               services.map((service) => (
                  <Group.Item key={service.id} onClick={() => setServiceId(service.id)}>
                     {service.title}
                     <FontAwesomeIcon icon={faChevronRight} />
                  </Group.Item>
               ))
            )}
         </Group>
      )
   }

   return (
      <div
         className={classNames({
            'relative flex flex-col min-w-0 break-words bg-clip-border border rounded bg-white': !useTailwindPrefix,
            'tw-relative tw-flex tw-flex-col tw-min-w-0 tw-break-words tw-bg-clip-border tw-border tw-rounded tw-bg-white':
               useTailwindPrefix,
         })}
      >
         <div
            className={classNames({
               'px-5 py-3 mb-0 bg-black/[0.03] border-b': !useTailwindPrefix,
               'tw-px-5 tw-py-3 tw-mb-0 tw-bg-black/[0.03] tw-border-b': useTailwindPrefix,
            })}
         >
            {intl.formatMessage({ id: 'daypass.book' })}
         </div>
         {hotelList?.length > 1 && !serviceForm.hotelId && !selectedHotelId ? (
            <Group>
               <Title single>{intl.formatMessage({ id: 'daypass.chooseHotel' })}</Title>
               {hotelList.map((hotel) => (
                  <Group.Item key={hotel.id} onClick={() => setSelectedHotelId(hotel.id)}>
                     {hotel.name}
                     <FontAwesomeIcon icon={faChevronRight} />
                  </Group.Item>
               ))}
            </Group>
         ) : (
            <>
               {addOnListWrapper.isFetching && !addOnListWrapper.isPristine && (
                  <div
                     className={classNames({
                        'p-5': !useTailwindPrefix,
                        'tw-p-5': useTailwindPrefix,
                     })}
                  >
                     <ServiceBookerLoading />
                  </div>
               )}
               {!addOnListWrapper.isFetching && !addOnListWrapper.isPristine && drawBooker()}
            </>
         )}
      </div>
   )
}

export default ServiceSelector
