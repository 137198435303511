import React, { useContext } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { CounterSelector } from 'ui'
import { Select } from 'ui/fields'
import { BookerFormValues } from '../Booker'
import { RoomsSelectorContext } from './RoomsSelector'
import { getPropertyValue } from 'utils/validation'
import classNames from 'classnames'

type Props = {
   index: number
}

export const GuestSelector = ({ index }: Props) => {
   const intl = useIntl()
   const {
      control,
      formState: { errors },
   } = useFormContext<BookerFormValues>()
   const { minAdults, maxAdults, minChildren, maxChildren, maxChildrenAge, minChildrenAge, useTailwindPrefix } =
      useContext(RoomsSelectorContext)
   const { fields, append, remove } = useFieldArray({
      control,
      name: `rooms.${index}.childrenAges`,
   })
   return (
      <>
         <div
            className={classNames({
               'p-4 flex items-center justify-between': !useTailwindPrefix,
               'tw-p-4 tw-flex tw-items-center tw-justify-between': useTailwindPrefix,
            })}
         >
            <div>{intl.formatMessage({ id: maxChildren === 0 ? 'booker.persons' : 'booker.adults' })}</div>
            <Controller
               name={`rooms.${index}.adults`}
               render={({ field: { value, onChange } }) => (
                  <CounterSelector
                     value={value}
                     minValue={minAdults}
                     maxValue={maxAdults}
                     onChange={onChange}
                     useTailwindPrefix={useTailwindPrefix}
                  />
               )}
            />
         </div>
         <div
            className={classNames({
               'p-4 flex items-center justify-between': !useTailwindPrefix,
               'tw-p-4 tw-flex tw-items-center tw-justify-between': useTailwindPrefix,
            })}
         >
            <div>
               {intl.formatMessage({ id: 'booker.minors' })}
               <span
                  className={classNames({
                     'block text-base text-gray-400': !useTailwindPrefix,
                     'tw-block tw-text-base tw-text-gray-400': useTailwindPrefix,
                  })}
               >
                  {intl.formatMessage({ id: 'booker.rooms.upToAge' }, { maxAge: maxChildrenAge })}
               </span>
            </div>
            <Controller
               name={`rooms.${index}.children`}
               render={({ field: { value, onChange } }) => {
                  function onChangeEvent(count: number) {
                     const currentCount = fields.length
                     if (count > currentCount) {
                        Array.from({ length: count - currentCount }, (_, i) => i).forEach(() =>
                           append({
                              age: undefined,
                           }),
                        )
                     } else {
                        for (let i = fields.length; i > fields.length - (currentCount - count); i--) {
                           remove(i - 1)
                        }
                     }
                     onChange(count)
                  }
                  return (
                     <CounterSelector
                        value={value}
                        minValue={minChildren}
                        maxValue={maxChildren}
                        onChange={onChangeEvent}
                        useTailwindPrefix={useTailwindPrefix}
                     />
                  )
               }}
            />
         </div>
         {fields.length > 0 && (
            <div
               className={classNames({
                  'px-4 pt-2 pb-4 flex flex-wrap gap-4': !useTailwindPrefix,
                  'tw-px-4 tw-pt-2 tw-pb-4 tw-flex tw-flex-wrap tw-gap-4': useTailwindPrefix,
               })}
            >
               <div
                  className={classNames({
                     'basis-full': !useTailwindPrefix,
                     'tw-basis-full': useTailwindPrefix,
                  })}
               >
                  {intl.formatMessage({ id: 'booker.rooms.minors.age' })}
               </div>
               {fields.map((item, ageIndex) => (
                  <div key={item.id}>
                     <Controller
                        name={`rooms.${index}.childrenAges.${ageIndex}.age`}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                           <Select
                              value={value}
                              onChange={onChange}
                              error={
                                 getPropertyValue(errors, `rooms.${index}.childrenAges.${ageIndex}.age`)
                                    ? 'error'
                                    : undefined
                              }
                              showErrorText={false}
                              useTailwindPrefix={useTailwindPrefix}
                           >
                              <Select.Option value="">--</Select.Option>
                              {Array.from({ length: maxChildrenAge + 1 }, (_, i) => i + minChildrenAge).map((age) => (
                                 <Select.Option key={age} value={age}>
                                    {age === 0 ? '< 1' : age}
                                 </Select.Option>
                              ))}
                           </Select>
                        )}
                     />
                  </div>
               ))}
            </div>
         )}
      </>
   )
}
