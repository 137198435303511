import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PopUpPromotionRequest, PopUpPromotionResponse, getPopUpPromotion } from '../api/popUpPromotion'
import { SiteConfigState } from './siteConfigSlice'

export type HotelBookerRatesWrapper = {
   isPristine: boolean
   isFetching: boolean
   hasError: boolean
   value: PopUpPromotionResponse | null
   isOpen: boolean
   hasBeenShown: boolean
   alreadyAplied: boolean
}

const initialState: HotelBookerRatesWrapper = {
   isPristine: true,
   isFetching: false,
   isOpen: false,
   hasBeenShown: false,
   hasError: false,
   value: null,
   alreadyAplied: false
}
export type PopUpPromotionRequestApi = {
   hotelId?: number
   promotionCode?: string,
   language?: string,
}
export const getPopUpPromotionThunk = createAsyncThunk(
   'getPopUpPromotion',
   async (
      request: PopUpPromotionRequest,
      { rejectWithValue, getState },
   ) => {
      const { siteConfig } = getState() as { siteConfig: SiteConfigState }

      const response = await getPopUpPromotion({ ...request, language: siteConfig.data?.language })
      if (response) {
         const alreadyAplied = request && request.promotionCode && request.promotionCode !== "" ? true : false;
         return { response, alreadyAplied }
      }
      return rejectWithValue(null)
   },
)

export const getPopUpPromotionSlice = createSlice({
   name: 'PopUpPromotion',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
      showPopUpPromotion: (state) => {
         state.isOpen = true
      },
      closePopUpPromotion: (state) => {
         state.isOpen = false
         state.hasBeenShown = true
      }
   },
   extraReducers(builder) {
      builder.addCase(getPopUpPromotionThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
         state.hasError = false
      })
      builder.addCase(getPopUpPromotionThunk.fulfilled, (state, action) => {
         state.isPristine = false
         state.isFetching = false
         state.hasError = false
         state.value = action.payload.response
         state.alreadyAplied = action.payload.alreadyAplied
      })
      builder.addCase(getPopUpPromotionThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
         state.hasError = true
      })
   },
})
export const { clear, showPopUpPromotion, closePopUpPromotion } = getPopUpPromotionSlice.actions
export default getPopUpPromotionSlice.reducer
