import React, { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import './DateRangeSelector.css'
import { getFormattedDate, getMomentDate } from '../../../utils/momentUtils'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { HotelBookerRates } from '../../HotelForm'
import { Amount } from 'ui'

export type DateRangePicketPropsType = {
   startDatePlaceholderText?: string
   endDatePlaceholderText?: string
   displayFormat?: string
   minimumNights?: number
   showDefaultInputIcon?: boolean
   showClearDates?: boolean
   enableOutsideDays?: boolean
   renderCalendarInfo?: () => string | JSX.Element
}

type Props = {
   value?: {
      startDate: string
      endDate: string
   }
   onChange: Function
   id: string
   locale: string
   dateRangePickerProps?: DateRangePicketPropsType
   myErrors: any
   isMobile: boolean
   numberOfMonths: number
   showIcon?: boolean
   rates?: Partial<HotelBookerRates>
   vertical?: boolean
   useTailwindPrefix?: boolean
}
type quoteProps = {
   amount: number
   lowestRate: boolean
   available: boolean
   isAvailable: boolean
   isLowestRate: boolean
}
export const DateRangeSelector = ({
   id,
   rates,
   value,
   onChange,
   locale,
   dateRangePickerProps,
   myErrors,
   isMobile,
   numberOfMonths,
   vertical,
   useTailwindPrefix,
}: Props) => {
   const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)
   const [startDate, setStartDate] = useState<Moment | null>(getMomentDate(value?.startDate, locale))
   const [endDate, setEndDate] = useState<Moment | null>(getMomentDate(value?.endDate, locale))
   const intl = useIntl()

   useEffect(() => {
      setStartDate(getMomentDate(value?.startDate, locale))
      setEndDate(getMomentDate(value?.endDate, locale))
   }, [value, locale, rates])

   const onFocusChange = (arg: FocusedInputShape | null) => {
      setFocusedInput(arg)
      if (document && document.body) {
         if (arg === null) {
            if (!useTailwindPrefix) {
               document.body.className = document.body.className.replace(/ ?fixed md:relative/, '')
            } else {
               document.body.className = document.body.className.replace(/ ?tw-fixed md:tw-relative/, '')
            }
         } else {
            let orig = document.body.className
            if (!useTailwindPrefix) {
               if (orig.indexOf('fixed md:relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'fixed md:relative'
               }
            } else {
               if (orig.indexOf('tw-fixed md:tw-relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'tw-fixed md:tw-relative'
               }
            }
         }
      }
   }

   const onDatesChange = (arg: { startDate: Moment | null; endDate: Moment | null }) => {
      setStartDate(arg.startDate)
      setEndDate(arg.endDate)
      onChange({
         startDate: getFormattedDate(arg.startDate, locale, 'YYYY-MM-DD'),
         endDate: getFormattedDate(arg.endDate, locale, 'YYYY-MM-DD'),
      })
   }
   moment.locale(locale)

   const renderPrice = (day: Moment) => {
      const days = rates?.days
      const months = rates?.months || []

      if (day < moment().startOf('day')) {
         return null
      }
      const month = day.format('YYYY-MM')
      const dayQuote = days ? days[day.format('YYYY-MM-DD')] : null
      const lastDay = rates?.lastProcessedDate || moment().add(6, 'months')
      if (months.indexOf(month) > -1 && !dayQuote && (day.isBefore(lastDay) || day.isSame(lastDay))) {
         return <small className="itm-drs-fetching" />
      }

      return dayQuote ? renderRate(dayQuote) : null
   }

   const renderRate = (dayQuote: quoteProps) => {
      return dayQuote.isAvailable ? renderValidAmount(dayQuote) : renderInvalidAmount()
   }
   const renderValidAmount = (dayQuote: quoteProps) => {
      if (dayQuote.amount < 1) {
         return null
      }

      return (
         <small
            className={classNames({
               block: !useTailwindPrefix,
               'itm-drs-lowestRate': dayQuote.isLowestRate,
            })}
         >
            <Amount amount={dayQuote.amount} currency="" showCurrency={false} />
         </small>
      )
   }
   const renderInvalidAmount = () => {
      return (
         <div>
            <span className={classNames('itm-drs-withoutRate')}>--</span>
         </div>
      )
   }
   const renderDayContentsCustom = (day: Moment) => {
      return (
         <span className="itm-drs-bookerDay">
            {day.format('D')}
            {rates && rates.days && rates.months && renderPrice(day)}
         </span>
      )
   }

   return (
      <div
         className={classNames({
            'border-b-red-400 mb-5': myErrors && !useTailwindPrefix,
            'tw-border-b-red-400 tw-mb-5': myErrors && useTailwindPrefix,
            'md:mb-0': myErrors && !vertical && !useTailwindPrefix,
            'md:tw-mb-0': myErrors && !vertical && useTailwindPrefix,
         })}
      >
         <div className={classNames('itm-drs', { 'itm-drs-h': !vertical, 'itm-drs--error': myErrors })}>
            {locale && (
               <DateRangePicker
                  firstDayOfWeek={1}
                  daySize={50}
                  transitionDuration={0}
                  startDate={startDate}
                  startDateId={`${id}.startDateId`}
                  endDate={endDate}
                  endDateId={`${id}.endDateId`}
                  onDatesChange={onDatesChange}
                  focusedInput={focusedInput}
                  onFocusChange={onFocusChange}
                  readOnly
                  hideKeyboardShortcutsPanel
                  numberOfMonths={numberOfMonths}
                  {...(isMobile && {
                     orientation: 'vertical',
                     withFullScreenPortal: true,
                  })}
                  {...(rates && {
                     renderDayContents: renderDayContentsCustom,
                  })}
                  horizontalMargin={30}
                  {...(dateRangePickerProps
                     ? dateRangePickerProps
                     : {
                          minimumNights: 1,
                          showClearDates: true,
                          showDefaultInputIcon: true,
                       })}
               />
            )}
         </div>
         {myErrors && (
            <div
               className={classNames({
                  'text-red-400 mt-2 text-sm overflow-hidden text-ellipsis absolute': !useTailwindPrefix,
                  'tw-text-red-400 tw-mt-2 tw-text-sm tw-overflow-hidden tw-text-ellipsis tw-absolute':
                     useTailwindPrefix,
               })}
            >
               {myErrors.message || intl.formatMessage({ id: 'booker.errors.correctFields' })}
            </div>
         )}
      </div>
   )
}
