import { faCaretDown, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button, OverlayTrigger } from 'ui'
import { Input } from 'ui/fields'
import { BookerContext, BookerFormValues } from '../Booker'
import classNames from 'classnames'

type FormProps = {
   id?: string
   value?: string
   onChange: Function
}

const Form = ({ value, onChange, id }: FormProps) => {
   const intl = useIntl()
   const [innerValue, setInnerValue] = useState(value || '')
   const { useTailwindPrefix } = useContext(BookerContext)

   useEffect(() => {
      value !== null && value !== undefined && setInnerValue(value)
   }, [value])

   function onChangeValue(event: ChangeEvent<HTMLInputElement>) {
      setInnerValue(event.target.value)
   }

   function onSubmit() {
      onChange(innerValue)
   }

   return (
      <>
         <Input
            id={id}
            label={intl.formatMessage({ id: 'booker.promotion.code' })}
            name={`${id}.promotionCode`}
            type="text"
            value={innerValue}
            onChange={onChangeValue}
            useTailwindPrefix={useTailwindPrefix}
            className={classNames({
               'mb-4 md:mb-0 text-[100%]': !useTailwindPrefix,
               'tw-mb-4 md:tw-mb-0 tw-text-[100%]': useTailwindPrefix,
            })}
         />

         <Button
            bsStyle="outline-primary"
            className={classNames({
               'block w-1/2 md:w-full md:my-6': !useTailwindPrefix,
               'tw-block tw-w-1/2 md:tw-w-full md:tw-my-6': useTailwindPrefix,
            })}
            onClick={onSubmit}
         >
            {intl.formatMessage({ id: 'booker.promotion.code.apply' })}
         </Button>
      </>
   )
}

type Props = {
   id?: string
}

export function PromoCodeSelector({ id = 'pCode' }: Props) {
   const intl = useIntl()
   const [isOpen, setIsOpen] = useState(false)
   const { control, setValue } = useFormContext<BookerFormValues>()
   const { useTailwindPrefix } = useContext(BookerContext)
   const promotionCode = useWatch({ control, name: 'promotionCode' })
   function close() {
      setIsOpen(false)
   }

   function reset() {
      setValue('promotionCode', '')
   }

   function getPromotionCodeText() {
      if (!promotionCode || promotionCode === '') {
         return (
            <>
               {intl.formatMessage({ id: 'booker.promotion.question' })} <FontAwesomeIcon icon={faCaretDown} />
            </>
         )
      } else {
         return (
            <div
               className={classNames({
                  'flex space-x-2': !useTailwindPrefix,
                  'tw-flex tw-space-x-2': useTailwindPrefix,
               })}
            >
               <span>{intl.formatMessage({ id: 'booker.promotion.code' })}:</span>
               <strong>
                  {promotionCode} <FontAwesomeIcon icon={faCaretDown} />
               </strong>
               <span onClick={reset}>
                  <FontAwesomeIcon icon={faTrash} /> {intl.formatMessage({ id: 'booker.promotion.code.delete' })}
               </span>
            </div>
         )
      }
   }

   return (
      <div className={classNames({ relative: !useTailwindPrefix, 'tw-relative': useTailwindPrefix })}>
         <OverlayTrigger isOpen={isOpen} onToggle={setIsOpen}>
            <OverlayTrigger.Trigger
               className={classNames({
                  'text-[85%] mb-3 md:mb-0': !useTailwindPrefix,
                  'tw-text-[85%] tw-mb-3 md:tw-mb-0': useTailwindPrefix,
               })}
               componentTag="div"
            >
               <span
                  className={classNames('itm-booker-promoCode', {
                     'cursor-pointer text-link-blue': !useTailwindPrefix,
                     'tw-cursor-pointer tw-text-link-blue': useTailwindPrefix,
                  })}
               >
                  {getPromotionCodeText()}
               </span>
            </OverlayTrigger.Trigger>
            <OverlayTrigger.Overlay
               className={classNames({
                  'bg-gray-100 md:z-10 md:bg-white md:shadow md:border border-gray-200 md:absolute min-w-[250px] right-0 p-6 box-content my-2':
                     !useTailwindPrefix,
                  'tw-bg-gray-100 md:tw-z-10 md:tw-bg-white md:tw-shadow md:tw-border tw-border-gray-200 md:tw-absolute tw-min-w-[250px] tw-right-0 tw-p-6 tw-box-content tw-my-2':
                     useTailwindPrefix,
               })}
            >
               <Controller
                  control={control}
                  name="promotionCode"
                  render={({ field: { value, onChange } }) => (
                     <Form
                        id={id}
                        value={value}
                        onChange={(value: string) => {
                           onChange(value)
                           close()
                        }}
                     />
                  )}
               />
               <span
                  className={classNames({
                     'hidden md:block text-center cursor-pointer text-link-blue': !useTailwindPrefix,
                     'tw-hidden md:tw-block tw-text-center tw-cursor-pointer tw-text-link-blue': useTailwindPrefix,
                  })}
                  onClick={close}
               >
                  {intl.formatMessage({ id: 'booker.promotion.code.cancel' })}
               </span>
            </OverlayTrigger.Overlay>
         </OverlayTrigger>
      </div>
   )
}
