import React, { useContext, useState } from 'react'
import { OverlayTrigger } from 'ui'
import { BookerContext } from '../Booker'
import Field from './Field'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { LoyaltyPlanList, LoyaltyPlanListItem } from '../../HotelForm'

type Props = {
   loyaltyPlanList: LoyaltyPlanList
   onSelect: Function
   value?: {
      loyaltyPlanId: number
      promotionCode?: string
   }
}

function LoyaltyPlanSelector(props: Props) {
   const { loyaltyPlanList, value } = props
   const [isOpen, setIsOpen] = useState(false)
   const { vertical, useTailwindPrefix } = useContext(BookerContext)
   const intl = useIntl()

   function onToggle(data: boolean) {
      setIsOpen(data)
      if (data) {
         if (document && document.body) {
            let orig = document.body.className
            if (!useTailwindPrefix) {
               if (orig.indexOf('fixed md:relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'fixed md:relative'
               }
            } else {
               if (orig.indexOf('tw-fixed md:tw-relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'tw-fixed md:tw-relative'
               }
            }
         }
      } else {
         if (document && document.body) {
            if (!useTailwindPrefix) {
               document.body.className = document.body.className.replace(/ ?fixed md:relative/, '')
            } else {
               document.body.className = document.body.className.replace(/ ?tw-fixed md:tw-relative/, '')
            }
         }
      }
   }

   function drawSelection(data: Array<LoyaltyPlanListItem>) {
      let result: string | undefined
      let selectedLoyaltyPlan = data.find((item) => item.id === value?.loyaltyPlanId)
      if (selectedLoyaltyPlan) {
         const selectedPromoCode = selectedLoyaltyPlan.promotionCodes?.find((code) => {
            if (value?.promotionCode) {
               return code.promotionCode === value?.promotionCode
            }
         })
         if (selectedPromoCode) {
            result = selectedPromoCode.name
         } else {
            result = selectedLoyaltyPlan.name
         }
      }
      if (result) {
         return (
            <div
               className={classNames({
                  'flex justify-between items-center w-full': !useTailwindPrefix,
                  'tw-flex tw-justify-between tw-items-center tw-w-full': useTailwindPrefix,
                  relative: true,
               })}
            >
               <p>{result}</p>
            </div>
         )
      } else {
         return intl.formatMessage({ id: 'booker.selectPlan' })
      }
   }

   function closeSelector() {
      onToggle(false)
   }

   function renderOptions(item: LoyaltyPlanListItem, index: number, array: LoyaltyPlanListItem[]) {
      const onClickLoyaltyPlan = (planId: { loyaltyPlanId: number; promotionCode?: string }) => {
         if (planId.promotionCode) {
            props.onSelect({ promotionCode: planId.promotionCode, loyaltyPlanId: item.id })
         } else {
            props.onSelect({ loyaltyPlanId: item.id })
         }
         closeSelector()
      }

      let result: JSX.Element[] = []
      if (array.length > 1) {
         result.push(
            <div
               key={item.id}
               className={classNames({
                  'overflow-hidden': !useTailwindPrefix,
                  'tw-overflow-hidden': useTailwindPrefix,
               })}
            >
               <div
                  onClick={() => onClickLoyaltyPlan({ loyaltyPlanId: item.id })}
                  className={classNames({
                     'flex cursor-pointer justify-between items-center py-3 px-5 whitespace-nowrap overflow-hidden text-ellipsis bg-gray-200 hover:bg-gray-100 gap-x-4':
                        !useTailwindPrefix,
                     'tw-flex tw-cursor-pointer tw-justify-between tw-items-center tw-py-3 tw-px-5 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-bg-gray-200 hover:tw-bg-gray-100 tw-gap-x-4':
                        useTailwindPrefix,
                  })}
               >
                  {item.name}
                  <span
                     className={classNames({
                        'text-xs text-link-blue': !useTailwindPrefix,
                        'tw-text-xs tw-text-link-blue': useTailwindPrefix,
                     })}
                  >
                     {intl.formatMessage({ id: 'booker.selectPlan' })}
                  </span>
               </div>
            </div>,
         )
      }
      let promotionsCodes = [...item.promotionCodes].sort((a, b) => (a.order > b.order ? 1 : -1))
      result.push(
         <div
            className={classNames({
               block: !useTailwindPrefix,
               'tw-block': useTailwindPrefix,
            })}
            key={`ratePlans-${item.id}-${index}`}
         >
            {promotionsCodes.map((ratePlan, index) => {
               return (
                  <div
                     key={`ratePlans-${item.id}-${index}`}
                     className={classNames({
                        'overflow-hidden cursor-pointer w-full': !useTailwindPrefix,
                        'tw-overflow-hidden tw-cursor-pointer tw-w-full': useTailwindPrefix,
                     })}
                     onClick={() =>
                        onClickLoyaltyPlan({ loyaltyPlanId: item.id, promotionCode: ratePlan.promotionCode })
                     }
                  >
                     <div
                        className={classNames({
                           'block py-5 px-5 whitespace-nowrap overflow-hidden text-ellipsis hover:bg-gray-100 cursor-pointer':
                              !useTailwindPrefix,
                           'tw-block tw-py-3 tw-px-5 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis hover:tw-bg-gray-100 tw-cursor-pointer':
                              useTailwindPrefix,
                        })}
                     >
                        {ratePlan.name}
                     </div>
                  </div>
               )
            })}
         </div>,
      )
      return result
   }

   return (
      <OverlayTrigger isOpen={isOpen} onToggle={onToggle}>
         <OverlayTrigger.Trigger
            componentTag="div"
            className={classNames({
               'cursor-pointer': !useTailwindPrefix,
               'tw-cursor-pointer': useTailwindPrefix,
            })}
         >
            <Field>
               <div
                  className={classNames({
                     'whitespace-nowrap w-full overflow-hidden text-ellipsis': !useTailwindPrefix,
                     'tw-whitespace-nowrap w-full tw-overflow-hidden tw-text-ellipsis': useTailwindPrefix,
                  })}
               >
                  {loyaltyPlanList.isFetching ? '...' : drawSelection(loyaltyPlanList?.data || [])}
               </div>
            </Field>
         </OverlayTrigger.Trigger>
         <OverlayTrigger.Overlay
            className={classNames({
               'bg-white md:border border-gray-200 z-10 fixed inset-0 md:shadow-lg md:absolute md:mt-0.5 md:min-w-[280px]':
                  !useTailwindPrefix,
               'tw-bg-white md:tw-border tw-border-gray-200 tw-z-10 tw-fixed tw-inset-0 md:tw-shadow-lg md:tw-absolute md:tw-mt-0.5 md:tw-min-w-[280px]':
                  useTailwindPrefix,
               'md:inset-auto': !vertical && !useTailwindPrefix,
               'md:tw-inset-auto': !vertical && useTailwindPrefix,
               'md:inset-y-auto md:left-auto md:right-0': vertical && !useTailwindPrefix,
               'md:tw-inset-y-auto md:tw-left-auto md:tw-right-0': vertical && useTailwindPrefix,
            })}
         >
            <div
               className={classNames({
                  'md:hidden flex justify-between items-center px-4 border-b border-gray-200 h-[50px]':
                     !useTailwindPrefix,
                  'md:tw-hidden tw-flex tw-justify-between tw-items-center tw-px-4 tw-border-b tw-border-gray-200 tw-h-[50px]':
                     useTailwindPrefix,
               })}
            >
               <div className={classNames({ 'text-3xl': !useTailwindPrefix, 'tw-text-3xl': useTailwindPrefix })}>
                  {intl.formatMessage({ id: 'booker.loyaltyPlans' })}
               </div>
               <button
                  type="button"
                  onClick={closeSelector}
                  className={classNames({
                     'cursor-pointer text-2xl': !useTailwindPrefix,
                     'tw-cursor-pointer tw-text-2xl': useTailwindPrefix,
                  })}
               >
                  <FontAwesomeIcon icon={faTimes} />
               </button>
            </div>
            <div>
               {loyaltyPlanList?.data
                  ?.filter((rate) => rate.hasPointBenefit)
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map(renderOptions)}
            </div>
         </OverlayTrigger.Overlay>
      </OverlayTrigger>
   )
}

LoyaltyPlanSelector.propTypes = {}

export default LoyaltyPlanSelector
