import { refreshToken } from "../features/authSlice"
import { StoreType } from "../store"
import { axiosPrivate } from "./api"
import { isValidAccessToken } from "./auth"

export const setUpInterceptor = (store: StoreType) => {
   axiosPrivate.interceptors.request.use(
      async (config) => {
         const { authToken, siteConfig } = store.getState() || {}
         const access_token = authToken?.token
         if (!isValidAccessToken(access_token)) {
            if (siteConfig) {
               const request = {
                  username: siteConfig.data?.apiUserName || '',
                  password: siteConfig.data?.apiPassword || '',
               }
               const authTokenNow = await store.dispatch(refreshToken(request)).unwrap()

               if (config?.headers) {
                  config.headers['Authorization'] = `Bearer ${authTokenNow.access_token}`
               }
            }
         } else {
            if (config?.headers) {
               config.headers['Authorization'] = `Bearer ${access_token}`
            }
         }

         return config
      },
      (error) => {
         return Promise.reject(error)
      }
   )
}
