import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { HotelBookerRatesRequest, getHotelBookerRates } from '../api/hotelBookerRates'
import { Destination, HotelBookerRates } from 'booker'
import { getDatesToQuote } from 'booker/src/utils/bookerUtils'

export type HotelBookerRatesWrapper = HotelBookerRates & {
   isFetching: boolean
   hasError: boolean
}
export type HotelBookerRatesThunkRequest = {
   range?: { startDate?: string; endDate?: string }
   adults: number
   children: number
   destination: Destination
}
const initialState: HotelBookerRatesWrapper = {
   isFetching: false,
   hasError: false,
   months: [],
   days: {},
   lastProcessedDate: undefined,
   currency: undefined,
}

export const getHotelBookerRatesThunk = createAsyncThunk(
   'getHotelBookerRates',
   async (
      { range, adults, children, destination }: HotelBookerRatesThunkRequest,
      { dispatch, rejectWithValue, getState },
   ) => {
      const { hotelBookerRates } = getState() as {
         hotelBookerRates: HotelBookerRates
      }
      const datesToQuote = getDatesToQuote(range || null, hotelBookerRates)
      if (datesToQuote) {
         const request: HotelBookerRatesRequest = {
            from: datesToQuote.startDate,
            to: datesToQuote.endDate,
            adults: adults,
            children: children,
         }
         dispatch(setMonts(datesToQuote.months))
         if (destination.destinationType === 'hotel') {
            request.hotelId = destination.id
            const response = await getHotelBookerRates(request)
            if (response) {
               return response
            }
            return rejectWithValue(response)
         }
         if (destination.destinationType === 'destination') {
            dispatch(clear())
         }
      }
      return rejectWithValue(null)
   },
)

export const hotelBookerRatesSlice = createSlice({
   name: 'HotelBookerRates',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
      setMonts: (state, action: PayloadAction<Array<string>>) => {
         state.months = Array.from(new Set(state.months.concat(action.payload)))
      },
   },
   extraReducers(builder) {
      builder.addCase(getHotelBookerRatesThunk.pending, (state) => {
         state.isFetching = true
      })
      builder.addCase(getHotelBookerRatesThunk.fulfilled, (state, action) => {
         state.isFetching = false
         state.lastProcessedDate = action.payload.lastProcessedDate
         state.days = Object.assign({}, state.days, action.payload.rates)
         state.currency = action.payload.currency
      })
      builder.addCase(getHotelBookerRatesThunk.rejected, (state) => {
         state.isFetching = false
      })
   },
})
export const { clear, setMonts } = hotelBookerRatesSlice.actions
export default hotelBookerRatesSlice.reducer
