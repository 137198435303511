import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import resources from './resources'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { store } from './store'
import { setUpInterceptor } from './utils/interceptor'

type LocaleType = 'es-mx' | 'en-us'
const container = document.querySelector('.itm-booker')
const searchParams = new URLSearchParams(document.location.search)

const getLocale = () => {
   const language = container?.getAttribute('data-language') || searchParams.get('language')
   switch (language) {
      case 'es-mx':
         return 'es-mx' as LocaleType
      case 'en-us':
         return 'en-us' as LocaleType
   }
   return 'es-mx' as LocaleType
}
const allResources = {
   'es-mx': {
      ...resources['es-mx'],
   },
   'en-us': {
      ...resources['en-us'],
   },
}

const supLocale = getLocale()
const messages = allResources[supLocale]

const root = createRoot(container!)
const accountCode = container?.getAttribute('data-account') || searchParams.get('dev-account')
const hotelIdString = container?.getAttribute('data-hotel') || searchParams.get('dev-hotel')
const hotelId = hotelIdString && !isNaN(parseInt(hotelIdString)) ? parseInt(hotelIdString) : null
const hideNavbar = container?.getAttribute('data-loadingnavbar') === 'false'
const devOnlyShow = container?.getAttribute('data-only-show-form') || searchParams.get('dev-onlyShowForm')
const customUri = container?.getAttribute('data-custom-uri') || searchParams.get('dev-customUri')
const forceDestinationIdOnUrl =
   container?.getAttribute('data-force-destination-id-on-url') === 'true' ||
   searchParams.get('dev-forceDestinationIdOnUrl') === 'true'
let dataOnlyFormToShow = null
switch (devOnlyShow) {
   case 'HOTEL':
      dataOnlyFormToShow = 'HOTEL' as const
      break
   case 'HOTEL_FLIGHT':
      dataOnlyFormToShow = 'HOTEL_FLIGHT' as const
      break
   case 'SERVICES':
      dataOnlyFormToShow = 'SERVICES' as const
      break
}

setUpInterceptor(store)
accountCode &&
   root.render(
      <React.StrictMode>
         <Provider store={store}>
            <IntlProvider locale={supLocale} messages={messages}>
               <App
                  accountCode={accountCode}
                  locale={supLocale}
                  hideNavbar={hideNavbar}
                  dataOnlyFormToShow={dataOnlyFormToShow}
                  {...(customUri && { customUri })}
                  {...(hotelId && { hotelId })}
                  {...(forceDestinationIdOnUrl && { forceDestinationIdOnUrl: true })}
                  searchParams={searchParams}
               />
            </IntlProvider>
         </Provider>
      </React.StrictMode>,
   )
