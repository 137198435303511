import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './booker.css'
import 'moment/locale/es-mx'
import { HotelForm } from '../HotelForm'
import { RoomFormType } from './components/RoomsSelector'
import { HotelFlightForm } from '../HotelFlightForm'
import { Origin } from './components/OriginField'
import classNames from 'classnames'
import { LoadingForm } from '../LoadingForm'
import ServiceSelector from '../Services/ServiceSelector'
import { desaturate, getRGBColor, getShadeColor } from 'utils/layout'

export const BookerContext = createContext<BookerContextType>({} as BookerContextType)

type BookerContextType = BookerType & {
   isMobile: boolean
   hideIcon?: boolean
   inline?: boolean
   isAutocomplete?: boolean
   numberOfMonths: number
   originFetchUrl?: string
   useTailwindPrefix?: boolean
   language?: string
   enableOutsideDays?: boolean
   primaryColor?: string
   promoCodeInlineIcon?: boolean
}

type BookerType = {
   locale: string
   dateFormat: string
   vertical?: boolean
}
export type Destination = {
   airportCode?: string
   destinationType: 'hotel' | 'destination'
   id: number
}

export type BookerFormValues = {
   origin?: Origin
   destination?: Destination
   rooms?: Array<RoomFormType>
   tripDates?: {
      startDate?: string
      endDate?: string
   }
   promotionCode?: string
   channelId?: string
   eventCode?: string
   loyaltyPlan?: {
      loyaltyPlanId: number
      promotionCode?: string
   }
}

export type ServiceBookerFormValues = {
   dates?: {
      startDate?: string
      endDate?: string
   }
   date?: string
   quantity?: number
   guests?: {
      adults: number
      children: number
      childrenAges: Array<{ age?: number }>
   }
}

type Props = BookerType & {
   className?: string
   children: ReactNode
   hideIcon?: boolean
   inline?: boolean
   isAutocomplete?: boolean
   originFetchUrl?: string
   useTailwindPrefix?: boolean
   language?: string
   primaryColor?: string
   promoCodeInlineIcon?: boolean
}

export const Booker = ({ children, className, useTailwindPrefix, ...rest }: Props) => {
   const [isMobile, setIsMobile] = useState(false)
   const [numberOfMonths, setNumberOfMonths] = useState(2)
   const [enableOutsideDays, setEnableOutsideDays] = useState(false)
   const onWindowResize = useCallback(() => {
      const frameWidth = window.innerWidth
      if (frameWidth <= 1200) {
         setNumberOfMonths(1)
         setEnableOutsideDays(true)
         if (frameWidth < 768) {
            setNumberOfMonths(2)
            setEnableOutsideDays(false)
            setIsMobile(true)
         } else {
            setIsMobile(false)
         }
      } else {
         setNumberOfMonths(2)
         setIsMobile(false)
         setEnableOutsideDays(false)
      }
   }, [])

   useEffect(() => {
      onWindowResize()
      window.addEventListener('resize', onWindowResize)
      return () => {
         window.removeEventListener('resize', onWindowResize)
      }
   }, [onWindowResize])

   const getColors = (primaryColor: string = '#417505') => {
      const colors = []
      colors.push(getRGBColor(getShadeColor(primaryColor, -0.1), 'calendarDay-selectedBg'))
      colors.push(getRGBColor(getShadeColor(primaryColor, -0.3), 'calendarDay-selectedBorder'))
      colors.push(getRGBColor(primaryColor, 'calendarDay-spanBg'))
      colors.push(getRGBColor(getShadeColor(primaryColor, -0.09), 'calendarDay-spanBorder'))
      colors.push(getRGBColor(getShadeColor(desaturate(primaryColor, 0.7), 0.6), 'calendarDay-spanHoverBg'))
      colors.push(getRGBColor(getShadeColor(desaturate(primaryColor, 0.7), 0.4), 'calendarDay-spanHoverBorder'))
      colors.push(getRGBColor(getShadeColor(primaryColor, -0.2), 'calendarDay-spanHoverColor'))
      return colors
   }

   return (
      <BookerContext.Provider value={{ isMobile, numberOfMonths, useTailwindPrefix, enableOutsideDays, ...rest }}>
         <style>
            {`:root {
               ${getColors(rest.primaryColor).join(' ')}
            }`}
         </style>
         <div
            className={classNames(className, {
               'itm-booker--vertical': rest.vertical,
               'itm-booker--horizontal': !rest.vertical,
               'text-[#565a5c] text-[16px]': !className && !useTailwindPrefix,
               'tw-text-[#565a5c] tw-text-[16px]': !className && useTailwindPrefix,
            })}
         >
            {children}
         </div>
      </BookerContext.Provider>
   )
}
Booker.Hotel = HotelForm
Booker.HotelFlight = HotelFlightForm
Booker.Services = ServiceSelector
Booker.Loading = LoadingForm
