export default {
   'booker.reserveHeader': 'Reservar ahora',
   'booker.travelDates': 'Fechas del viaje',
   'booker.search': 'Buscar',
   'booker.rooms': 'Habitaciones',
   'booker.adults': 'Adultos',
   'booker.minors': 'Menores',
   'booker.persons': 'Personas',
   'booker.roomAbbreviated': 'Hab. {roomIndex}',
   'booker.roomWithIndex': 'Habitación {roomIndex}',
   'booker.minorWithIndex': 'Menor {minorIndex}',
   'booker.hotel': 'Hotel',
   'booker.checkIn': 'Llegada',
   'booker.checkOut': 'Salida',
   'booker.flight.outboundFlightDate': 'Ida',
   'booker.flight.returnFlightDate': 'Regreso',
   'booker.selectHotel': 'Selecciona un hotel',
   'booker.errors.correctFields': 'Favor de corregir los campos marcados en rojo',
   'booker.errors.origin': 'Seleccione un origen valido',
   'booker.errors.origin.same': 'El origen y el destino son el mismo',
   'booker.errors.selectHotel': 'Seleccione un hotel',
   'booker.errors.selectHotel.destination': 'Selecciona un hotel o destino',
   'booker.errors.travel.dates': 'Ingrese las fechas de viaje',
   'booker.errors.dates': 'Ingrese las fechas',
   'booker.errors.selectMinorsAge': 'Elija la edad de los menores',
   'booker.errors.select.arrival': 'Ingrese la fecha de llegada',
   'booker.errors.select.departure': 'Ingrese la fecha de salida',
   'booker.errors.select.outbound': 'Ingrese la fecha de ida',
   'booker.errors.select.return': 'Ingrese la fecha de regreso',
   'booker.errors.select.start': 'Ingrese la fecha inicial',
   'booker.errors.select.end': 'Ingrese la fecha final',
   'booker.errors.unValid.arrival.format': 'Ingrese una fecha de llegada valida: dd/mm/yyyy',
   'booker.errors.unValid.departure.format': 'Ingrese una fecha de salida valida: dd/mm/yyyy',
   'booker.errors.arrival.before.today': 'La fecha de llegada no puede ser menor al dia de hoy',
   'booker.errors.departure.before.today': 'La fecha de salida no puede ser menor al dia de hoy',
   'booker.errors.start.before.today': 'La fecha inicial no puede ser menor al dia de hoy',
   'booker.errors.end.before.today': 'La fecha final no puede ser menor al dia de hoy',
   'booker.errors.departureDateBeforeArrival': 'La fecha de salida no puede ser menor a la fecha de llegada',
   'booker.rooms.uri': 'habitaciones',
   'booker.done': 'Aceptar',
   'booker.promotion.question': '¿Tienes un código de promoción?',
   'booker.promotion.code': 'Código de promoción',
   'booker.promotion.code.delete': 'Eliminar',
   'booker.promotion.code.apply': 'Aplicar',
   'booker.promotion.code.cancel': 'Cancelar',
   'booker.rooms.total': '{rooms, plural, one {# habitación} other {# habitaciones}}',
   'booker.rooms.total.guests': '{guests, plural, one {# persona} other {# personas}}',
   'booker.rooms.years': 'años',
   'booker.rooms.minors.age': 'Edades de los menores',
   'booker.rooms.close': 'Cerrar',
   'booker.show.availability': 'Ver disponibilidad',
   'booker.flight': 'vuelo',
   'booker.origin': 'Origen',
   'booker.no.results': 'No se encontraron resultados con el criterio de búsqueda',
   'booker.no.results.length': 'Ingresa al menos 3 caracteres para ver resultados',
   'booker.select.destination': 'Seleccionar destino',
   'booker.filter.results': 'Filtrar por nombre de hotel o destino',
   'booker.lowest.rate': 'Tarifa más baja por noche en {currency}',
   'booker.lowest.rate2': 'Tarifa más baja aproximada en {currency}, por habitación, para dos adultos, desde una noche.',
   'booker.dayPass.continue': 'Continuar',
   'booker.dayPass.date': 'Fecha',
   'booker.dayPass.dates': 'Fechas',
   'booker.dayPass.chooseDate': 'Elegir una fecha',
   'booker.dayPass.chooseDates': 'Elegir las fechas',
   'booker.dayPass.errors.date': 'Selecciona una fecha',
   'booker.resume.date.format': 'DD MMM YYYY',
   'booker.resume.date.day.format': 'DD',
   'booker.resume.date.month.format': 'DD MMM',
   'booker.change': 'Cambiar',
   'booker.startDate': 'Inicio',
   'booker.endDate': 'Fin',
   'booker.to': 'al',
   'booker.quantity': 'Cantidad',
   'booker.hotel.write': 'Escribe...',
   'booker.rooms.upToAge': 'Hasta {maxAge} años',
   'booker.services': 'Servicios',
   'booker.rate.simbols.lowest': " Tarifa más baja",
   'booker.rate.simbols.notavailable': "Sin disponibilidad",
}
