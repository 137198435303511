import jwt_decode, { JwtPayload } from 'jwt-decode'

export const isValidAccessToken = (accesToken?: string | null) => {
   if (!accesToken || accesToken === 'undefined' || accesToken === null || accesToken === '') {
      return false
   }

   const tokenDecoded = jwt_decode<JwtPayload>(accesToken)
   const expiredDate = tokenDecoded && tokenDecoded.exp ? new Date(tokenDecoded.exp * 1000) : null
   let validationDate = new Date()

   validationDate.setMinutes(validationDate.getMinutes() + 5)

   return expiredDate && validationDate.getTime() < expiredDate.getTime()
}
