import diacritics from 'diacritics'

export function toUri(value?: string) {
   if (!value || value === '') {
      return "";
   }
   const result = diacritics.remove(value).toLowerCase();
   return result.split(" ").join("-");
}

export function toPercentage(decimalValue: number) {
   let value = decimalValue * 100
   return value % 1 > 0 ? (value % 1 > 0.5 ? Math.round(value) : Math.floor(value)) : value
}