import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { Amount, Button } from 'ui'
import { toPercentage } from 'utils/parse'
import { ImageOptions, getSizedImage } from 'utils/images'
import useIsMobile from '../../hooks/useIsMobile'

export type Promotion = {
   // applied: boolean
   // prePromotionalAmount: number | null
   discount: number | null
   discountPercentage: number | null
   // applyDiscountByTotalRate: null
   title: string | null
   description: string | TrustedHTML | null
   addedValueTitle: string | null
   addedValueDescription: string | null
   // addedValues: []
   code: string | null
   // discountType: 'PERCENTAGE'
   // bookingWindowStart: string | null
   // bookingWindowEnd: string | null
   // travelWindowStart: string | null
   // travelWindowEnd: string | null
   // travelWindowPeriods: Array<{
   //    startDate: string
   //    endDate: string
   // }>
   // bookingWindowPeriods: Array<{
   //    startDate: string
   //    endDate: string
   // }>
   // weekDaySettings: {
   //    1: boolean
   //    2: boolean
   //    3: boolean
   //    4: boolean
   //    5: boolean
   //    6: boolean
   //    7: boolean
   // }
   // blackOutDates: []
   rollingEBBSettings: []
   // popupPeriods: []
   nightFree: number | null
   // stopNightsFree: null
   secondNightFree: number | null
   kidsFree: boolean | null
   kidsFreeMax: number | null
   // minimumStay: null
   isTwoForOne: boolean | null
   isNightFree: boolean | null
   popupInactiveInSeconds: number | null
   popupBehaviourType: PopupBehaviourType
   popupPhoto: null | {
      basePath: string
      path: string
      id: number
      position: PopupPosition
   }
}

export enum PopupBehaviourType {
   EXIT_POPUP = 'EXIT_POPUP',
   INACTIVITY = 'INACTIVITY',
}

export enum PopupPosition {
   MID = 'MID',
   LEFT = 'LEFT',
   RIGHT = 'RIGHT',
}

export const PopupPositionOptions = {
   MID: 'MID',
   LEFT: 'LEFT',
   RIGHT: 'RIGHT',
}

type Props = {
   promotion: Promotion
   onCancel: React.MouseEventHandler<HTMLButtonElement>
   currency?: string
   onApply: Function
   useTailwindPrefix?: boolean
}

export const PromotionPopup = (props: Props) => {
   const { promotion, onCancel, currency, onApply, useTailwindPrefix } = props
   const isMobile = useIsMobile()
   const previewLayout = classNames({
      'grid grid-cols-2 text-left':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position === PopupPositionOptions.LEFT &&
         !isMobile &&
         !useTailwindPrefix,
      'tw-grid tw-grid-cols-2 tw-text-left':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position === PopupPositionOptions.LEFT &&
         !isMobile &&
         useTailwindPrefix,
      'grid grid-cols-2 text-right':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position === PopupPositionOptions.RIGHT &&
         !isMobile &&
         !useTailwindPrefix,
      'tw-grid tw-grid-cols-2 tw-text-right':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position === PopupPositionOptions.RIGHT &&
         !isMobile &&
         useTailwindPrefix,
   })
   const styleImage = classNames({
      'bg-cover bg-center': !useTailwindPrefix,
      'tw-bg-cover tw-bg-center': useTailwindPrefix,
      'min-h-[400px]':
         (promotion && promotion.popupPhoto && promotion.popupPhoto.position !== PopupPositionOptions.MID) ||
         (isMobile && !useTailwindPrefix),
      'tw-min-h-[400px]':
         (promotion && promotion.popupPhoto && promotion.popupPhoto.position !== PopupPositionOptions.MID) ||
         (isMobile && useTailwindPrefix),
      'col-start-1 row-start-1 rounded-tl rounded-bl':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position === PopupPositionOptions.LEFT &&
         !isMobile &&
         !useTailwindPrefix,
      'tw-col-start-1 tw-row-start-1 tw-rounded-tl tw-rounded-bl':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position === PopupPositionOptions.LEFT &&
         !isMobile &&
         useTailwindPrefix,
      'col-start-2 row-start-1 rounded-tr rounded-br':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position === PopupPositionOptions.RIGHT &&
         !isMobile &&
         !useTailwindPrefix,
      'tw-col-start-2 tw-row-start-1 tw-rounded-tr tw-rounded-br':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position === PopupPositionOptions.RIGHT &&
         !isMobile &&
         useTailwindPrefix,
      'rounded-tr rounded-tl h-[250px]':
         (promotion && promotion.popupPhoto && promotion.popupPhoto.position === PopupPositionOptions.MID) ||
         (isMobile && !useTailwindPrefix),
      'tw-rounded-tr tw-rounded-tl tw-h-[250px]':
         (promotion && promotion.popupPhoto && promotion.popupPhoto.position === PopupPositionOptions.MID) ||
         (isMobile && useTailwindPrefix),
   })
   const styleText = classNames({
      'flex flex-col justify-between px-4':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position !== PopupPositionOptions.MID &&
         !isMobile &&
         !useTailwindPrefix,
      'tw-flex tw-flex-col tw-justify-between tw-px-4':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position !== PopupPositionOptions.MID &&
         !isMobile &&
         useTailwindPrefix,
      'col-start-1':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position === PopupPositionOptions.RIGHT &&
         !isMobile &&
         !useTailwindPrefix,
      'tw-col-start-1':
         promotion &&
         promotion.popupPhoto &&
         promotion.popupPhoto.position === PopupPositionOptions.RIGHT &&
         !isMobile &&
         useTailwindPrefix,
      'text-center px-4':
         (promotion && promotion.popupPhoto && promotion.popupPhoto.position === PopupPositionOptions.MID) ||
         (isMobile && !useTailwindPrefix),
      'tw-text-center tw-px-4':
         (promotion && promotion.popupPhoto && promotion.popupPhoto.position === PopupPositionOptions.MID) ||
         (isMobile && useTailwindPrefix),
   })
   const intl = useIntl()
   // eslint-disable-next-line
   function onApplyEvent() {
      onApply(promotion)
   }

   const getDiscount = (promotion: Promotion) => {
      let result = null
      if (promotion.rollingEBBSettings && promotion.rollingEBBSettings.length > 0) {
         result = promotion.rollingEBBSettings.map((rebb: any) => (
            <div key={`rebb-${rebb.id}`}>
               {intl.formatMessage(
                  { id: 'common.rooms.promotion.ebb' },
                  { days: rebb.days, discountPercentage: toPercentage(rebb.discountPercentage) },
               )}
            </div>
         ))
      } else if (promotion.discountPercentage && promotion.discountPercentage > 0) {
         result = (
            <span>
               {toPercentage(promotion.discountPercentage)}% {intl.formatMessage({ id: 'common.rooms.promotion.off' })}
            </span>
         )
      } else if (promotion.discount && promotion.discount > 0) {
         result = (
            <span>
               <Amount amount={promotion.discount} currency={currency} />{' '}
               {intl.formatMessage({ id: 'common.rooms.promotion.byRoom.byNight.discount' })}
            </span>
         )
      }
      if (result) {
         return <li>{result}</li>
      }
      return result
   }
   const getPhotoPreviewURL = () => {
      const {
         promotion: { popupPhoto },
      } = props
      if (!popupPhoto) {
         return ''
      }
      let url = popupPhoto.basePath + popupPhoto.path
      const options =
         popupPhoto && popupPhoto.position !== 'MID'
            ? ({ w: 299, h: 400, fit: 'crop', dpr: '2.0' } as ImageOptions)
            : ({ w: 600, h: 250, fit: 'crop', dpr: '2.0' } as ImageOptions)
      let imageUrl = getSizedImage(url, options)
      return imageUrl
   }

   return (
      <div className={previewLayout}>
         {promotion.popupPhoto && (
            <div className={styleImage} style={{ backgroundImage: `url("${getPhotoPreviewURL()}")` }}></div>
         )}
         <div className={styleText}>
            <div className={classNames({ 'py-[18px]': !useTailwindPrefix, 'tw-py-[18px]': useTailwindPrefix })}>
               <div
                  className={classNames({
                     'text-[20px]': !useTailwindPrefix,
                     'tw-text-[20px]': useTailwindPrefix,
                  })}
               >
                  {promotion.title ? promotion.title : intl.formatMessage({ id: 'common.popup.exclusiveOffer' })}
               </div>
            </div>
            {promotion.description && (
               <div
                  className={classNames({
                     'mb-6 text-[15px]': !useTailwindPrefix,
                     'tw-mb-6 tw-text-[15px]': useTailwindPrefix,
                  })}
                  dangerouslySetInnerHTML={{ __html: promotion.description }}
               ></div>
            )}

            {promotion.code ? (
               <div
                  className={classNames({
                     'bg-[#fafafa] py-6 px-4 text-[#777777] text-sm text-muted -mx-4 mb-6': !useTailwindPrefix,
                     'tw-bg-[#fafafa] tw-py-6 tw-px-4 tw-text-[#777777] tw-text-sm  tw-text-muted -tw-mx-4 tw-mb-6':
                        useTailwindPrefix,
                  })}
               >
                  <ul
                     className={classNames({
                        'mb-3 text-[15px] leading-6 font-bold': !useTailwindPrefix,
                        'tw-mb-3 tw-text-[15px] tw-leading-6 tw-font-bold': useTailwindPrefix,
                     })}
                  >
                     {getDiscount(promotion)}
                     {promotion.kidsFree && (
                        <li>
                           {intl.formatMessage(
                              { id: 'common.rooms.promotion.minors.free' },
                              { quantity: promotion.kidsFreeMax || 0 },
                           )}
                        </li>
                     )}
                     {promotion.isTwoForOne && <li>2 x 1</li>}
                     {promotion.isNightFree && (
                        <li>
                           {intl.formatMessage(
                              { id: 'common.rooms.promotion.night.free' },
                              { quantity: 1, days: promotion.nightFree },
                           )}
                        </li>
                     )}
                     {promotion.isNightFree && promotion.secondNightFree && (
                        <li>
                           {intl.formatMessage(
                              { id: 'common.rooms.promotion.night.free' },
                              { quantity: 2, days: promotion.secondNightFree },
                           )}
                        </li>
                     )}
                     {promotion.addedValueTitle && promotion.addedValueDescription && (
                        <li>{promotion.addedValueDescription}</li>
                     )}
                  </ul>
                  <div
                     className={classNames({ 'text-[14px]': !useTailwindPrefix, 'tw-text-[14px]': !useTailwindPrefix })}
                  >
                     {intl.formatMessage({ id: 'common.popup.usePromoCode' }, { code: promotion.code })}
                  </div>
               </div>
            ) : null}
            <div className={classNames({ 'pb-2': !useTailwindPrefix, 'tw-pb-2': useTailwindPrefix })}>
               {promotion.code ? (
                  <ul>
                     <li className={classNames({ 'mb-1.5': !useTailwindPrefix, 'tw-mb-1.5': useTailwindPrefix })}>
                        <Button bsStyle="primary" onClick={onApplyEvent}>
                           {intl.formatMessage({ id: 'common.popup.applyCode' })}
                        </Button>
                     </li>
                     <li className={classNames({ 'mb-1.5': !useTailwindPrefix, 'tw-mb-1.5': useTailwindPrefix })}>
                        <span
                           className={classNames({
                              'text-[#337ab7] text-[14px] cursor-pointer py-2 px-0 leading-tight inline-block no-underline hover:underline':
                                 !useTailwindPrefix,
                              'tw-text-[#337ab7] tw-text-[14px] tw-cursor-pointer tw-py-2 tw-px-0 tw-leading-tight tw-inline-block tw-no-underline hover:tw-underline':
                                 useTailwindPrefix,
                           })}
                           onClick={onCancel}
                        >
                           {intl.formatMessage({ id: 'common.popup.noThanks' })}
                        </span>
                        {/* <Button onClick={onCancel} bsStyle='default' className='text-blue-500 hover:text-blue-700 focus:text-blue-700 border-none underline text-current bg-transparent'>  {intl.formatMessage({ id: 'common.popup.noThanks' })}</Button> */}
                     </li>
                  </ul>
               ) : (
                  <ul>
                     <li className={classNames({ 'mb-1.5': !useTailwindPrefix, 'tw-mb-1.5': useTailwindPrefix })}>
                        <Button bsStyle="primary" onClick={onCancel}>
                           {intl.formatMessage({ id: 'common.popup.ok' })}
                        </Button>
                     </li>

                  </ul>
               )}
            </div>
         </div>
      </div>
   )
}
