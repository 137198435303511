import { useCallback, useRef, useState } from 'react'

export function useScrollWithShadow() {
   const [scrollTop, setScrollTop] = useState(0)
   const [scrollHeight, setScrollHeight] = useState(0)
   const [clientHeight, setClientHeight] = useState(0)

   const ref = useCallback((node: HTMLDivElement) => {
      if (!node) return
      const resizeObserver = new ResizeObserver(() => {
         reset(node)
      })
      resizeObserver.observe(node)
   }, [])

   const onScrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
      const target = event.target as HTMLDivElement
      setScrollTop(target.scrollTop)
      setScrollHeight(target.scrollHeight)
      setClientHeight(target.clientHeight)
   }

   function reset(target: HTMLDivElement) {
      setScrollTop(target.scrollTop)
      setScrollHeight(target.scrollHeight)
      setClientHeight(target.clientHeight)
   }
   const topShadow = (
      <div
         className="absolute top-0 left-0 right-0 h-[10px] bg-none"
         style={{
            background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%)',
         }}
      />
   )
   const bottomShadow = (
      <div
         className="absolute bottom-0 left-0 right-0 h-[10px] bg-none"
         style={{
            background: 'linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)',
         }}
      />
   )

   function drawShadows() {
      if (Math.abs(scrollHeight - clientHeight) < 1) {
         return null
      }
      const isBottom = Math.abs(clientHeight - (scrollHeight - scrollTop)) < 1
      const isTop = scrollTop === 0
      const isBetween =
         scrollTop > 0 &&
         clientHeight < scrollHeight - scrollTop &&
         Math.abs(clientHeight - (scrollHeight - scrollTop)) > 1

      let result = []
      if (isTop) {
         result.push(bottomShadow)
      } else if (isBetween) {
         result.push(bottomShadow)
         result.push(topShadow)
      } else if (isBottom) {
         result.push(topShadow)
      }
      if (result.length > 0) {
         return <>{result}</>
      }
      return null
   }

   return {
      containerRef: ref,
      drawShadows,
      onScrollHandler,
      parentStyle: { position: 'relative' } as React.CSSProperties,
   }
}
