import { useEffect, useState } from 'react';

const useIsMobile = (): boolean => {
   const [isMobile, setIsMobile] = useState(false);

   useEffect(() => {
      const checkIsMobile = () => {
         setIsMobile(window.innerWidth <= 575);
      };
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
      return () => {
         window.removeEventListener('resize', checkIsMobile);
      };
   }, []);

   return isMobile;
};

export default useIsMobile;