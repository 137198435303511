function getRGB(hex: string) {
   let color = hex.replace(/#/g, '')
   // rgb values
   let r = parseInt(color.substring(0, 2), 16)
   let g = parseInt(color.substring(2, 4), 16)
   let b = parseInt(color.substring(4, 6), 16)
   return ({ r, g, b })
}
function componentToHex(c: number) {
   var hex = c.toString(16);
   return hex.length == 1 ? "0" + hex : hex;
}

export function getRGBColor(hex: string, type: string) {
   const color = getRGB(hex)

   return `--color-${type}: ${color.r}, ${color.g}, ${color.b};`
}

export function getAccessibleColor(hex: string) {
   const color = getRGB(hex)

   let yiq = (color.r * 299 + color.g * 587 + color.b * 114) / 1000
   return yiq >= 128 ? '#000000' : '#FFFFFF'
}

export function getShadeColor(hex: string, amount: number) {
   let percentage = amount > 1 ? 1 : amount < -1 ? -1 : amount
   const color = getRGB(hex)

   let isNegative = percentage < 0
   let t = isNegative ? 0 : 255 * percentage
   let p = isNegative ? 1 + percentage : 1 - percentage
   const newColor = {
      r: Math.round(color.r * p + t),
      g: Math.round(color.g * p + t),
      b: Math.round(color.b * p + t)
   }
   return "#" + componentToHex(newColor.r) + componentToHex(newColor.g) + componentToHex(newColor.b);
}

export function desaturate(hex: string, amount: number) {
   const sat = amount > 1 ? 1 : amount < -1 ? -1 : amount
   const color = getRGB(hex)
   var gray = color.r * 0.3086 + color.g * 0.6094 + color.b * 0.0820;
   const newColor = {
      r: Math.round(color.r * sat + gray * (1 - sat)),
      g: Math.round(color.g * sat + gray * (1 - sat)),
      b: Math.round(color.b * sat + gray * (1 - sat))
   }
   return "#" + componentToHex(newColor.r) + componentToHex(newColor.g) + componentToHex(newColor.b);
}

export type Theme = {
   primaryColor?: string
   primaryBorderColor?: string
   primaryColorOutline?: string
   primaryBorderColorOutline?: string
   primaryColorOutlineHover?: string
   accessiblePrimaryColorOutlineHover?: string
   accessiblePrimaryColor?: string
   headerBgColor?: string
   accessibleBGColor?: string
   headerBgHoverColor?: string
   accessibleBgHoverColor?: string
   successAmenityColor?: string
   infoIconColor?: string
   warningColor?: string
   linkColor?: string
   primaryBookerBgColor?: string
   accessiblePrimaryBookerBgColor?: string
   primaryBookerBorderColor?: string
   calendarColor?: string
}

export function getColors(theme?: Theme) {
   const primaryColor = theme?.primaryColor || '#337ab7'
   const primaryBorderColor = theme?.primaryBorderColor || primaryColor
   const accessiblePrimaryColor = theme?.accessiblePrimaryColor || getAccessibleColor(primaryColor)
   const primaryColorOutline = theme?.primaryColorOutline || primaryColor
   const primaryColorOutlineHover = theme?.primaryColorOutlineHover || primaryColor
   const accessiblePrimaryColorOutlineHover = theme?.accessiblePrimaryColorOutlineHover || accessiblePrimaryColor
   const headerBGColor = theme?.headerBgColor || '#FFFFFF'
   const accessibleBGColor = theme?.accessibleBGColor || getAccessibleColor(headerBGColor)
   const headerBGHoverColor = theme?.headerBgHoverColor || getShadeColor(headerBGColor, 0.2)
   const accessibleBGHoverColor = theme?.accessibleBgHoverColor || getAccessibleColor(headerBGHoverColor)
   const successAmenityColor = theme?.successAmenityColor || '#15803d'
   const infoIconColor = theme?.infoIconColor || '#d1d5db'
   const warningColor = theme?.warningColor || '#ca8a04'
   const linkColor = theme?.linkColor || '#0071C5'
   const primaryBookerBgColor = theme?.primaryBookerBgColor || theme?.primaryColor || '#337ab7'
   const accessiblePrimaryBookerBgColor = theme?.accessiblePrimaryBookerBgColor || theme?.accessiblePrimaryColor || getAccessibleColor(primaryBookerBgColor)
   const primaryBookerBorderColor = theme?.primaryBookerBorderColor || primaryBookerBgColor

   const colors = []
   colors.push(getRGBColor(primaryColor, 'primary'))
   colors.push(getRGBColor(primaryBorderColor, 'primary-border'))
   colors.push(getRGBColor(getShadeColor(primaryColor, 0.1), 'primary-10'))
   colors.push(getRGBColor(getShadeColor(primaryColor, -0.1), 'primary-10b'))
   colors.push(getRGBColor(accessiblePrimaryColor, 'primary-a11y'))
   colors.push(getRGBColor(headerBGColor, 'header'))
   colors.push(getRGBColor(headerBGHoverColor, 'header-hover'))
   colors.push(getRGBColor(accessibleBGColor, 'header-a11y'))
   colors.push(getRGBColor(accessibleBGHoverColor, 'header-a11y-hover'))
   colors.push(getRGBColor(successAmenityColor, 'success-amenity'))
   colors.push(getRGBColor(infoIconColor, 'info-icon'))
   colors.push(getRGBColor(warningColor, 'warning'))
   colors.push(getRGBColor(linkColor, 'link-primary'))
   colors.push(getRGBColor(primaryBookerBgColor, 'primary-booker'))
   colors.push(getRGBColor(accessiblePrimaryBookerBgColor, 'primary-booker-a11y'))
   colors.push(getRGBColor(primaryBookerBorderColor, 'primary-booker-border'))
   colors.push(getRGBColor(primaryColorOutline, 'primary-outline'))
   colors.push(getRGBColor(primaryColorOutlineHover, 'primary-outline-hover'))
   colors.push(getRGBColor(accessiblePrimaryColorOutlineHover, 'primary-outline-hover-a11y'))
   return colors
}
