export default {
   'booker.reserveHeader': 'Book now',
   'booker.travelDates': 'Travel dates',
   'booker.search': 'Search',
   'booker.rooms': 'Rooms',
   'booker.adults': 'Adults',
   'booker.minors': 'Minors',
   'booker.persons': 'Persons',
   'booker.roomAbbreviated': 'Room. {roomIndex}',
   'booker.roomWithIndex': 'Room {roomIndex}',
   'booker.minorWithIndex': 'Minor {minorIndex}',
   'booker.hotel': 'Hotel',
   'booker.checkIn': 'Check in',
   'booker.checkOut': 'Check out',
   'booker.flight.outboundFlightDate': 'Depart',
   'booker.flight.returnFlightDate': 'Return',
   'booker.selectHotel': 'Select an hotel',
   'booker.errors.correctFields': 'Please correct the fields marked in red',
   'booker.errors.origin': 'Select a valid origin',
   'booker.errors.origin.same': 'The origin and destination are the same',
   'booker.errors.selectHotel': 'Select an hotel',
   'booker.errors.selectHotel.destination': 'Select an hotel or destination',
   'booker.errors.travel.dates': 'Select the travel dates',
   'booker.errors.dates': 'Select the dates',
   'booker.errors.selectMinorsAge': 'Select the minors age',
   'booker.errors.select.arrival': 'Select the arrival date',
   'booker.errors.select.departure': 'Select the departure date',
   'booker.errors.select.outbound': 'Select the outbound date',
   'booker.errors.select.return': 'Select the return date',
   'booker.errors.select.start': 'Select the start date',
   'booker.errors.select.end': 'Select the end date',
   'booker.errors.unValid.arrival.format': 'Select a valid arrival date: mm/dd/yyyy',
   'booker.errors.unValid.departure.format': 'Select a valid departure date: mm/dd/yyyy',
   'booker.errors.arrival.before.today': 'The arrival date cannot be before than today',
   'booker.errors.departure.before.today': 'The departure date cannot be before than today',
   'booker.errors.start.before.today': 'The start date cannot be before than today',
   'booker.errors.end.before.today': 'The end date cannot be before than today',
   'booker.errors.departureDateBeforeArrival': 'The departure date cannot be before than arrival date',
   'booker.rooms.uri': 'rooms',
   'booker.done': 'Done',
   'booker.promotion.question': 'Have a promotion code?',
   'booker.promotion.code': 'Promotion code',
   'booker.promotion.code.delete': 'Delete',
   'booker.promotion.code.apply': 'Apply',
   'booker.promotion.code.cancel': 'Cancel',
   'booker.rooms.total': '{rooms, plural, one {# room} other {# rooms}}',
   'booker.rooms.total.guests': '{guests, plural, one {# guest} other {# guests}}',
   'booker.rooms.years': 'years',
   'booker.rooms.minors.age': 'Minors age',
   'booker.rooms.close': 'Close',
   'booker.show.availability': 'Show availability',
   'booker.flight': 'flight',
   'booker.origin': 'Origin',
   'booker.no.results': 'We could not find results with the search criteria',
   'booker.no.results.length': 'Enter at least 3 characters',
   'booker.select.destination': 'Select destination',
   'booker.filter.results': 'Search by destination or hotel name',
   'booker.lowest.rate': 'Lowest rate per night in {currency}',
   'booker.lowest.rate2': 'Lowest rate per night in {currency}',
   'booker.dayPass.continue': 'Continue',
   'booker.dayPass.date': 'Date',
   'booker.dayPass.dates': 'Dates',
   'booker.dayPass.chooseDate': 'Choose a date',
   'booker.dayPass.chooseDates': 'Choose dates',
   'booker.dayPass.errors.date': 'Select a date',
   'booker.resume.date.format': 'MMM DD YYYY',
   'booker.resume.date.day.format': 'MMM DD',
   'booker.resume.date.month.format': 'MMM DD',
   'booker.change': 'Change',
   'booker.startDate': 'Start',
   'booker.endDate': 'End',
   'booker.to': 'to',
   'booker.quantity': 'Quantity',
   'booker.hotel.write': 'Write...',
   'booker.rooms.upToAge': 'Up to {maxAge} years',
   'booker.services': 'Services',
   'booker.rate.simbols.notavailable': "Not available",
   'booker.rate.simbols.lowest': " Lowest rate",
}
