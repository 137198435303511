import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react'
import { OverlayTrigger } from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

const DropdownContext = createContext<DropdownContextType>({} as DropdownContextType)

type DropdownContextType = {
   isOpen: boolean
   onToggle: (data: boolean) => void
}

type Props = {
   children?: ReactNode | ((props: DropdownContextType) => ReactNode)
   title?: string
   className?: string
   onClose?: Function
}

type MenuProps = {
   children?: ReactNode | ((props: DropdownContextType) => ReactNode)
   className?: string
}

type ToggleProps = {
   children?: ReactNode
   className?: string
   componentTag?: string
}

const Dropdown = ({ children, title, className, onClose }: Props) => {
   const [isOpen, setIsOpen] = useState(false)
   const prevState = useRef<boolean | undefined>()

   useEffect(() => {
      if (prevState.current && !isOpen) {
         onClose && onClose()
      }
      prevState.current = isOpen
   }, [isOpen, onClose])

   function onToggle(data: boolean) {
      setIsOpen(data)
   }
    

   return (
      <DropdownContext.Provider value={{ isOpen, onToggle }}>
         <div className={`relative ${className || ''}`}>
            <OverlayTrigger isOpen={isOpen} onToggle={onToggle}>
               {title && <OverlayTrigger.Trigger>{title}</OverlayTrigger.Trigger>}
               {typeof children === 'function' ? children({ isOpen, onToggle }) : children}
            </OverlayTrigger>
         </div>
      </DropdownContext.Provider>
   )
}

const Toggle = ({ children, className, componentTag }: ToggleProps) => {
   const { isOpen } = useContext(DropdownContext)
   return (
      <OverlayTrigger.Trigger className={className} {...(componentTag && { componentTag })}>
         {children}
         <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} className="ml-2" />
      </OverlayTrigger.Trigger>
   )
}

const Menu = ({ children, className }: MenuProps) => {
   const { isOpen, onToggle } = useContext(DropdownContext)
   if (!isOpen) {
      return null
   }
   return (
      <OverlayTrigger.Overlay
         className={`absolute bg-white text-[#333] z-10 shadow border border-black border-opacity-20 py-2 rounded min-w-[120px] ${
            className || ''
         }`}
      >
          {typeof children === 'function' ? children({ isOpen, onToggle }) : children}
      </OverlayTrigger.Overlay>
   )
}

Dropdown.Toggle = Toggle
Dropdown.Menu = Menu

export { Dropdown }
