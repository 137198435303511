//https://unicode-org.github.io/icu/userguide/format_parse/datetime/#datetime-format-syntax
import Slider from 'rc-slider'
import React, { useState } from 'react'
import { formatFromISO, formatFromMinutes, getMinutesFromISOTime } from '../../utils/timeSlider'
import 'rc-slider/assets/index.css'

type Props = {
   minString?: string
   maxString?: string
   min: number
   max: number
   initialValue?: number
   step?: number
   onChange?: Function
}

export const TimeSlider = ({ minString, maxString, min, max, initialValue, step = 15, onChange }: Props) => {
   const minSlider = minString ? getMinutesFromISOTime(minString) : min
   const maxSlider = maxString ? getMinutesFromISOTime(maxString) : max
   const [value, setValue] = useState(initialValue || maxSlider)

   function onChangeEvent(value: number | number[]) {
      setValue(typeof value === 'number' ? value : value[0])
   }

   function onAfterChange(newValue: number | number[]) {
      onChange && onChange(typeof newValue === 'number' ? newValue : newValue[0])
   }

   return (
      <div className="px-2">
         <div className="flex justify-between -mx-2">
            <div>{minString ? formatFromISO(minString) : formatFromMinutes(min)}</div>
            <div>{formatFromMinutes(value)}</div>
         </div>
         <Slider
            min={minSlider}
            max={maxSlider}
            value={value}
            onChange={onChangeEvent}
            step={step}
            onAfterChange={onAfterChange}
         />
      </div>
   )
}
