export default {
   "common.popup.exclusiveOffer": "¡Oferta exclusiva de hoy!",
   "common.popup.usePromoCode": "Usando el código de promoción: {code}",
   "common.popup.applyCode": "Aplicar código de promoción",
   "common.popup.noThanks": "No gracias",
   "common.popup.accept": "Aceptar",
   "common.popup.close": "Cerrar",
   "common.popup.bookNow": "Reservar ahora",
   "common.popup.maybeLater": "Tal vez mas tarde",
   "common.popup.ok": "OK",
   "common.rooms.promotion.conditions": "Condiciones",
   "common.rooms.promotion.book": "Reserva del {startDay} de {startMonth} al {endDay} de {endMonth}",
   "common.rooms.promotion.book.follow": "del {startDay} de {startMonth} al {endDay} de {endMonth}",
   "common.rooms.promotion.travel": "Hospédate del {startDay} de {startMonth} al {endDay} de {endMonth}",
   "common.rooms.promotion.travel.follow": "del {startDay} de {startMonth} al {endDay} de {endMonth}",
   "common.rooms.promotion.save.text": "Ahorra {discount} hoy",
   "common.rooms.additionalpromotion.save.text": "Ahorra {discount} adicional",
   "common.rooms.additionalpromotion.offer": "Ahorra {discount} adicional",
   "common.rooms.promotion.offer": "{quantity, plural, one {Oferta} other {Ofertas}}",
   "common.rooms.promotion.code": "Código de promoción",
   "common.rooms.promotion.minors.free": "{quantity, plural, =0 {Todos los menores gratis} =1 {# menor gratis} other {# menores gratis}}",
   "common.rooms.promotion.night.free": "{quantity, plural, =1 {1ra noche gratis cada {days} días} =2 {2da noche gratis cada {days} días}}",
   "common.rooms.promotion.off": "de descuento",
   "common.rooms.additionalpromotion.byRoom.total.discount": "de descuento por estancia",
   "common.rooms.additionalpromotion.byRoom.byNight.discount": "de descuento por habitación por noche",
   "common.rooms.promotion.byRoom.byNight.discount": "de descuento por habitación por noche",
   "common.rooms.promotion.minimumStay": "Estancia mínima de {minimumStay, plural, one {# noche} other {# noches}}",
   "common.rooms.promotion.includes": "Incluye",
   "common.rooms.promotion.weekdays.0": "lunes",
   "common.rooms.promotion.weekdays.1": "martes",
   "common.rooms.promotion.weekdays.2": "miércoles",
   "common.rooms.promotion.weekdays.3": "jueves",
   "common.rooms.promotion.weekdays.4": "viernes",
   "common.rooms.promotion.weekdays.5": "sábado",
   "common.rooms.promotion.weekdays.6": "domingo",
   "common.rooms.promotion.weekdays.invalid": "Oferta no válida los días: {weekdays}",
   "common.rooms.promotion.ebb": "Reserva con {days, plural, =1 {# día } other {# días }} de anticipación y recibe {discountPercentage}% de descuento",
   "common.rooms.promotion.termsAndConditions": "Términos y condiciones",
   "common.rooms.promotion.and": "y",
   "common.rooms.promotion.or": "o",
   "common.rooms.promotion.maxNightsFree": "Máximo {quantity, plural, one {# noche} other {# noches}} gratis",
};
