export default {
   "daypass.book": "Book now",
   "daypass.empty": "Not results found",
   "daypass.empty.error": "An error has ocurred",
   "daypass.numberOf": "{total, plural, one {# person} other {# persons}}",
   "daypass.numberOf.item": "{total, plural, one {# item} other {# items}}",
   "daypass.chooseService": "Choose service",
   "daypass.dateAndPeople": "Date y people",
   "daypass.options": "Options",
   "daypass.noService": "Not available services found",
   "daypass.chooseHotel": "Choose hotel",
};
