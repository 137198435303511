import { HotelType } from "../components/BookerWrapper"
import { apiCall } from "../utils/api"

export type HotelListRequestType = {
   locale: string
   account?: string
   id?: string | number
}

export async function getHotelList(request: HotelListRequestType): Promise<Array<HotelType>> {
   const { locale, account } = request
   try {
      const result = await apiCall(
         {
            url: `${process.env.REACT_APP_PUBLIC_HOTELS_API}/hotels`,
            method: 'GET',
         },
         account,
         locale,
      )
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (error) {
      return []
   }
}