import { AddOnQuoteListType } from "booker/src/partials/Services/ServiceSelector"
import { apiCall } from "../utils/api"

export type QuoteAddOnListRequest = {
   addonId: number
   currency: string
   language: string
   startDate?: string
   endDate?: string
   date?: string
   people?: number
   adults?: number
   children?: Array<number>
   quantity?: number
}

export type QuoteAddOnListResponse = AddOnQuoteListType

export async function quoteAddOnList(request: QuoteAddOnListRequest): Promise<QuoteAddOnListResponse | null> {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_ADDONS_API}/quote/list`,
         method: 'POST',
         data: request
      })
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (error) {
      return null
   }
}

