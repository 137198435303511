import { faClose, faTicket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { BookerContext, BookerFormValues } from '../Booker'
import { useFormContext, useWatch } from 'react-hook-form'
import classNames from 'classnames'
import Field from './Field'

const PromotionCodeField = () => {
   const { control, setValue, register } = useFormContext<BookerFormValues>()
   const { useTailwindPrefix, promoCodeInlineIcon } = useContext(BookerContext)
   const promotionCode = useWatch({ control, name: 'promotionCode' })
   const intl = useIntl()
   function reset() {
      setValue('promotionCode', '')
      setValue('loyaltyPlan.promotionCode', undefined)
   }
   return (
      <Field>
         {/* {promoCodeInlineIcon && ( */}
         <div
            className={classNames({
               'flex justify-center w-4 -rotate-45': !useTailwindPrefix,
               'tw-flex tw-justify-center tw-w-4 -tw-rotate-45': useTailwindPrefix,
            })}
         >
            <FontAwesomeIcon icon={faTicket} size="sm" />
         </div>
         {/* )} */}
         <input
            className={classNames({
               'focus:outline-none w-11/12 overflow-hidden text-ellipsis': !useTailwindPrefix,
               'focus:tw-outline-none tw-w-11/12 tw-overflow-hidden tw-text-ellipsis': useTailwindPrefix,
            })}
            type="text"
            {...register('promotionCode')}
            placeholder={intl.formatMessage({ id: 'booker.promotion.code' })}
         />
         <div
            className={classNames({
               'flex justify-center w-4': !useTailwindPrefix,
               'tw-flex tw-justify-center tw-w-4': useTailwindPrefix,
               hidden: !promotionCode && !useTailwindPrefix,
               'tw-hidden': !promotionCode && useTailwindPrefix,
            })}
            onClick={reset}
         >
            <FontAwesomeIcon icon={faClose} />
         </div>
      </Field>
   )
}

export default PromotionCodeField
