import classNames from 'classnames'
import React, { useContext } from 'react'
import { BookerContext } from '../Booker'

type Props = {
   children?: React.ReactNode
   className?: string
   type: 'button' | 'submit'
   vertical?: boolean
}

const FieldButton = ({ children, className, type, vertical }: Props) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   const fieldClassName = classNames(
      'itm-booker-field',
      {
         'w-full text-primary-booker-a11y hover:bg-primary-booker-10 bg-primary-booker px-3 whitespace-nowrap overflow-hidden text-ellipsis leading-[45px] border border-primary-booker-border rounded':
            !useTailwindPrefix,
         'tw-w-full tw-text-primary-a11y tw-text-primary-booker-ally hover:tw-bg-primary-booker-10 tw-bg-primary tw-bg-primary-booker tw-px-3 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-leading-[45px] tw-border tw-border-primary-booker-border tw-rounded':
            useTailwindPrefix,
         'md:rounded-none': !vertical && !useTailwindPrefix,
         'md:tw-rounded-none': !vertical && useTailwindPrefix,
      },

      className,
   )
   return (
      <button type={type} className={fieldClassName}>
         {children}
      </button>
   )
}

export default FieldButton
