import { faCalendarCheck, faClock } from '@fortawesome/free-regular-svg-icons'
import { faCar, faCheck, faDollarSign, faStar, faTimes, faUsd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { createContext, FC, PropsWithChildren, ReactFragment, ReactNode, useContext } from 'react'
import './Alert.css'

export enum alertTypes {
   ALLOTMENT = 'ALLOTMENT',
   RATES = 'RATES',
   VISITS = 'VISITS',
   PROMOTION = 'PROMOTION',
   REVIEWS = 'REVIEWS',
   GENERIC = 'GENERIC',
   GENERIC_SUCCESS = 'GENERIC_SUCCESS',
   GENERIC_WARNING = 'GENERIC_WARNING',
   GENERIC_DANGER = 'GENERIC_DANGER',
   GENERIC_INFO = 'GENERIC_INFO',
   FREE_CANCELLATION = 'FREE_CANCELLATION',
   FREE_TRANSFER = 'FREE_TRANSFER',
   BEST_PRICE_GUARANTEED = 'BEST_PRICE_GUARANTEED',
}

export type HotelAlert = {
   id: number
   alerts: Array<AlertType>
   rooms?: Array<AlertRoom>
}
export type AlertRoom = {
   id: number
   ratePlans: Array<AlertRatePlan>
}
export type AlertRatePlan = {
   id: number
   alerts: Array<{ text: string; type: string }>
}
export type AlertType = {
   title?: string
   subtitle?: string
   text?: JSX.Element | string | ReactNode
   type: alertTypes
}

type mode = 'light' | 'dark'

type Props = {
   type?: alertTypes
   isLoading?: boolean
   children?: ReactNode
   onDismiss?: Function
   mode?: mode
   className?: string
}

type ContentProps = {
   className?: string
}

type AlertContextType = {
   type?: alertTypes
   mode?: mode
   onDismiss?: Function
}

const AlertContext = createContext<AlertContextType>({} as AlertContextType)

const Body: FC<PropsWithChildren<ContentProps>> = ({ children, className }) => {
   const { onDismiss, mode } = useContext(AlertContext)
   return (
      <div
         className={classNames(
            {
               'border-t border-b first:border-l last:border-r': mode === 'light',
            },
            'first:rounded-tl first:rounded-bl last:rounded-tr last:rounded-br',
            'itm-media-body basis-full flex items-center justify-between px-3 py-4',
         )}
      >
         <div className={className}>{children}</div>
         {onDismiss && (
            <FontAwesomeIcon
               icon={faTimes}
               className="cursor-pointer pl-2"
               onClick={() => {
                  onDismiss()
               }}
            />
         )}
      </div>
   )
}

const Icon: FC<PropsWithChildren<ContentProps>> = ({ children, className }) => {
   const { type, mode } = useContext(AlertContext)
   function drawIcon() {
      switch (type) {
         case 'ALLOTMENT':
         case 'VISITS':
            return <FontAwesomeIcon icon={faClock} size={'2x'} fixedWidth />
         case 'PROMOTION':
         case 'RATES':
            return <FontAwesomeIcon icon={faUsd} size={'2x'} fixedWidth />
         case 'REVIEWS':
            return <FontAwesomeIcon icon={faStar} size={'2x'} fixedWidth />
         case 'FREE_CANCELLATION':
            return <FontAwesomeIcon icon={faCalendarCheck} size={'2x'} fixedWidth />
         case 'FREE_TRANSFER':
            return <FontAwesomeIcon icon={faCar} size={'2x'} fixedWidth />
         case 'BEST_PRICE_GUARANTEED':
            return <FontAwesomeIcon icon={faDollarSign} size={'2x'} fixedWidth />

         default:
            return <FontAwesomeIcon icon={faCheck} size={'2x'} fixedWidth />
      }
   }
   return (
      <div
         className={classNames(
            {
               'border-t border-b first:border-l last:border-r': mode === 'light',
            },
            'first:rounded-tl first:rounded-bl last:rounded-tr last:rounded-br',
            'itm-media-left flex items-center p-3',
            className,
         )}
      >
         {children || drawIcon()}
      </div>
   )
}

const Alert = ({ type = alertTypes.GENERIC, isLoading, children, onDismiss, mode, className }: Props) => {
   function getNoticeStyle() {
      if (isLoading) {
         return 'itm-alert--spinning'
      }
      switch (type) {
         case 'ALLOTMENT':
         case 'VISITS':
         case 'GENERIC_DANGER':
            return 'itm-alert--danger'
         case 'PROMOTION':
         case 'RATES':
         case 'GENERIC_SUCCESS':
         case 'FREE_CANCELLATION':
         case 'FREE_TRANSFER':
         case 'BEST_PRICE_GUARANTEED':
            return 'itm-alert--success'
         case 'REVIEWS':
         case 'GENERIC_WARNING':
            return 'itm-alert--warning'
         case 'GENERIC_INFO':
            return 'itm-alert--info'
         default:
            return 'itm-alert--default'
      }
   }
   return (
      <div
         className={classNames(
            `rounded flex items-stretch itm-alert`,
            getNoticeStyle(),
            mode === 'light' ? 'itm-alert--light' : null,
            className,
         )}
      >
         <AlertContext.Provider value={{ type, mode, onDismiss }}>{children}</AlertContext.Provider>
      </div>
   )
}

Alert.Body = Body
Alert.Icon = Icon

export default Alert
