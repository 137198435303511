import { AddOnType } from "booker/src/partials/AddOn"
import { apiCall } from "../utils/api"

export type AddOnListRequest = {
   hotelId?: number
   currency: string
   type?: 'TRANSFER' | 'GENERIC' | 'DAY_PASS',
   standalone?: boolean
}

export type AddOnListResponse = Array<AddOnType>

export async function getAddOnList({ hotelId, currency, ...rest }: AddOnListRequest): Promise<AddOnListResponse | null> {
   try {
      let url = `${process.env.REACT_APP_PUBLIC_ADDONS_API}/public/content/all/${currency}`
      if (hotelId && hotelId > 0) {
         url = `${process.env.REACT_APP_PUBLIC_ADDONS_API}/public/content/by-hotel/${hotelId}/${currency}`;
      }
      const result = await apiCall({
         url,
         method: 'GET',
         params: rest
      })
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (error) {
      return null
   }
}

