import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getHotelList, HotelListRequestType } from '../api/hotel'
import { HotelType } from '../components/BookerWrapper'

export type HotelListWrapper = {
   isPristine: boolean
   isFetching: boolean
   hasError: boolean,
   items: Array<HotelType>
}

const initialState: HotelListWrapper = {
   isPristine: true,
   isFetching: false,
   hasError: false,
   items: []
}

export const getHotelListThunk = createAsyncThunk(
   'getHotelList',
   async (
      request: HotelListRequestType,
      { rejectWithValue },
   ) => {
      const response = await getHotelList(request)
      if (response) {
         return response
      }
      return rejectWithValue(null)
   },
)

export const hotelListSlice = createSlice({
   name: 'hotelList',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getHotelListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getHotelListThunk.fulfilled, (state, action) => {
         state.isPristine = false
         state.isFetching = false
         state.hasError = false
         state.items = action.payload
      })
      builder.addCase(getHotelListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
         state.hasError = true
      })
   },
})
export const { clear } = hotelListSlice.actions
export default hotelListSlice.reducer
