import classNames from 'classnames'
import React, { ReactNode, useContext } from 'react'
import { BookerContext } from '../Booker'

type Props = {
   className?: string
   children: ReactNode
   clipMargin?: boolean
}

const FieldContainer = ({ className, children, clipMargin }: Props) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   return (
      <div
         className={classNames('itm-booker-field-container', className, {
            'w-full mb-3 text-[#565a5c] md:relative': !useTailwindPrefix,
            'tw-w-full tw-mb-3 tw-text-[#565a5c] md:tw-relative': useTailwindPrefix,
            'md:-mr-px': clipMargin && !useTailwindPrefix,
            'md:-tw-mr-px': clipMargin && useTailwindPrefix,
         })}
      >
         {children}
      </div>
   )
}

export default FieldContainer
