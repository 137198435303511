import { HotelFlightFormType, HotelFormType } from "booker"
import { Theme } from "utils/layout"
import { stageData } from './data.siteConfig';
import { ServiceFormType } from "booker/src/partials/Services/ServiceForm";

export type SelectedForm = 'HOTEL_FLIGHT' | 'HOTEL' | 'SERVICES' | null

export type Account = {
   code: string
   siteUrl: string
   languages: Array<{
      language: string
      siteUrl: string
   }>
}

export type BookerPlace = {
   id: number
   name: string
   place: {
      id: number
      name: string
      airportCode?: string
   },
   chain?: {
      id: number
      name: string
   },
   url?: string
}

export type ExternalServiceType = {
   displayText: string
   url: string
   icon?: string
}

export type SiteConfig = {
   language: string
   account: Account
   dateFormat: string
   showCalendarIcon?: boolean
   selectedForm?: SelectedForm
   hotelForm?: HotelFormType
   hotelFlightForm?: HotelFlightFormType
   serviceForm?: ServiceFormType
   theme?: Theme
   apiUserName: string
   apiPassword: string
   bookerPlaces: Array<BookerPlace>
   externalHotels?: Array<BookerPlace>
   showStandaloneServices?: boolean
   currency: string
   submitOnSameTab?: boolean
   externalServices?: Array<ExternalServiceType>
}

export type SiteConfigRequest = {
   account: string
   locale: string
   channelId?: number
   customUri?: string
}

export async function getSiteConfig(request: SiteConfigRequest): Promise<SiteConfig> {
   const { account, locale, channelId, customUri } = request
   try {
      const publicApiHotelKey = process.env.REACT_APP_PUBLIC_HOTEL_API_KEY
      if (publicApiHotelKey && publicApiHotelKey !== "") {
         const url = new URL(
            `${process.env.REACT_APP_PUBLIC_HOTELS_API}/siteConfig/${account}/lang/${locale}`
         );
         const params = [];
         if (channelId) {
            params.push(["channelId", channelId.toString()]);
         }
         if (customUri && customUri !== "") {
            params.push(["customUri", customUri]);
         }
         url.search = new URLSearchParams(params).toString();
         const requestHeaders: HeadersInit = new Headers();
         requestHeaders.set('x-api-key', publicApiHotelKey);

         const data = await fetch(url, {
            method: "GET",
            headers: requestHeaders
         });
         return data.json()
      } else {
         const result = await new Promise((resolve) => {
            setTimeout(() => {
               const siteConfigs = stageData // JSON.parse(process.env.NEXT_PUBLIC_SITE_CONFIGS || '[]')
               const siteConfigList = siteConfigs as Array<SiteConfig>
               const result = siteConfigList.find((r) => {
                  return r.account.code === account
               })
               resolve({ data: result })
            }, 100)
         }) as { data: any }
         return result.data
      }
   } catch (error) {
      throw error
   }
}