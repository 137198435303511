import { apiCall } from "../utils/api"
import { Promotion } from "booker"
import { PopUpPromotionRequestApi } from "../features/popUpPromotionSlice"

export type PopUpPromotionRequest = {
   hotelId?: number
   promotionCode?: string
}

export type PopUpPromotionResponse = Promotion

export async function getPopUpPromotion(request: PopUpPromotionRequestApi): Promise<PopUpPromotionResponse | null> {
   const { hotelId, language } = request
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_HOTELS_API}/promotions/exit-intent${hotelId && hotelId > 0 ? `/${hotelId}` : ''}`,
         method: 'GET',
      },
         undefined,
         language,)
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (error) {
      return null
   }
}

