import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../features/authSlice";
import siteConfigSlice from "../features/siteConfigSlice";
import hotelBookerRatesSlice from "../features/hotelBookerRatesSlice";
import popUpPromotionSlice from "../features/popUpPromotionSlice";
import addOnListSlice from "../features/addOnListSlice";
import quoteAddOnListSlice from "../features/quoteAddOnListSlice";
import hotelListSlice from "../features/hotelListSlice";

export const store = configureStore({
   reducer: {
      popUpPromotion: popUpPromotionSlice,
      hotelBookerRates: hotelBookerRatesSlice,
      authToken: authSlice,
      siteConfig: siteConfigSlice,
      addOnListWrapper: addOnListSlice,
      quoteAddOnListWrapper: quoteAddOnListSlice,
      hotelListWrapper: hotelListSlice
   }
})
export type StoreType = typeof store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch