import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { faHotel, faLocationDot, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

import { useIntl } from 'react-intl'
import { OverlayTrigger } from 'ui'
import Field from './Field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DestinationBookerType, HotelBookerType } from './HotelSelector'
import { getGroupedPlaces } from '../../../utils/bookerUtils'
import { BookerContext, BookerFormValues } from '../Booker'
import { useFormContext } from 'react-hook-form'
type props = {
   hotelList: Array<HotelBookerType>
   selection?: {
      id: number
      destinationType: 'hotel' | 'destination'
      aiportCode?: string
   }
   onSelect: Function
   myErrors: any
   vertical?: boolean
}
type OptionType = {
   destination: DestinationBookerType
   hotels: Array<HotelBookerType>
}
const AutoCompleteSelector = ({ hotelList, selection, onSelect, myErrors, vertical }: props) => {
   const intl = useIntl()
   const { useTailwindPrefix } = useContext(BookerContext)
   const { setValue } = useFormContext<BookerFormValues>()
   const originalOptions = getGroupedPlaces(hotelList)
   const [options, setOptions] = useState(originalOptions)
   const [isOpen, setIsOpen] = useState(false)
   const [searchInputValue, setSearchInputValue] = useState('')
   const hasMultipleDestinations = originalOptions.length > 1
   const hasManyResults = originalOptions.some((o) => o.hotels.length > 5)
   useEffect(() => {
      if (selection) {
         drawSelection()
      }
   }, [selection])
   function onToggle(data: boolean) {
      setIsOpen(data)
      if (data) {
         if (document && document.body) {
            let orig = document.body.className
            if (!useTailwindPrefix) {
               if (orig.indexOf('fixed md:relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'fixed md:relative'
               }
            } else {
               if (orig.indexOf('tw-fixed md:tw-relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'tw-fixed md:tw-relative'
               }
            }
         }
      } else {
         if (document && document.body) {
            if (!useTailwindPrefix) {
               document.body.className = document.body.className.replace(/ ?fixed md:relative/, '')
            } else {
               document.body.className = document.body.className.replace(/ ?tw-fixed md:tw-relative/, '')
            }
         }
      }
   }
   const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value
      if (selection && text !== drawSelection()) {
         setValue('destination', undefined)
      }
      let result = []
      if (text !== '' && text.length > 2) {
         const regex = new RegExp(text, 'i')
         result = hotelList.filter((h) => regex.test(h.name) || regex.test(h.destination.name))
      } else {
         result = hotelList
      }
      setSearchInputValue(text)
      setOptions(getGroupedPlaces(result))
      onToggle(true)
   }
   function drawSelection() {
      let result = null
      if (selection && hotelList.length > 0) {
         if (selection.destinationType === 'hotel') {
            result = hotelList.find((h) => h.id === selection.id)
         } else {
            result = options.find((o) => o.destination.id === selection.id)?.destination
         }
      }
      setSearchInputValue(result ? result.name : 'No encontrado')
      return result ? result.name : null
   }
   const bodyContainerClassName = classNames('flex', {
      'flex-wrap': hasMultipleDestinations && hasManyResults,
      'flex-col': !(hasMultipleDestinations && hasManyResults),
   })
   function onSelectEvent(data: any) {
      closeSelector()
      onSelect(data)
   }
   function closeSelector() {
      onToggle(false)
      setSearchInputValue('')
      setOptions(originalOptions)
      setValue('destination', undefined)
   }
   const renderOptions = (option: OptionType) => {
      let result = []
      const onDestinationSelection = () => {
         onSelectEvent({
            id: option.destination.id,
            destinationType: 'destination',
            airportCode: option.destination.airportCode,
         })
      }

      const classes = classNames('overflow-hidden', {
         'bg-gray-100': selection?.id === option.destination.id && selection.destinationType === 'destination',
         'basis-full': hasManyResults,
      })

      result.push(
         <div className={classes} key={`destination-${option.destination.id}`}>
            <div
               className="flex justify-between items-center py-3 px-5 whitespace-nowrap overflow-hidden text-ellipsis bg-gray-200 hover:bg-gray-100 cursor-pointer"
               onClick={onDestinationSelection}
            >
               {option.destination.name}
               <span className="text-sm text-link-blue">{intl.formatMessage({ id: 'booker.select.destination' })}</span>
            </div>
         </div>,
      )

      option.hotels.forEach((hotel) => {
         const clickHotel = () => {
            onSelectEvent({
               id: hotel.id,
               destinationType: 'hotel',
               airportCode: hotel.destination.airportCode,
            })
         }
         const classes = classNames('overflow-hidden', {
            'bg-gray-100': selection?.id === hotel.id && selection.destinationType === 'hotel',
            'basis-full md:basis-1/2 lg:basis-1/3': hasManyResults && hasMultipleDestinations,
         })

         result.push(
            <div key={`hotel-${hotel.id}`} className={classes}>
               <div
                  className="block py-3 px-5 whitespace-nowrap overflow-hidden text-ellipsis hover:bg-gray-100 cursor-pointer"
                  onClick={clickHotel}
               >
                  {hotel.name}
                  {hotel.destination?.name && (
                     <span className="block text-base text-gray-400">{hotel.destination.name}</span>
                  )}
               </div>
            </div>,
         )
      })

      return result
   }
   return (
      <OverlayTrigger isOpen={isOpen} onToggle={onToggle}>
         <OverlayTrigger.Trigger componentTag="div">
            <Field vertical={vertical} {...(myErrors && { hasError: true })}>
               <div className="w-4 flex justify-center">
                  <FontAwesomeIcon icon={hasMultipleDestinations ? faLocationDot : faHotel} />
               </div>
               <div className="whitespace-nowrap w-full overflow-hidden text-ellipsis">
                  <input
                     type="search"
                     value={searchInputValue}
                     className="outline-0 w-full text-ellipsis"
                     onChange={onInputChange}
                     placeholder={intl.formatMessage({ id: 'booker.hotel' })}
                     autoComplete="off"
                  />
               </div>
               {myErrors && (
                  <div className="text-red-400 mt-16 pl-4   overflow-hidden text-ellipsis absolute text-sm">
                     {myErrors?.type === 'required'
                        ? intl.formatMessage({
                             id: `booker.errors.selectHotel${hasMultipleDestinations ? '.destination' : ''}`,
                          })
                        : intl.formatMessage({ id: 'booker.errors.correctFields' })}
                  </div>
               )}
            </Field>
         </OverlayTrigger.Trigger>
         <OverlayTrigger.Overlay className="bg-white md:border border-gray-200 z-[1080] fixed inset-0 md:inset-auto md:shadow-lg md:absolute md:mt-0.5 md:min-w-[280px]">
            <div className="md:hidden flex justify-between items-center px-4 border-b border-gray-200 h-[50px]">
               <div className="text-3xl">{intl.formatMessage({ id: 'booker.hotel' })}</div>
               <button onClick={closeSelector} className="cursor-pointer text-2xl">
                  <FontAwesomeIcon icon={faTimes} />
               </button>
            </div>
            <div className="p-4 md:hidden">
               <div className="border rounded w-full px-2 flex items-center space-x-2">
                  <input
                     type="text"
                     value={searchInputValue}
                     className=" w-full py-2 outline-none text-ellipsis"
                     onChange={onInputChange}
                  />
                  {searchInputValue?.length > 0 && (
                     <FontAwesomeIcon icon={faTimes} onClick={closeSelector} color="#82888a" />
                  )}
               </div>
            </div>

            {searchInputValue && searchInputValue.length > 2 ? (
               options.length < 1 ? (
                  <div className="md:p-4 text-center">{intl.formatMessage({ id: 'booker.no.results' })}</div>
               ) : (
                  <div className={bodyContainerClassName}>{options.map(renderOptions)}</div>
               )
            ) : (
               <div className="md:p-4 text-center">{intl.formatMessage({ id: 'booker.no.results.length' })}</div>
            )}
         </OverlayTrigger.Overlay>
      </OverlayTrigger>
   )
}

export default AutoCompleteSelector
