import { useEffect, useState } from 'react';

export default function useInactivityUser(timeToInactive: number | null) {
   const [isInactivity, setIsInactivity] = useState(false);
   let timeOutId: NodeJS.Timeout;;
   const resetTimer = () => {
      clearTimeout(timeOutId);
      if (!isInactivity && timeToInactive) {
         timeOutId = setTimeout(() => {
            setIsInactivity(true);
         }, timeToInactive * 1000);
      }

   };
   const handleEventListeners = () => {
      if (isInactivity) {
         setIsInactivity(false);
      }
      resetTimer();
   };
   useEffect(() => {
      if (timeToInactive) {
         resetTimer();
         window.addEventListener('mousemove', handleEventListeners);
         window.addEventListener('keypress', handleEventListeners);
         window.addEventListener('scroll', handleEventListeners);
         window.addEventListener('click', handleEventListeners);
      }
      return () => {
         clearTimeout(timeOutId);
         window.removeEventListener('mousemove', handleEventListeners);
         window.removeEventListener('keypress', handleEventListeners);
         window.removeEventListener('scroll', handleEventListeners);
         window.removeEventListener('click', handleEventListeners);
      };
   }, [isInactivity, resetTimer]);
   return [isInactivity];
}