import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { QuoteAddOnListRequest, quoteAddOnList } from '../api/quoteAddOnList'
import { AddOnQuoteListType } from 'booker/src/partials/Services/ServiceSelector'

export type QuoteAddOnListWrapper = {
   isPristine: boolean
   isFetching: boolean
   hasError: boolean,
   data: AddOnQuoteListType | null
}

const initialState: QuoteAddOnListWrapper = {
   isPristine: true,
   isFetching: false,
   hasError: false,
   data: null
}

export const quoteAddOnListThunk = createAsyncThunk(
   'quoteAddOnList',
   async (
      request: QuoteAddOnListRequest,
      { rejectWithValue },
   ) => {
      const response = await quoteAddOnList(request)
      if (response) {
         return response
      }
      return rejectWithValue(null)
   },
)

export const quoteAddOnListSlice = createSlice({
   name: 'QuoteAddOnList',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(quoteAddOnListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(quoteAddOnListThunk.fulfilled, (state, action) => {
         state.isPristine = false
         state.isFetching = false
         state.hasError = false
         state.data = action.payload
      })
      builder.addCase(quoteAddOnListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
         state.hasError = true
      })
   },
})
export const { clear } = quoteAddOnListSlice.actions
export default quoteAddOnListSlice.reducer
