import React, { createContext, MouseEventHandler, useContext, useEffect, useRef } from 'react'

type TriggerProps = {
   componentTag?: string
   className?: string
   children?: React.ReactNode
}

type OverlayProps = {
   className?: string
   children?: React.ReactNode
   style?: React.CSSProperties
}

type Props = {
   children?: React.ReactNode
   onHide?: Function
   onShow?: Function
   onToggle: Function
   isOpen: boolean
}

type Context = {
   isOpen: boolean
   trigger: any
   overlay: any
   toggleOverlay: MouseEventHandler<any>
}

const OverlayTriggerContext = createContext({} as Context)

function useOverlayContext() {
   const context = useContext(OverlayTriggerContext)
   if (!context) {
      throw new Error(`Overlay compound components cannot be rendered outside the OverlayTrigger component`)
   }
   return context
}

const Overlay = ({ className, children, style }: OverlayProps) => {
   const { isOpen, overlay } = useOverlayContext()
   const styles = Object.assign({}, style, { display: isOpen ? 'block' : 'none' })
   return (
      <div ref={overlay} className={className} style={styles}>
         {children}
      </div>
   )
}

const Trigger = ({ componentTag = 'span', className, children }: TriggerProps) => {
   const { trigger, toggleOverlay } = useOverlayContext()
   return React.createElement(
      componentTag,
      { ref: trigger, className, ...(toggleOverlay && { onClick: toggleOverlay }) },
      children,
   )
}

const OverlayTrigger = ({ children, onToggle, isOpen }: Props) => {
   const trigger = useRef<HTMLSpanElement>(null)
   const overlay = useRef<HTMLDivElement>(null)
   // const [isOpen, _setIsOpen] = useState(isOpen2)
   const isOpenRef = React.useRef(isOpen)
   const setIsOpen = (data: boolean) => {
      isOpenRef.current = data
      onToggle(data)
   }

   useEffect(() => {
      if (isOpen) {
         window && window.addEventListener('click', onOverlayClose)
      } else {
         window && window.removeEventListener('click', onOverlayClose)
      }
      return () => {
         window && window.removeEventListener('click', onOverlayClose)
      }
   }, [isOpen])

   function onOverlayClose(e: any) {
      const isTriggerDescendant = trigger && trigger.current?.contains(e.target)
      const isOverlayDescendant = overlay && overlay.current?.contains(e.target)
      if (isOpenRef.current && !isTriggerDescendant && !isOverlayDescendant) {
         setIsOpen(false)
      }
   }

   function toggleOverlay(e: any) {
      const isOverlayDescendant = overlay && overlay.current?.contains(e.target)
      const isTriggerDescendant = trigger && trigger.current?.contains(e.target)

      if (!isOverlayDescendant) {
         if (isOpen) {
            setIsOpen(false)
            // onHide && onHide(isTriggerDescendant, isOverlayDescendant)
         } else {
            setIsOpen(true)
            // onShow && onShow()
         }
      }
   }

   const contextValue = {
      isOpen,
      trigger,
      overlay,
      toggleOverlay,
   }

   return <OverlayTriggerContext.Provider value={contextValue}>{children}</OverlayTriggerContext.Provider>
}

OverlayTrigger.Overlay = Overlay
OverlayTrigger.Trigger = Trigger

export { OverlayTrigger }
