import { Destination } from "booker"
import { Account, SelectedForm, BookerPlace, SiteConfig } from "../api/siteConfig"
import { ServiceFormType } from "booker/src/partials/Services/ServiceForm";

export function getSelectedForm({
   selectedForm,
   hotelForm,
   hotelFlightForm,
   serviceForm,
   showStandaloneServices
}: {
   selectedForm?: SelectedForm,
   hotelForm?: {},
   hotelFlightForm?: {},
   serviceForm?: {},
   showStandaloneServices?: boolean
}) {
   const booker = document.querySelector('.itm-booker');

   const dataSelectedForm = booker?.getAttribute("data-selected-form") || selectedForm;
   switch (dataSelectedForm) {
      case 'HOTEL_FLIGHT':
         return 'hotelFlight'
      case 'HOTEL':
         return 'hotel'
      case 'SERVICES':
         return 'service'
   }
   return hotelForm ? 'hotel' : hotelFlightForm ? 'hotelFlight' : (serviceForm && showStandaloneServices) ? 'service' : null;
}

export function isVertical() {
   const booker = document.querySelector('.itm-booker');
   const vertical = booker?.getAttribute("data-vertical") === "true" || false;
   return vertical
}

export function getDates(form?: { startDate?: string | null, endDate?: string | null }) {
   const booker = document.querySelector('.itm-booker');

   if (!form) {
      return {
         startDate: undefined,
         endDate: undefined
      };
   }

   const startDate = booker?.getAttribute("data-start-date");
   const endDate = booker?.getAttribute("data-end-date");

   return {
      startDate: form.startDate || startDate || undefined,
      endDate: form.endDate || endDate || undefined
   }
}

export function getSelectedDestination(bookerPlaces: Array<BookerPlace>, destination?: {
   destinationType: 'HOTEL' | 'DESTINATION'
   id: number
} | null): Destination | undefined {

   if (bookerPlaces.length === 0) {
      return undefined
   }

   //Revisa el atributo de hotel y si existe entre los bookerPlaces lo regresa
   const booker = document.querySelector('.itm-booker');
   const attHotelId = booker ? parseInt(booker.getAttribute("data-hotel") || '0') : 0;

   if (!isNaN(attHotelId) && attHotelId > 0) {
      const hotel = bookerPlaces.find(b => b.id === attHotelId)
      if (hotel) {
         return {
            id: attHotelId,
            destinationType: 'hotel' as const,
            airportCode: hotel.place.airportCode
         }
      }
   }

   //Revisa el atributo de destination y si existe entre los bookerPlaces lo regresa
   const selectedDestination = booker ? parseInt(booker.getAttribute("data-destination") || '0') : 0;

   if (!isNaN(selectedDestination) && selectedDestination > 0) {
      const place = bookerPlaces.find(b => b.place.id === selectedDestination)
      if (place) {
         return {
            id: selectedDestination,
            destinationType: 'destination' as const,
            airportCode: place.place.airportCode
         }
      }
   }

   //Si se define un destination en el siteConfig se mapea aquí
   if (destination) {
      switch (destination.destinationType) {
         case 'DESTINATION':
            const place = bookerPlaces.find(b => b.place.id === destination.id)
            if (place) {
               return {
                  id: destination.id,
                  destinationType: 'destination' as const,
                  airportCode: place.place.airportCode
               }
            }
            break;
         case 'HOTEL':
            const hotel = bookerPlaces.find(b => b.id === destination.id)
            if (hotel) {
               return {
                  id: destination.id,
                  destinationType: 'hotel' as const,
                  airportCode: hotel.place.airportCode
               }
            }
            break;
      }
   }

   //Busca si solo hay un destino o un hotel para seleccionarlo automáticamente
   const destinations = Array.from(new Set(bookerPlaces.map((h) => h.place.id)))
   if (destinations.length === 1) {
      return {
         destinationType: bookerPlaces.length > 1 ? 'destination' as const : 'hotel' as const,
         id: bookerPlaces.length > 1 ? bookerPlaces[0].place.id : bookerPlaces[0].id,
         airportCode: bookerPlaces[0].place.airportCode,
      }
   }

   return undefined;
}

export function bookerPlateToHotelType(bookerPlaces: Array<BookerPlace>, externalHotels?: Array<BookerPlace>) {
   const result = bookerPlaces.map(({ place, ...rest }) => (
      {
         ...rest,
         place: {
            id: place.id,
            name: place.name
         },
         destination: {
            id: place.id,
            name: place.name,
            airportCode: place.airportCode || ''
         },
      }
   ))
   if (externalHotels) {
      result.push(...externalHotels.map(({ place, ...rest }) => ({
         ...rest,
         place: {
            id: place.id,
            name: place.name
         },
         destination: {
            id: place.id,
            name: place.name,
            airportCode: place.airportCode || ''
         },
         isExternal: true
      })))
   }
   return result
}

export function getSiteUrl(props: { account: Account, language: string }) {
   if (!props) {
      return undefined
   }
   const currentSiteInfo = props.account.languages.find((l) => l.language === props.language)
   return currentSiteInfo?.siteUrl || props.account.siteUrl
}

function isValidServiceType(type?: string) {
   switch (type) {
      case 'DAY_PASS':
      case 'GENERIC':
      case 'TRANSFER':
         return type;
      default:
         return null;
   }
}

function getServiceType(siteConfig: SiteConfig) {
   const booker = document.querySelector('.itm-booker');
   const type = booker ? booker.getAttribute('data-service-type') : null;
   return isValidServiceType(type || siteConfig.serviceForm?.type);
}

function getServiceId() {
   const booker = document.querySelector('.itm-booker');
   const addOnId = booker ? booker.getAttribute('data-service') : null;
   return addOnId && !isNaN(parseInt(addOnId)) ? parseInt(addOnId) : 0;
}

function getServicesHotelId(siteConfig: SiteConfig, hotelIdList: Array<number>) {
   if (hotelIdList.length === 1) {
      return hotelIdList[0];
   }
   const booker = document.querySelector('.itm-booker');
   const hotelId = booker ? booker.getAttribute('data-hotel') : null;
   return hotelId && !isNaN(parseInt(hotelId)) ? parseInt(hotelId) : siteConfig.serviceForm?.hotelId;
}

export function getServiceForm(siteConfig: SiteConfig, hotelIdList: Array<number>, force?: boolean) {
   let serviceForm;

   if (siteConfig.serviceForm || (force && siteConfig.currency)) {
      const type = getServiceType(siteConfig);
      const hotelId = getServicesHotelId(siteConfig, hotelIdList);
      const serviceId = getServiceId();
      serviceForm = {
         hotelId,
         currency: siteConfig.serviceForm?.currency || siteConfig.currency,
         type,
         serviceId,
      } as ServiceFormType;
   }
   return serviceForm;
}

export function getForms(siteConfig: SiteConfig, hotelIdList: Array<number>, dataOnlyFormToShow: 'HOTEL_FLIGHT' | 'HOTEL' | 'SERVICES' | null) {
   const serviceForm = getServiceForm(siteConfig, hotelIdList)
   switch (dataOnlyFormToShow) {
      case 'HOTEL':
         return {
            hotelForm: siteConfig.hotelForm
         }
      case 'HOTEL_FLIGHT':
         return {
            hotelFlightForm: siteConfig.hotelFlightForm
         }
      case 'SERVICES':
         return {
            serviceForm: serviceForm || getServiceForm(siteConfig, hotelIdList, true)
         }
   }
   return {
      hotelForm: siteConfig.hotelForm,
      hotelFlightForm: siteConfig.hotelFlightForm,
      serviceForm
   }
}