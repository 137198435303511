import moment, { Moment } from 'moment'

export function isDateFromString(date: string | undefined, format = 'YYYY-MM-DD') {
   return date && moment(date, format).isValid()
}

export function getMomentDate(date: string | undefined, locale = 'es-mx', format = 'YYYY-MM-DD') {
   moment.locale(locale)
   return isDateFromString(date, format) ? moment(date, format) : null
}

export function getFormattedDate(date: Moment | null, locale = 'es-mx', format = 'YYYY-MM-DD') {
   if (date && date.isValid()) {
      moment.locale(locale)
      return moment(date).format(format)
   } else {
      return ''
   }
}
