import React, { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { SingleDatePicker } from 'react-dates'
import './SingleDateSelector.css'
import { getFormattedDate, getMomentDate } from '../../../utils/momentUtils'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

type Props = {
   value?: string
   onChange: Function
   id: string
   locale: string
   singleDatePickerProps: {
      placeholder?: string
      displayFormat?: string
      showDefaultInputIcon: boolean
      showClearDate: boolean
   }
   myErrors: any
   isMobile: boolean
   numberOfMonths: number
   showIcon?: boolean
   vertical?: boolean
   useTailwindPrefix?: boolean
}

export const SingleDateSelector = ({
   id,
   value,
   onChange,
   locale,
   singleDatePickerProps = {
      showClearDate: true,
      showDefaultInputIcon: true,
   },
   myErrors,
   isMobile,
   numberOfMonths,
   vertical,
   useTailwindPrefix,
}: Props) => {
   const [focused, setFocused] = useState<boolean>(false)
   const [date, setDate] = useState<Moment | null>(getMomentDate(value, locale))
   const intl = useIntl()

   useEffect(() => {
      setDate(getMomentDate(value, locale))
   }, [value, locale])

   const onFocusChange = ({ focused }: { focused: boolean | null }) => {
      setFocused(focused || false)
   }

   const onDateChange = (date: Moment | null) => {
      setDate(date)
      onChange(getFormattedDate(date, locale, 'YYYY-MM-DD'))
   }

   moment.locale(locale)

   return (
      <div
         className={classNames({
            'whitespace-nowrap': !useTailwindPrefix,
            'tw-whitespace-nowrap': useTailwindPrefix,
            'border-b-red-400 mb-5': myErrors && !useTailwindPrefix,
            'tw-border-b-red-400 tw-mb-5': myErrors && useTailwindPrefix,
            'md:mb-0': myErrors && !vertical && !useTailwindPrefix,
            'md:tw-mb-0': myErrors && !vertical && useTailwindPrefix,
         })}
      >
         <div className={classNames('itm-sds', { 'itm-sds-h': !vertical, 'itm-sds--error': myErrors })}>
            {locale && (
               <SingleDatePicker
                  firstDayOfWeek={1}
                  transitionDuration={0}
                  date={date}
                  id={id}
                  onDateChange={onDateChange}
                  focused={focused}
                  onFocusChange={onFocusChange}
                  readOnly
                  hideKeyboardShortcutsPanel
                  numberOfMonths={numberOfMonths}
                  {...(isMobile && {
                     orientation: 'vertical',
                     withFullScreenPortal: true,
                  })}
                  {...singleDatePickerProps}
               />
            )}
         </div>
         {myErrors && (
            <div
               className={classNames({
                  'text-red-400 mt-2 text-sm overflow-hidden text-ellipsis absolute': !useTailwindPrefix,
                  'tw-text-red-400 tw-mt-2 tw-text-sm tw-overflow-hidden tw-text-ellipsis tw-absolute':
                     useTailwindPrefix,
               })}
            >
               {myErrors.message || intl.formatMessage({ id: 'booker.errors.correctFields' })}
            </div>
         )}
      </div>
   )
}
