export default {
   "daypass.book": "Reservar",
   "daypass.empty": "No se encontraron resultados",
   "daypass.empty.error": "Ocurrió un error",
   "daypass.numberOf": "{total, plural, one {# persona} other {# personas}}",
   "daypass.numberOf.item": "{total, plural, one {# pieza} other {# piezas}}",
   "daypass.chooseService": "Elegir servicio",
   "daypass.dateAndPeople": "Fecha y personas",
   "daypass.options": "Opciones",
   "daypass.noService": "No se encontraron servicios disponibles",
   "daypass.chooseHotel": "Elegir hotel",
};
