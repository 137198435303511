import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { BookerContext } from '../Booker'
import classNames from 'classnames'

type Props = {
   error?: boolean | null
   errorText?: string | null
}

export const AddOnEmpty = ({ error, errorText }: Props) => {
   const intl = useIntl()
   const { useTailwindPrefix } = useContext(BookerContext)

   return (
      <div
         className={classNames({
            'py-[1.9em] px-0 text-center': !useTailwindPrefix,
            'tw-py-[1.9em] tw-px-0 tw-text-center': useTailwindPrefix,
         })}
      >
         {errorText || intl.formatMessage({ id: `daypass.empty${error ? '.error' : ''}` })}
      </div>
   )
}
