import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { createContext, useContext, useState } from 'react'
import Field from './Field'
import { useIntl } from 'react-intl'
import { Button, CounterSelector, OverlayTrigger } from 'ui'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { BookerContext, BookerFormValues } from '../Booker'
import { GuestSelector } from './GuestSelector'
import { getPropertyValue } from 'utils/validation'
import classNames from 'classnames'

type Props = {
   minRooms: number
   maxRooms: number
   minAdults: number
   maxAdults: number
   minChildren: number
   maxChildren: number
   minChildrenAge: number
   maxChildrenAge: number
}

type RoomSelectorContextProps = Props & {
   vertical?: boolean
   useTailwindPrefix?: boolean
}

export const RoomsSelectorContext = createContext<RoomSelectorContextProps>({} as RoomSelectorContextProps)

type ChildrenAge = {
   age?: number
}

export type RoomFormType = {
   adults: number
   children: number
   childrenAges?: Array<ChildrenAge>
}

const RoomsSelector = (props: Props) => {
   const { minRooms, maxRooms } = props
   const { vertical, useTailwindPrefix } = useContext(BookerContext)
   const {
      control,
      formState: { errors },
   } = useFormContext<BookerFormValues>()
   const { fields, append, remove } = useFieldArray({
      control,
      name: 'rooms',
   })
   const intl = useIntl()
   const [isOpen, setIsOpen] = useState(false)

   function closeSelector() {
      onToggle(false)
   }

   function onToggle(data: boolean) {
      setIsOpen(data)
      if (data) {
         if (document && document.body) {
            let orig = document.body.className
            if (!useTailwindPrefix) {
               if (orig.indexOf('fixed md:relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'fixed md:relative'
               }
            } else {
               if (orig.indexOf('tw-fixed md:tw-relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'tw-fixed md:tw-relative'
               }
            }
         }
      } else {
         if (document && document.body) {
            if (!useTailwindPrefix) {
               document.body.className = document.body.className.replace(/ ?fixed md:relative/, '')
            } else {
               document.body.className = document.body.className.replace(/ ?tw-fixed md:tw-relative/, '')
            }
         }
      }
   }

   function onChangeEvent(count: number) {
      const currentCount = fields.length
      if (count > currentCount) {
         Array.from({ length: count - currentCount }, (_, i) => i).forEach(() =>
            append({
               adults: 2,
               children: 0,
               childrenAges: [],
            }),
         )
      } else {
         for (let i = fields.length; i > fields.length - (currentCount - count); i--) {
            remove(i - 1)
         }
      }
   }
   const rooms = useWatch({ name: 'rooms', control })
   const guest = rooms
      ? rooms.reduce((p, r) => {
           return (p += r.adults + r.children)
        }, 0)
      : 0
   const myErrors = getPropertyValue(errors, 'rooms')
   return (
      <OverlayTrigger isOpen={isOpen} onToggle={onToggle}>
         <OverlayTrigger.Trigger
            componentTag="div"
            className={classNames({
               'mb-5': myErrors && !isOpen && !useTailwindPrefix,
               'tw-mb-5': myErrors && !isOpen && useTailwindPrefix,
               'md:mb-0': myErrors && !isOpen && !vertical && !useTailwindPrefix,
               'md:tw-mb-0': myErrors && !isOpen && !vertical && useTailwindPrefix,
            })}
         >
            <Field {...(myErrors && { hasError: true })}>
               <div
                  className={classNames({
                     'w-4 flex justify-center': !useTailwindPrefix,
                     'tw-w-4 tw-flex tw-justify-center': useTailwindPrefix,
                  })}
               >
                  <FontAwesomeIcon icon={faBed} />
               </div>
               <div
                  className={classNames({
                     'whitespace-nowrap overflow-hidden text-ellipsis': !useTailwindPrefix,
                     'tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis': useTailwindPrefix,
                  })}
               >
                  {intl.formatMessage({ id: 'booker.rooms.total' }, { rooms: rooms?.length })},&nbsp;
                  {intl.formatMessage({ id: 'booker.rooms.total.guests' }, { guests: guest })}
               </div>
            </Field>
            {myErrors && (
               <div
                  className={classNames({
                     'text-red-400 mt-2 text-sm overflow-hidden text-ellipsis absolute': !useTailwindPrefix,
                     'tw-text-red-400 tw-mt-2 tw-text-sm tw-overflow-hidden tw-text-ellipsis tw-absolute':
                        useTailwindPrefix,
                  })}
               >
                  {myErrors?.flatMap((r: any) => r.childrenAges)?.some((c: any) => c?.age?.type === 'required')
                     ? intl.formatMessage({ id: 'booker.errors.selectMinorsAge' })
                     : intl.formatMessage({ id: 'booker.errors.correctFields' })}
               </div>
            )}
         </OverlayTrigger.Trigger>
         <OverlayTrigger.Overlay
            className={classNames('itm-popover-guest-selector', {
               'bg-white md:border border-gray-200 z-[1080] fixed inset-0 md:inset-auto md:shadow-lg md:absolute md:mt-0.5 md:w-[280px]':
                  !useTailwindPrefix,
               'tw-bg-white md:tw-border tw-border-gray-200 tw-z-[1080] tw-fixed tw-inset-0 md:tw-inset-auto md:tw-shadow-lg md:tw-absolute md:tw-mt-0.5 md:tw-w-[280px]':
                  useTailwindPrefix,
            })}
         >
            <div
               className={classNames({
                  'md:hidden flex justify-between items-center px-4 border-b border-gray-200 h-[50px]':
                     !useTailwindPrefix,
                  'md:tw-hidden tw-flex tw-justify-between tw-items-center tw-px-4 tw-border-b tw-border-gray-200 tw-h-[50px]':
                     useTailwindPrefix,
               })}
            >
               <div className="text-3xl">{intl.formatMessage({ id: 'booker.rooms' })}</div>
               <button type="button" onClick={closeSelector} className="cursor-pointer text-2xl">
                  <FontAwesomeIcon icon={faTimes} />
               </button>
            </div>
            <RoomsSelectorContext.Provider value={{ ...props, useTailwindPrefix }}>
               <div
                  className={classNames({
                     'fixed overflow-auto inset-0 top-[50px] md:inset-auto md:relative': !useTailwindPrefix,
                     'tw-fixed tw-overflow-auto tw-inset-0 tw-top-[50px] md:tw-inset-auto md:tw-relative':
                        useTailwindPrefix,
                  })}
               >
                  <div
                     className={classNames({
                        'flex flex-col': !useTailwindPrefix,
                        'tw-flex tw-flex-col': useTailwindPrefix,
                     })}
                  >
                     <div
                        className={classNames({
                           'p-4 flex items-center justify-between border-b': !useTailwindPrefix,
                           'tw-p-4 tw-flex tw-items-center tw-justify-between tw-border-b': useTailwindPrefix,
                        })}
                     >
                        <div>{intl.formatMessage({ id: 'booker.rooms' })}</div>
                        <CounterSelector
                           value={fields.length}
                           minValue={minRooms}
                           maxValue={maxRooms}
                           onChange={onChangeEvent}
                           useTailwindPrefix={useTailwindPrefix}
                        />
                     </div>
                  </div>
                  <div
                     className={classNames({
                        'flex flex-col overflow-auto md:max-h-[60vh]': !useTailwindPrefix,
                        'tw-flex tw-flex-col tw-overflow-auto md:tw-max-h-[60vh]': useTailwindPrefix,
                     })}
                  >
                     {fields.map(({ id }, index) => (
                        <React.Fragment key={id}>
                           <div
                              className={classNames({
                                 'px-4 py-2 uppercase bg-gray-100 text-sm border-t first:border-none border-gray-200':
                                    !useTailwindPrefix,
                                 'tw-px-4 tw-py-2 tw-uppercase tw-bg-gray-100 tw-text-sm tw-border-t first:tw-border-none tw-border-gray-200':
                                    useTailwindPrefix,
                              })}
                           >
                              {intl.formatMessage({ id: 'booker.roomWithIndex' }, { roomIndex: index + 1 })}
                           </div>
                           <GuestSelector index={index} />
                        </React.Fragment>
                     ))}
                  </div>
               </div>
            </RoomsSelectorContext.Provider>
            <div
               className={classNames({
                  'fixed inset-0 top-auto px-5 py-3 border-t border-gray-200 bg-white md:relative md:inset-auto':
                     !useTailwindPrefix,
                  'tw-fixed tw-inset-0 tw-top-auto tw-px-5 tw-py-3 tw-border-t tw-border-gray-200 tw-bg-white md:tw-relative md:tw-inset-auto':
                     useTailwindPrefix,
               })}
            >
               <Button
                  bsStyle="outline-primary"
                  className={classNames({
                     'block md:hidden w-full': !useTailwindPrefix,
                     'tw-block md:tw-hidden tw-w-full': useTailwindPrefix,
                  })}
                  onClick={closeSelector}
               >
                  {intl.formatMessage({ id: 'booker.done' })}
               </Button>
               <button
                  type="button"
                  className={classNames({
                     'hidden md:block w-full text-center leading-loose': !useTailwindPrefix,
                     'tw-hidden md:tw-block tw-w-full tw-text-center tw-leading-loose': useTailwindPrefix,
                  })}
                  onClick={closeSelector}
               >
                  {intl.formatMessage({ id: 'booker.rooms.close' })}
               </button>
            </div>
         </OverlayTrigger.Overlay>
      </OverlayTrigger>
   )
}

export default RoomsSelector
