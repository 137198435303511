import classNames from 'classnames'
import React, { useContext } from 'react'
import { BookerContext } from '../Booker'

type Props = {
   children?: React.ReactNode
   className?: string
   // vertical?: boolean
   hasErrors?: boolean
}

const Field = ({ children, className, hasErrors }: Props) => {
   const { vertical, useTailwindPrefix } = useContext(BookerContext)
   return (
      <div
         className={classNames(
            'itm-booker-field',
            {
               'flex gap-2 items-center bg-white px-3 leading-[45px] cursor-pointer border border-gray-200 rounded-[3px]':
                  !useTailwindPrefix,
               'tw-flex tw-gap-2 tw-items-center tw-bg-white tw-px-3 tw-leading-[45px] tw-cursor-pointer tw-border tw-border-gray-200 tw-rounded-[3px]':
                  useTailwindPrefix,
               'md:rounded-none': !vertical && !useTailwindPrefix,
               'md:tw-rounded-none': !vertical && useTailwindPrefix,
               'border-b-red-400': hasErrors && !useTailwindPrefix,
               'tw-border-b-red-400': hasErrors && useTailwindPrefix,
            },
            className,
         )}
      >
         {children}
      </div>
   )
}

export default Field
