import classNames from 'classnames'
import React, { useContext } from 'react'
import { BookerContext } from '../Booker'
import Field from '../Booker/components/Field'
import FieldContainer from '../Booker/components/FieldContainer'

export const LoadingForm = () => {
   const { vertical, useTailwindPrefix } = useContext(BookerContext)
   return (
      <div
         className={classNames({
            "before:content-[' '] before:table after:block after:content-[''] after:clear-both": !useTailwindPrefix,
            "before:tw-content-[' '] before:tw-table after:tw-block after:tw-content-[''] after:tw-clear-both":
               useTailwindPrefix,
         })}
      >
         <div
            className={classNames({
               'flex flex-col min-w-[250px]': !useTailwindPrefix,
               'tw-flex tw-flex-col tw-min-w-[250px]': useTailwindPrefix,
               'md:flex-row': !vertical && !useTailwindPrefix,
               'md:tw-flex-row': !vertical && useTailwindPrefix,
            })}
         >
            <FieldContainer
               className={classNames({
                  'md:w-4/12': !vertical && !useTailwindPrefix,
                  'md:tw-w-4/12': !vertical && useTailwindPrefix,
               })}
               clipMargin
            >
               <Field
                  className={classNames({
                     'text-transparent': !useTailwindPrefix,
                     'tw-text-transparent': useTailwindPrefix,
                  })}
               >
                  _
               </Field>
            </FieldContainer>
            <FieldContainer
               className={classNames({
                  'md:w-4/12': !vertical && !useTailwindPrefix,
                  'md:tw-w-4/12': !vertical && useTailwindPrefix,
               })}
               clipMargin
            >
               <Field
                  className={classNames({
                     'text-transparent': !useTailwindPrefix,
                     'tw-text-transparent': useTailwindPrefix,
                  })}
               >
                  _
               </Field>
            </FieldContainer>
            <FieldContainer
               className={classNames({
                  'md:w-4/12': !vertical && !useTailwindPrefix,
                  'md:tw-w-4/12': !vertical && useTailwindPrefix,
               })}
               clipMargin
            >
               <Field
                  className={classNames({
                     'text-transparent': !useTailwindPrefix,
                     'tw-text-transparent': useTailwindPrefix,
                  })}
               >
                  _
               </Field>
            </FieldContainer>
         </div>
      </div>
   )
}
