import { useEffect, useState } from 'react'

const useEventListener = () => {
   let prev: number | null = null
   const [isAlmosExit, setIsAlmosExit] = useState(false)
   const onAlmostExit = (e: MouseEvent) => {
      if (e.clientY < 15) {
         if (prev && prev > e.clientY) {
            setIsAlmosExit(true)
         }
      }
      prev = e.clientY
   }
   useEffect(() => {
      window.addEventListener('mousemove', onAlmostExit, false)
      return () => {
         window.removeEventListener('mousemove', onAlmostExit, false)
      }
   }, [onAlmostExit])

   return [isAlmosExit, prev]
}

export default useEventListener
