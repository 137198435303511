import { faArrowUpRightFromSquare, faHotel, faLocationDot, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { ChangeEvent, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { OverlayTrigger } from 'ui'
import { getGroupedChains } from '../../../utils/bookerUtils'

import Field from './Field'
import { BookerContext } from '../Booker'
import { useScrollWithShadow } from '../../../hooks/useScrollWithShadows'
export type DestinationBookerType = {
   id: number
   name: string
   airportCode: string
}

export type ChainBookerType = {
   id: number
   name: string
}

export type HotelBookerType = {
   id: number
   name: string
   url?: string
   destination: DestinationBookerType
   chain?: ChainBookerType | null
   isExternal?: boolean
   alternativePath?: string
}

type Props = {
   hotelList: Array<HotelBookerType>
   selection?: {
      id: number
      destinationType: 'hotel' | 'destination'
      aiportCode?: string
   }
   onSelect: Function
   myErrors: any
   hasMultiDestination: boolean
   showChains?: boolean
}

type OptionType = {
   destination: DestinationBookerType
   hotels: Array<HotelBookerType>
   nonSelectable: boolean
}

type ChainOptionType = {
   chain: ChainBookerType | null
   items: Array<OptionType>
}

const HotelSelector = ({ hotelList, selection, onSelect, myErrors, hasMultiDestination, showChains }: Props) => {
   const intl = useIntl()
   const { vertical, useTailwindPrefix } = useContext(BookerContext)
   const originalOptions = getGroupedChains(hotelList)
   const [options, setOptions] = useState(originalOptions)
   const [isOpen, setIsOpen] = useState(false)
   const { containerRef, drawShadows, onScrollHandler, parentStyle } = useScrollWithShadow()
   const [searchInputValue, setSearchInputValue] = useState('')

   const hasMultipleDestinations = hasMultiDestination
   const showTitles = originalOptions.flatMap((o) => o.items).some((p) => p.hotels.length > 1)
   const hasManyResults =
      hotelList.length > 8 || (hasMultipleDestinations && originalOptions.flatMap((o) => o.items).length > 3)

   function onSelectEvent(data: any) {
      closeSelector()
      onSelect(data)
   }

   function closeSelector() {
      onToggle(false)
      setSearchInputValue('')
      setOptions(originalOptions)
   }

   function onToggle(data: boolean) {
      setIsOpen(data)
      if (data) {
         if (document && document.body) {
            let orig = document.body.className
            if (!useTailwindPrefix) {
               if (orig.indexOf('fixed md:relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'fixed md:relative'
               }
            } else {
               if (orig.indexOf('tw-fixed md:tw-relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'tw-fixed md:tw-relative'
               }
            }
         }
      } else {
         if (document && document.body) {
            if (!useTailwindPrefix) {
               document.body.className = document.body.className.replace(/ ?fixed md:relative/, '')
            } else {
               document.body.className = document.body.className.replace(/ ?tw-fixed md:tw-relative/, '')
            }
         }
      }
   }

   function renderChainOptions(option: ChainOptionType) {
      const result = []

      if (showChains && originalOptions.length > 1) {
         result.push(
            <div
               className={classNames({
                  'overflow-hidden': !useTailwindPrefix,
                  'tw-overflow-hidden': useTailwindPrefix,
                  'bg-[#EEEEEE]': !useTailwindPrefix,
                  'tw-bg-[#EEEEEE]': useTailwindPrefix,
                  'basis-full': hasManyResults && !useTailwindPrefix,
                  'tw-basis-full': hasManyResults && useTailwindPrefix,
               })}
            >
               <div
                  className={classNames({
                     'py-3 px-5 whitespace-nowrap overflow-hidden text-ellipsis': !useTailwindPrefix,
                     'tw-py-3 tw-px-5 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis': useTailwindPrefix,
                     invisible: (!option.chain || option.chain.name === '') && !useTailwindPrefix,
                     'tw-invisible': (!option.chain || option.chain.name === '') && useTailwindPrefix,
                  })}
               >
                  {option.chain?.name || '.'}
               </div>
            </div>,
         )
      }

      result.push(option.items.map(renderOptions))
      return result
   }

   function renderOptions(option: OptionType) {
      let result = []

      if (showTitles && hasMultipleDestinations) {
         const onDestinationSelection = () => {
            onSelectEvent({
               id: option.destination.id,
               destinationType: 'destination',
               airportCode: option.destination.airportCode,
            })
         }

         result.push(
            <div
               className={classNames({
                  'overflow-hidden': !useTailwindPrefix,
                  'tw-overflow-hidden': useTailwindPrefix,
                  'bg-[#EEEEEE]':
                     selection?.id === option.destination.id &&
                     selection.destinationType === 'destination' &&
                     !useTailwindPrefix,
                  'tw-bg-[#EEEEEE]':
                     selection?.id === option.destination.id &&
                     selection.destinationType === 'destination' &&
                     useTailwindPrefix,
                  'basis-full': hasManyResults && !useTailwindPrefix,
                  'tw-basis-full': hasManyResults && useTailwindPrefix,
               })}
               key={`destination-${option.destination.id}`}
            >
               <div
                  className={classNames({
                     'flex justify-between items-center py-3 px-5 whitespace-nowrap overflow-hidden text-ellipsis bg-[#EEEEEE]':
                        !useTailwindPrefix,
                     'tw-flex tw-justify-between tw-items-center tw-py-3 tw-px-5 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-bg-[#EEEEEE]':
                        useTailwindPrefix,
                     'hover:bg-neutral-100 cursor-pointer': !option.nonSelectable && !useTailwindPrefix,
                     'hover:tw-bg-neutral-100 tw-cursor-pointer': !option.nonSelectable && useTailwindPrefix,
                  })}
                  {...(!option.nonSelectable && { onClick: onDestinationSelection })}
               >
                  {option.destination.name}
                  {!option.nonSelectable && (
                     <span
                        className={classNames({
                           'text-xs text-link-blue': !useTailwindPrefix,
                           'tw-text-xs tw-text-link-blue': useTailwindPrefix,
                        })}
                     >
                        {intl.formatMessage({ id: 'booker.select.destination' })}
                     </span>
                  )}
               </div>
            </div>,
         )
      }

      option.hotels.forEach((hotel) => {
         const clickHotel = () => {
            onSelectEvent({
               id: hotel.id,
               destinationType: 'hotel',
               airportCode: hotel.destination.airportCode,
            })
         }
         const classes = classNames({
            'overflow-hidden': !useTailwindPrefix,
            'tw-overflow-hidden': useTailwindPrefix,
            'bg-neutral-100': selection?.id === hotel.id && selection.destinationType === 'hotel' && !useTailwindPrefix,
            'tw-bg-neutral-100':
               selection?.id === hotel.id && selection.destinationType === 'hotel' && useTailwindPrefix,
            'basis-full md:basis-1/2 lg:basis-1/3': hasManyResults && !useTailwindPrefix,
            'tw-basis-full md:tw-basis-1/2 lg:tw-basis-1/3': hasManyResults && useTailwindPrefix,
         })
         if (hotel.isExternal) {
            result.push(
               <div key={`hotel-${hotel.id}`} className={classes}>
                  <a
                     href={hotel.url}
                     target="_blank"
                     className={classNames({
                        'flex items-center justify-between py-3 px-5 whitespace-nowrap overflow-hidden text-ellipsis hover:bg-neutral-100 cursor-pointer':
                           !useTailwindPrefix,
                        'tw-flex tw-items-center tw-justify-between tw-py-3 tw-px-5 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis hover:tw-bg-neutral-100 tw-cursor-pointer':
                           useTailwindPrefix,
                     })}
                  >
                     <span>
                        {hotel.name}
                        {hotel.destination.name && (
                           <span
                              className={classNames({
                                 'block text-base text-gray-400': !useTailwindPrefix,
                                 'tw-block tw-text-base tw-text-gray-400': useTailwindPrefix,
                              })}
                           >
                              {hotel.destination.name}
                           </span>
                        )}
                     </span>
                     <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        className="text-neutral-300 tw-text-neutral-300"
                     />
                  </a>
               </div>,
            )
         } else {
            result.push(
               <div key={`hotel-${hotel.id}`} className={classes}>
                  <div
                     className={classNames({
                        'block py-3 px-5 whitespace-nowrap overflow-hidden text-ellipsis hover:bg-neutral-100 cursor-pointer':
                           !useTailwindPrefix,
                        'tw-block tw-py-3 tw-px-5 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis hover:tw-bg-neutral-100 tw-cursor-pointer':
                           useTailwindPrefix,
                     })}
                     onClick={clickHotel}
                  >
                     {hotel.name}
                     {hotel.destination.name && (
                        <span
                           className={classNames({
                              'block text-base text-gray-400': !useTailwindPrefix,
                              'tw-block tw-text-base tw-text-gray-400': useTailwindPrefix,
                           })}
                        >
                           {hotel.destination.name}
                        </span>
                     )}
                  </div>
               </div>,
            )
         }
      })

      return result
   }

   function drawSelection() {
      let result = null
      if (selection && hotelList.length > 0) {
         if (selection.destinationType === 'hotel') {
            result = hotelList.find((h) => h.id === selection.id)
         } else {
            result = options.flatMap((o) => o.items).find((o) => o.destination?.id === selection.id)?.destination
         }
      }
      return result ? result.name : null
   }

   const bodyClassName = classNames({
      'fixed overflow-auto inset-0 top-[50px] md:inset-auto md:relative': !useTailwindPrefix,
      'tw-fixed tw-overflow-auto tw-inset-0 tw-top-[50px] md:tw-inset-auto md:tw-relative': useTailwindPrefix,
      'md:w-[400px] lg:w-[750px]': hasManyResults && !useTailwindPrefix,
      'md:tw-w-[400px] lg:tw-w-[750px]': hasManyResults && useTailwindPrefix,
   })

   const bodyContainerClassName = classNames({
      'flex md:max-h-[60vh] md:overflow-auto': !useTailwindPrefix,
      'tw-flex md:tw-max-h-[60vh] md:tw-overflow-auto': useTailwindPrefix,
      'flex-wrap': hasManyResults && !useTailwindPrefix,
      'tw-flex-wrap': hasManyResults && useTailwindPrefix,
      'flex-col': !hasManyResults && !useTailwindPrefix,
      'tw-flex-col': !hasManyResults && useTailwindPrefix,
   })

   function onSearchInputChange(e: ChangeEvent<HTMLInputElement>) {
      const text = e.target.value
      let result = []

      if (text !== '') {
         const regex = new RegExp(text, 'i')
         result = hotelList.filter((h) => regex.test(h.name) || regex.test(h.destination.name))
      } else {
         result = hotelList
      }

      setSearchInputValue(text)
      setOptions(getGroupedChains(result))
   }

   return (
      <OverlayTrigger isOpen={isOpen} onToggle={onToggle}>
         <OverlayTrigger.Trigger
            componentTag="div"
            className={classNames({
               'mb-5': myErrors && !useTailwindPrefix,
               'tw-mb-5': myErrors && useTailwindPrefix,
               'md:mb-0': myErrors && !vertical && !useTailwindPrefix,
               'md:tw-mb-0': myErrors && !vertical && useTailwindPrefix,
            })}
         >
            <Field vertical={vertical} {...(myErrors && { hasError: true })}>
               <div
                  className={classNames({
                     'w-4 flex justify-center': !useTailwindPrefix,
                     'tw-w-4 tw-flex tw-justify-center': useTailwindPrefix,
                  })}
               >
                  {selection ? (
                     <FontAwesomeIcon icon={selection.destinationType === 'hotel' ? faHotel : faLocationDot} />
                  ) : (
                     <FontAwesomeIcon icon={hasMultipleDestinations ? faLocationDot : faHotel} />
                  )}
               </div>
               <div
                  className={classNames({
                     'whitespace-nowrap overflow-hidden text-ellipsis': !useTailwindPrefix,
                     'tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis': useTailwindPrefix,
                  })}
               >
                  {drawSelection() || intl.formatMessage({ id: 'booker.hotel' })}
               </div>
            </Field>
            {myErrors && (
               <div
                  className={classNames({
                     'text-red-400 mt-1 text-sm truncate w-full absolute': !useTailwindPrefix,
                     'tw-text-red-400 tw-mt-1 tw-text-sm tw-truncate tw-w-full tw-absolute': useTailwindPrefix,
                  })}
               >
                  {myErrors?.type === 'required'
                     ? intl.formatMessage({
                          id: `booker.errors.selectHotel${hasMultipleDestinations ? '.destination' : ''}`,
                       })
                     : intl.formatMessage({ id: 'booker.errors.correctFields' })}
               </div>
            )}
         </OverlayTrigger.Trigger>
         <OverlayTrigger.Overlay
            className={classNames({
               'bg-white md:border border-gray-200 z-10 fixed inset-0 md:shadow-lg md:absolute md:mt-0.5 md:min-w-[280px]':
                  !useTailwindPrefix,
               'tw-bg-white md:tw-border tw-border-gray-200 tw-z-10 tw-fixed tw-inset-0 md:tw-shadow-lg md:tw-absolute md:tw-mt-0.5 md:tw-min-w-[280px]':
                  useTailwindPrefix,
               'md:inset-auto': !vertical && !useTailwindPrefix,
               'md:tw-inset-auto': !vertical && useTailwindPrefix,
               'md:inset-y-auto md:left-auto md:right-0': vertical && !useTailwindPrefix,
               'md:tw-inset-y-auto md:tw-left-auto md:tw-right-0': vertical && useTailwindPrefix,
            })}
         >
            <div
               className={classNames({
                  'md:hidden flex justify-between items-center px-4 border-b border-gray-200 h-[50px]':
                     !useTailwindPrefix,
                  'md:tw-hidden tw-flex tw-justify-between tw-items-center tw-px-4 tw-border-b tw-border-gray-200 tw-h-[50px]':
                     useTailwindPrefix,
               })}
            >
               <div className={classNames({ 'text-3xl': !useTailwindPrefix, 'tw-text-3xl': useTailwindPrefix })}>
                  {intl.formatMessage({ id: 'booker.hotel' })}
               </div>
               <button
                  type="button"
                  onClick={closeSelector}
                  className={classNames({
                     'cursor-pointer text-2xl': !useTailwindPrefix,
                     'tw-cursor-pointer tw-text-2xl': useTailwindPrefix,
                  })}
               >
                  <FontAwesomeIcon icon={faTimes} />
               </button>
            </div>
            <div className={bodyClassName}>
               {hasManyResults && (
                  <div className={classNames({ 'p-4': !useTailwindPrefix, 'tw-p-4': useTailwindPrefix })}>
                     <div
                        className={classNames({
                           'border rounded w-full px-2 flex items-center space-x-2': !useTailwindPrefix,
                           'tw-border tw-rounded tw-w-full tw-px-2 tw-flex tw-items-center tw-space-x-2':
                              useTailwindPrefix,
                           'lg:w-1/2': !useTailwindPrefix && hasManyResults,
                           'lg:tw-w-1/2': useTailwindPrefix && hasManyResults,
                        })}
                     >
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                           id="search"
                           placeholder={intl.formatMessage({ id: 'booker.filter.results' })}
                           className={classNames({
                              'py-2 outline-none w-full': !useTailwindPrefix,
                              'tw-py-2 tw-outline-none tw-w-full': useTailwindPrefix,
                           })}
                           value={searchInputValue}
                           onChange={onSearchInputChange}
                        />
                     </div>
                  </div>
               )}
               <div style={parentStyle}>
                  {drawShadows()}
                  <div className={bodyContainerClassName} onScroll={onScrollHandler} ref={containerRef}>
                     {options.map(renderChainOptions)}
                  </div>
               </div>
            </div>
         </OverlayTrigger.Overlay>
      </OverlayTrigger>
   )
}

export default HotelSelector
