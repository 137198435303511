import classNames from 'classnames'
import React, { ChangeEvent, FC, PropsWithChildren } from 'react'

type Props = {
   value?: number | string
   htmlFor?: string
   onChange: Function
   onBlur?: Function
   defaultValue?: number | string
   label?: string
   error?: string
   showErrorText?: boolean
   isTouched?: boolean
   disabled?: boolean
   className?: string
   useTailwindPrefix?: boolean
   boldLabel?: boolean
}

type OptionProps = {
   value: number | string
   disabled?: boolean
}

type LoadingProps = {
   label?: string
   useTailwindPrefix?: boolean
}

interface Composition {
   Option: FC<PropsWithChildren<OptionProps>>
}

export const Select: FC<PropsWithChildren<Props>> & Composition = ({
   label,
   htmlFor,
   value,
   onChange,
   onBlur,
   children,
   defaultValue,
   error,
   isTouched,
   disabled,
   className,
   useTailwindPrefix,
   showErrorText = true,
   boldLabel = true,
}) => {
   function handleOnChange(e: ChangeEvent<HTMLSelectElement>) {
      onChange(e.target.value)
   }

   function handleOnBlur() {
      onBlur && onBlur()
   }

   return (
      <div
         className={classNames(className, {
            'has-error': error,
            'has-success': !error && isTouched,
         })}
      >
         {label && (
            <label
               htmlFor={htmlFor}
               className={classNames({
                  'inline-block mb-1.5': !useTailwindPrefix,
                  'tw-inline-block tw-mb-1.5': useTailwindPrefix,
                  'font-semibold': boldLabel && !useTailwindPrefix,
                  'tw-font-semibold': boldLabel && useTailwindPrefix,
               })}
            >
               {label}
            </label>
         )}
         <select
            className={'form-control'}
            value={value}
            onChange={handleOnChange}
            {...(onBlur && { onBlur: handleOnBlur })}
            {...(defaultValue && { defaultValue })}
            {...(disabled && { disabled: true })}
         >
            {children}
         </select>
         {error && showErrorText && (
            <div
               className={classNames({
                  'error-block text-sm mt-2': !useTailwindPrefix,
                  'tw-error-block tw-text-sm tw-mt-2': useTailwindPrefix,
               })}
            >
               {error}
            </div>
         )}
      </div>
   )
}

const Option: FC<PropsWithChildren<OptionProps>> = ({ value, disabled, children }) => {
   return (
      <option value={value} {...(disabled && { disabled: true })}>
         {children}
      </option>
   )
}

Select.Option = Option

export const SelectLoading = ({ label, useTailwindPrefix }: LoadingProps) => (
   <div
      className={classNames({
         'mb-4': !useTailwindPrefix,
         'tw-mb-4': useTailwindPrefix,
      })}
   >
      {label && (
         <label
            className={classNames({
               'mb-2 inline-block': !useTailwindPrefix,
               'tw-mb-2 tw-inline-block': useTailwindPrefix,
            })}
         >
            {label}
         </label>
      )}
      <select className="form-control" disabled>
         <option>...</option>
      </select>
   </div>
)
