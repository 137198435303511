export default {
   "common.popup.exclusiveOffer": "Today's exclusive offer!",
   "common.popup.usePromoCode": "Use the promo code: {code}",
   "common.popup.applyCode": "Apply promotion",
   "common.popup.noThanks": "No thank you",
   "common.popup.accept": "OK",
   "common.popup.bookNow": "Book now",
   "common.popup.maybeLater": "Maybe later",
   "common.popup.close": "Close",
   "common.popup.ok": "OK",
   "common.rooms.promotion.conditions": "Conditions",
   "common.rooms.promotion.book": "Book from {startMonth} {startDay} to {endMonth} {endDay}",
   "common.rooms.promotion.book.follow": "from {startMonth} {startDay} to {endMonth} {endDay}",
   "common.rooms.promotion.travel": "Stay from {startMonth} {startDay} to {endMonth} {endDay}",
   "common.rooms.promotion.travel.follow": "from {startMonth} {startDay} to {endMonth} {endDay}",
   "common.rooms.promotion.save.text": "{discount} off today",
   "common.rooms.additionalpromotion.save.text": "additional {discount} off ",
   "common.rooms.additionalpromotion.offer": "additional {discount} off",
   "common.rooms.promotion.offer": "{quantity, plural, one {Special offer} other {Special offers}}",
   "common.rooms.promotion.code": "Promotion code",
   "common.rooms.promotion.minors.free": "{quantity, plural, =0 {All minors free} =1 {# free minor} other {# free minors}}",
   "common.rooms.promotion.night.free": "{quantity, plural, =1 {1st night free every {days} days} =2 {2nd night free every {days} days}}",
   "common.rooms.promotion.off": "off",
   "common.rooms.additionalpromotion.byRoom.total.discount": "off by stay",
   "common.rooms.additionalpromotion.byRoom.byNight.discount": "off by room by night",
   "common.rooms.promotion.byRoom.byNight.discount": "off by room by night",
   "common.rooms.promotion.minimum.stay": "Minimum stay of {minimumStay, plural, one {# night} other {# nights}}",
   "common.rooms.promotion.includes": "Includes",
   "common.rooms.promotion.weekdays.0": "monday",
   "common.rooms.promotion.weekdays.1": "tuesday",
   "common.rooms.promotion.weekdays.2": "wednesday",
   "common.rooms.promotion.weekdays.3": "thursday",
   "common.rooms.promotion.weekdays.4": "friday",
   "common.rooms.promotion.weekdays.5": "saturday",
   "common.rooms.promotion.weekdays.6": "sunday",
   "common.rooms.promotion.weekdays.invalid": "Not valid the following days: {weekdays}",
   "common.rooms.promotion.ebb": "Book {days, plural, =1 {# day } other {# days }} in advance and get a {discountPercentage}% discount",
   "common.rooms.promotion.termsAndConditions": "Terms and conditions",
   "common.rooms.promotion.and": "and",
   "common.rooms.promotion.or": "or",
   "common.rooms.promotion.maxNightsFree": "Max {quantity, plural, one {# free night} other {# free nights}}"
};