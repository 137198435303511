import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import classNames from 'classnames'
import { CircleButton } from '../Button'

type Props = {
   value: number
   onChange: Function
   minValue: number
   maxValue?: number
   useTailwindPrefix?: boolean
}

export const CounterSelector = ({ value, onChange, minValue, maxValue, useTailwindPrefix }: Props) => {
   const onAddClick = () => {
      if (!maxValue || value < maxValue) {
         onChange(value + 1)
      }
   }

   const onSubtractClick = () => {
      if (value > minValue) {
         onChange(value - 1)
      }
   }

   return (
      <div
         className={classNames({
            'flex items-center gap-2': !useTailwindPrefix,
            'tw-flex tw-items-center tw-gap-2': useTailwindPrefix,
         })}
      >
         <CircleButton
            bsStyle="outline-primary"
            className={classNames({
               'w-[30px] h-[30px]': !useTailwindPrefix,
               'tw-w-[30px] tw-h-[30px]': useTailwindPrefix,
            })}
            onClick={onSubtractClick}
         >
            <FontAwesomeIcon icon={faMinus} />
         </CircleButton>
         <span
            className={classNames({
               'font-bold': !useTailwindPrefix,
               'tw-font-bold': useTailwindPrefix,
            })}
         >
            {value}
         </span>
         <CircleButton
            bsStyle="outline-primary"
            className={classNames({
               'w-[30px] h-[30px] p-0': !useTailwindPrefix,
               'tw-w-[30px] tw-h-[30px] tw-p-0': useTailwindPrefix,
            })}
            onClick={onAddClick}
         >
            <FontAwesomeIcon icon={faPlus} />
         </CircleButton>
      </div>
   )
}
