import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AddOnListRequest, getAddOnList } from '../api/addOnList'
import { AddOnType } from 'booker/src/partials/AddOn'

export type AddOnListWrapper = {
   isPristine: boolean
   isFetching: boolean
   items: Array<AddOnType>
}

const initialState: AddOnListWrapper = {
   isPristine: true,
   isFetching: false,
   items: []
}

export const getAddOnListThunk = createAsyncThunk(
   'getAddOnList',
   async (
      request: AddOnListRequest,
      { rejectWithValue },
   ) => {
      const response = await getAddOnList(request)
      if (response) {
         return response
      }
      return rejectWithValue(null)
   },
)

export const getAddOnListSlice = createSlice({
   name: 'AddOnList',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getAddOnListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getAddOnListThunk.fulfilled, (state, action) => {
         state.isPristine = false
         state.isFetching = false
         state.items = action.payload
      })
      builder.addCase(getAddOnListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})
export const { clear } = getAddOnListSlice.actions
export default getAddOnListSlice.reducer
