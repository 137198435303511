export const stageData = [
   {
      code: 'ixtul',
      currency: 'MXN',
      language: "es-mx",
      account: {
         code: 'ixtul',
         name: 'Ixtul',
         telephones: ['+52 (998) 282 2365'],
         logoObj: {
            base: 'https://res.cloudinary.com/itermotus/',
            path: 'assets/logo-soulbeach2.jpg',
            width: 175,
            height: 60,
         },
         webSiteUrl: '/',
         containerId: "GTM-KHLBTH8",
         faviconUrl: "https://assets.revenatium.com/hotelsoulbeach/favicon.ico",
         siteUrl: "",
         languages: [{
            flag: "es",
            language: "es-mx",
            name: "Espanol",
            siteUrl: ""
         },
         {
            flag: "en",
            language: "en-us",
            name: "English",
            siteUrl: ""
         }]
      },
      bookerPlaces: [
         { "id": 5, "name": "Ixtul", "uri": "ixtul", "place": { "id": 6, "name": "Cancun" } },
         { "id": 3, "name": "Hotel Soul Beach 3", "uri": "hotelsoulbeach", "place": { "id": 6, "name": "Cancún" } }
      ],
      hotelForm: {
         startDate: null,
         endDate: null,
         rooms: [{ adults: 2, children: 0, childrenAges: [] }],
         maxAdults: 8,
         maxRooms: 3,
         maxChildren: 4,
         minChildrenAge: 0,
         maxChildrenAge: 10,
         quoteRates: false,
         hasMultiDestination: false,
      },
      theme: {
         headerBgColor: '#000000',
         primaryColor: '#8d713a',
      },
      dateFormat: 'MMM D',
      apiUserName: 'cesar.reyes@itermotus.com',
      apiPassword: 'nirvana',
      hasAddons: true,
      showAlerts: true,
      loyaltyEnabled: true
   },
]