import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { Component, createContext, MouseEventHandler, useContext, useEffect, useRef, useState } from 'react'

type GlobalContent = {
   onCloseEvent?: () => void
}
const ModalContext = createContext<GlobalContent>({})
interface propsModal {
   children: React.ReactNode
   visible: boolean
   onClose: () => void
   outerClassName?: string
   size?: 'md' | 'lg'
   useTailwindPrefix?: boolean
   portalClassName?: string
}

type childrenProps = Omit<propsModal, 'onClose' | 'visible' | 'size'> & {
   className?: string
}

class BodyEnd extends Component<{ children: React.ReactNode; className?: string }> {
   el: HTMLDivElement
   constructor(
      props:
         | { children: React.ReactNode; className?: string }
         | Readonly<{ children: React.ReactNode; className?: string }>,
   ) {
      super(props)
      this.el = document.createElement('div')
      this.el.id = 'portal'
      if (props.className) {
         this.el.className = props.className
      }
   }

   componentDidMount() {
      document.body.appendChild(this.el)
   }

   componentWillUnmount() {
      document.body.removeChild(this.el)
   }

   render() {
      const { children } = this.props
      if (!children) {
         return null
      }
      return createPortal(children, this.el)
   }
}

const Modal = ({
   children,
   onClose,
   visible,
   useTailwindPrefix,
   portalClassName,
   outerClassName = '',
   size = 'lg',
}: propsModal): JSX.Element | null => {
   // const ref = useRef<Element | null>(null)
   const [mounted, setMounted] = useState(false)

   useEffect(() => {
      // ref.current = document.querySelector<HTMLElement>(portalId || '#portal')
      setMounted(true)
      return () => {
         if (document && document.body) {
            document.body.style.overflow = ''
            // document.body.className = document.body.className.replace(/ ?overflow-hidden/, '')
         }
      }
   }, [])

   const [on, setOn] = useState(visible)
   let idContainer = 'container-popUp'

   const sizeContentClassName = classNames(
      {
         'relative w-auto m-4 md:mx-auto': !useTailwindPrefix,
         'tw-relative tw-w-auto tw-m-4 md:tw-mx-auto': useTailwindPrefix,
         'md:w-[600px] md:my-[30px]': !outerClassName && !useTailwindPrefix,
         'md:tw-w-[600px] md:tw-my-[30px]': !outerClassName && useTailwindPrefix,
         'lg:w-[900px]': size === 'lg' && !useTailwindPrefix,
         'lg:tw-w-[900px]': size === 'lg' && useTailwindPrefix,
      },
      outerClassName,
   ) //animate-fade-in
   const blackdropClassName = classNames({
      'inset-0 fixed bg-black z-40 flex backdrop-blur-sm transition-opacity duration-300 opacity-0': !useTailwindPrefix,
      'tw-inset-0 tw-fixed tw-bg-black tw-z-40 tw-flex tw-backdrop-blur-sm tw-transition-opacity tw-duration-300 tw-opacity-0':
         useTailwindPrefix,
   })
   const modalContentClassName = classNames({
      'bg-white shadow rounded relative transition-transform duration-300 -translate-y-1/4': !useTailwindPrefix,
      'tw-bg-white tw-shadow tw-rounded tw-relative tw-transition-transform tw-duration-300 -tw-translate-y-1/4':
         useTailwindPrefix,
   })
   useEffect(() => {
      setOn(visible)
      if (visible) {
         if (document && document.body) {
            document.body.style.overflow = 'hidden'
            // let orig = document.body.className
            // if (orig.indexOf('overflow-hidden') < 0) {
            //    document.body.className = orig + (orig ? ' ' : '') + 'overflow-hidden'
            // }
         }
      } else {
         if (document && document.body) {
            document.body.style.overflow = ''
            // document.body.className = document.body.className.replace(/ ?overflow-hidden/, '')
         }
      }
   }, [visible])

   const onCloseEvent = () => {
      setOn(false)
      setTimeout(onClose, 300)
   }

   const handleClose = (event: MouseEventHandler<HTMLDivElement> | any) => {
      const eventCurrent = event.target as HTMLElement
      if (eventCurrent.id === idContainer) onCloseEvent()
   }

   if (mounted && visible) {
      return (
         <BodyEnd className={portalClassName}>
            <div role="dialog">
               <div className={blackdropClassName} {...(on && { style: { opacity: 0.5 } })}></div>
               <div
                  id={idContainer}
                  className={classNames({
                     'fixed inset-0 z-50 overflow-y-auto transition-opacity duration-300 opacity-0': !useTailwindPrefix,
                     'tw-fixed tw-inset-0 tw-z-50 tw-overflow-y-auto tw-transition-opacity tw-duration-300 tw-opacity-0':
                        useTailwindPrefix,
                  })}
                  onClick={handleClose}
                  {...(on && { style: { opacity: 1 } })}
               >
                  <div className={sizeContentClassName}>
                     <div className={modalContentClassName} {...(on && { style: { transform: 'translate(0)' } })}>
                        <ModalContext.Provider value={{ onCloseEvent }}>{children}</ModalContext.Provider>
                     </div>
                  </div>
               </div>
            </div>
         </BodyEnd>
      )
   }
   return null
}

const Body = ({ children, className = 'py-4' }: childrenProps) => {
   return <div className={className}>{children}</div>
}

const Header = ({ children }: childrenProps) => {
   const { onCloseEvent } = useContext(ModalContext)
   return (
      <div className="py-4 flex justify-between border-b items-stretch">
         {children}
         {onCloseEvent && (
            <button type="button" className="focus:outline-none align-middle text-gray-400 px-4" onClick={onCloseEvent}>
               <FontAwesomeIcon icon={faTimes} className="fa-xl" />
            </button>
         )}
      </div>
   )
}

const Footer = ({ children }: childrenProps) => {
   return <div className="py-4 border-t">{children}</div>
}

Modal.Body = Body
Modal.Header = Header
Modal.Footer = Footer

export { Modal }
