import axios from 'axios'

type AccessToken = {
   access_token: string
}
export type AccessTokenRequest = {
   username: string
   password: string
}

export async function getAccessToken(request: AccessTokenRequest): Promise<AccessToken> {
   const config = {
      url: `${process.env.REACT_APP_PUBLIC_CORE_API}/login`,
      method: 'POST',
      data: request,
   }
   const response = await axios.request(config)
   return response.data
}
