export function sortBy(key: any, mode: 'asc' | 'desc' = 'asc') {
   if (mode === 'asc') {
      if (typeof key === 'function') {
         return (a: any, b: any) => (key(a) > key(b) ? 1 : key(b) > key(a) ? -1 : 0)
      }
      return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0)
   }
   if (typeof key === 'function') {
      return (a: any, b: any) => (key(a) < key(b) ? 1 : key(b) < key(a) ? -1 : 0)
   }
   return (a: any, b: any) => (a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0)
}

export function groupBy(xs: any, f: Function) {
   return xs.reduce((r: any, v: any, i: number, a: unknown, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {})
}

export function uniqBy(arr: Array<any>, predicate: Function | string) {
   if (!Array.isArray(arr)) {
      return []
   }

   const cb = typeof predicate === 'function' ? predicate : (o: any) => o[predicate]

   const pickedObjects = arr
      .filter((item) => item)
      .reduce((map, item) => {
         const key = cb(item)
         if (!key) {
            return map
         }
         return map.has(key) ? map : map.set(key, item)
      }, new Map())
      .values()

   return [...pickedObjects]
}

export function capitalize(value: string) {
   if (value.length > 1) {
      return value.charAt(0).toUpperCase() + value.slice(1)
   }
   return value.toUpperCase()
}

export const minBy = <T = any>(arr: Array<T>, func: (x: any) => number) => {
   const min = Math.min(...arr.map(func))
   return arr.find(item => func(item) === min)
}

export function range(length: number, start: number = 0) {
   return Array.from({ length }, (_, i) => i + start)
} 
