import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { getPropertyValue } from 'utils/validation'
import { BookerContext, BookerFormValues, Destination } from '../Booker'
import { DateRangeSelector } from '../Booker/components/DateRangeSelector'
import FieldButton from '../Booker/components/FieldButton'
import FieldContainer from '../Booker/components/FieldContainer'
import HotelSelector, { HotelBookerType } from '../Booker/components/HotelSelector'
import OriginField, { Origin } from '../Booker/components/OriginField'
import { PromoCodeSelector } from '../Booker/components/PromoCodeSelector'
import RoomsSelector, { RoomFormType } from '../Booker/components/RoomsSelector'
import classNames from 'classnames'

export type HotelFlightFormType = {
   destination?: {
      destinationType: 'HOTEL' | 'DESTINATION'
      id: number
   } | null
   startDate?: string | null
   endDate?: string | null
   rooms?: Array<RoomFormType>
   maxAdults: number
   maxRooms: number
   maxChildren: number
   minChildrenAge: number
   maxChildrenAge: number
   hasMultiDestination: boolean
}

type Props = {
   hotelList: Array<HotelBookerType>
   onSubmit: Function
   hotelFlightForm: HotelFlightFormType
   request?: BookerFormValues
   useAlgolia?: boolean
}

export const HotelFlightForm = ({ hotelList, onSubmit, hotelFlightForm, request, useAlgolia }: Props) => {
   const {
      locale,
      dateFormat,
      isMobile,
      numberOfMonths,
      vertical,
      originFetchUrl,
      useTailwindPrefix,
      enableOutsideDays,
      primaryColor,
   } = useContext(BookerContext)
   const intl = useIntl()
   const showHotels = hotelList?.length > 1

   const getDefaultValues = useCallback(
      () => ({
         origin: request?.origin,
         destination: request?.destination,
         rooms:
            request && request.rooms
               ? request.rooms
               : [
                    {
                       adults: 2,
                       children: 0,
                       childrenAges: [],
                    },
                 ],
         tripDates: request?.tripDates,
         promotionCode: request?.promotionCode,
         eventCode: request?.eventCode,
      }),
      [request],
   )
   const methods = useForm<BookerFormValues>({
      defaultValues: getDefaultValues(),
   })

   const {
      formState: { errors },
      setValue,
      getValues,
   } = methods
   const onSubmitEvent = (data: any) => {
      onSubmit && onSubmit(data)
   }

   return (
      <FormProvider {...methods}>
         <form onSubmit={methods.handleSubmit(onSubmitEvent)}>
            <div
               className={classNames({
                  "before:content-[' '] before:table after:block after:content-[''] after:clear-both":
                     !useTailwindPrefix,
                  "before:tw-content-[' '] before:tw-table after:tw-block after:tw-content-[''] after:tw-clear-both":
                     useTailwindPrefix,
               })}
            >
               <div
                  className={classNames({
                     'flex flex-col min-w-[250px]': !useTailwindPrefix,
                     'tw-flex tw-flex-col tw-min-w-[250px]': useTailwindPrefix,
                     'md:flex-row': !vertical && !useTailwindPrefix,
                     'md:tw-flex-row': !vertical && useTailwindPrefix,
                  })}
               >
                  <FieldContainer
                     className={classNames({
                        'md:w-4/12': !showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-4/12': !showHotels && !vertical && useTailwindPrefix,
                        'md:w-3/12': showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-3/12': showHotels && !vertical && useTailwindPrefix,
                     })}
                     clipMargin
                  >
                     <Controller
                        name="origin"
                        rules={{
                           required: intl.formatMessage({ id: 'booker.errors.origin' }),
                           validate: {
                              same: (v?: Origin) => {
                                 const destinationAirportCode = getValues('destination.airportCode')
                                 if (v && destinationAirportCode && v.code === destinationAirportCode) {
                                    return intl.formatMessage({ id: 'booker.errors.origin.same' })
                                 }
                              },
                           },
                        }}
                        render={({ field: { value, onChange } }) => (
                           <OriginField
                              value={value}
                              onChange={onChange}
                              myErrors={getPropertyValue(errors, 'origin')}
                              isMobile={isMobile}
                              onClear={() => setValue('origin', undefined)}
                              fetchUrl={originFetchUrl}
                              useAlgolia={useAlgolia}
                           />
                        )}
                     />
                  </FieldContainer>
                  {showHotels && (
                     <FieldContainer
                        className={classNames({
                           'md:w-3/12': !vertical && !useTailwindPrefix,
                           'md:tw-w-3/12': !vertical && useTailwindPrefix,
                        })}
                        clipMargin
                     >
                        <Controller
                           name="destination"
                           rules={{ required: true }}
                           render={({ field: { value, onChange } }) => (
                              <HotelSelector
                                 selection={value}
                                 hotelList={hotelList}
                                 onSelect={onChange}
                                 myErrors={getPropertyValue(errors, 'destination')}
                                 hasMultiDestination={hotelFlightForm.hasMultiDestination}
                              />
                           )}
                        />
                     </FieldContainer>
                  )}
                  <FieldContainer
                     className={classNames({
                        'md:w-4/12': !showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-4/12': !showHotels && !vertical && useTailwindPrefix,
                        'md:w-3/12': showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-3/12': showHotels && !vertical && useTailwindPrefix,
                     })}
                     clipMargin
                  >
                     <RoomsSelector
                        minRooms={1}
                        maxRooms={hotelFlightForm.maxRooms}
                        minAdults={1}
                        maxAdults={hotelFlightForm.maxAdults}
                        minChildren={0}
                        maxChildren={hotelFlightForm.maxChildren}
                        minChildrenAge={hotelFlightForm.minChildrenAge}
                        maxChildrenAge={hotelFlightForm.maxChildrenAge}
                     />
                  </FieldContainer>
                  <FieldContainer
                     className={classNames({
                        'md:w-4/12': !showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-4/12': !showHotels && !vertical && useTailwindPrefix,
                        'md:w-3/12': showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-3/12': showHotels && !vertical && useTailwindPrefix,
                     })}
                     clipMargin
                  >
                     <Controller
                        name="tripDates"
                        rules={{
                           required: intl.formatMessage({ id: 'booker.errors.dates' }),
                           validate: {
                              emptyStart: (v) =>
                                 (v && v.startDate !== '') ||
                                 intl.formatMessage({ id: `booker.errors.select.outbound` }),
                              emtpyEnd: (v) =>
                                 (v && v.endDate !== '') || intl.formatMessage({ id: `booker.errors.select.return` }),
                           },
                        }}
                        render={({ field: { value, onChange } }) => (
                           <DateRangeSelector
                              id="tripDates"
                              value={value}
                              onChange={onChange}
                              locale={locale}
                              dateRangePickerProps={{
                                 startDatePlaceholderText: intl.formatMessage({
                                    id: 'booker.flight.outboundFlightDate',
                                 }),
                                 endDatePlaceholderText: intl.formatMessage({ id: 'booker.flight.returnFlightDate' }),
                                 displayFormat: dateFormat,
                                 minimumNights: 1,
                                 showClearDates: true,
                                 showDefaultInputIcon: true,
                                 enableOutsideDays,
                              }}
                              myErrors={getPropertyValue(errors, 'tripDates')}
                              isMobile={isMobile}
                              numberOfMonths={numberOfMonths}
                              vertical={vertical}
                              useTailwindPrefix={useTailwindPrefix}
                           />
                        )}
                     />
                  </FieldContainer>
                  <FieldContainer
                     className={classNames({
                        'block mt-3 md:hidden': !useTailwindPrefix,
                        'tw-block tw-mt-3 md:tw-hidden': useTailwindPrefix,
                     })}
                  >
                     <PromoCodeSelector />
                  </FieldContainer>
                  <FieldContainer
                     className={classNames({
                        'md:w-4/12': !showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-4/12': !showHotels && !vertical && useTailwindPrefix,
                        'md:w-3/12': showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-3/12': showHotels && !vertical && useTailwindPrefix,
                     })}
                  >
                     <FieldButton type="submit" vertical={vertical}>
                        {intl.formatMessage({ id: 'booker.show.availability' })}
                     </FieldButton>
                  </FieldContainer>
               </div>
               <div
                  className={classNames({
                     'hidden md:block float-right py-3': !useTailwindPrefix,
                     'tw-hidden md:tw-block tw-float-right tw-py-3': useTailwindPrefix,
                  })}
               >
                  <PromoCodeSelector />
               </div>
            </div>
         </form>
      </FormProvider>
   )
}
