import React, { useCallback, useContext } from 'react'
import ServiceForm, { DetailedServiceFormType } from './ServiceForm'
import { BookerContext, ServiceBookerFormValues } from '../Booker'
import { useIntl } from 'react-intl'
import { Moment } from 'moment'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { getMomentDate } from '../../utils/momentUtils'
import classNames from 'classnames'

type Props = {
   serviceForm: DetailedServiceFormType
   showResume: boolean
   setResume: Function
   isLoading?: boolean
   onSubmit: Function
}

const Col = ({ children }: { children: React.ReactNode }) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   return (
      <div
         className={classNames({
            'pr-4': !useTailwindPrefix,
            'tw-pr-4': useTailwindPrefix,
         })}
      >
         {children}
      </div>
   )
}
Col.Title = ({ children }: { children: React.ReactNode }) => {
   const { useTailwindPrefix } = useContext(BookerContext)
   return (
      <div
         className={classNames({
            ' text-[85%] text-zinc-400': !useTailwindPrefix,
            'tw-text-[85%] tw-text-zinc-400': useTailwindPrefix,
         })}
      >
         {children}
      </div>
   )
}

export const ServiceBooker = ({ showResume, serviceForm, isLoading, setResume }: Props) => {
   const { locale, dateFormat, useTailwindPrefix } = useContext(BookerContext)
   const { date, startDate, endDate, guests, isRangeDate, hasQuantity, quantity, hasNumberPersons } = serviceForm

   const getDefaultValues = useCallback(
      () => ({
         ...(isRangeDate
            ? {
                 dates: {
                    startDate: getMomentDate(startDate, locale)?.format('YYYY-MM-DD'),
                    endDate: getMomentDate(endDate, locale)?.format('YYYY-MM-DD'),
                 },
              }
            : {
                 date: getMomentDate(date, locale)?.format('YYYY-MM-DD'),
              }),
         ...(hasNumberPersons && {
            guests: guests ? Object.assign({}, guests) : { adults: 2, children: 0, childrenAges: [] },
         }),
         ...(hasQuantity && {
            quantity: quantity || 0,
         }),
      }),
      [],
   )

   const methods = useForm<ServiceBookerFormValues>({
      defaultValues: getDefaultValues(),
   })

   const intl = useIntl()

   function getProps() {
      return {
         childrenAgesSelectorProps: {
            minChildrenAge: serviceForm.minChildrenAge || 0,
            maxChildrenAge: serviceForm.maxChildrenAge || 12,
         },
         roomsSelectorProps: {
            maxRooms: 1,
            maxAdults: serviceForm.maxAdults || 8,
            maxChildren: serviceForm.maxChildren || 0,
            noChildrenAges: serviceForm.noChildrenAges,
         },
         datePickerProps: {
            placeholder: intl.formatMessage({ id: 'booker.dayPass.date' }),
            displayFormat: dateFormat,
            id: serviceForm.dateId || 'date',
            showClearDate: true,
            showDefaultInputIcon: true,
            ...(serviceForm.allotmentDates.length > 0 && {
               isDayBlocked: (day: Moment) => {
                  return !serviceForm.allotmentDates.some((date) => day.isSame(date, 'day'))
               },
            }),
         },
         ...(serviceForm.isRangeDate && {
            isRangeDate: true,
            dateRangePickerProps: {
               startDatePlaceholderText: intl.formatMessage({
                  id: 'booker.startDate',
               }),
               endDatePlaceholderText: intl.formatMessage({
                  id: 'booker.endDate',
               }),
               startDateId: serviceForm.startDateId || 'startDateId',
               endDateId: serviceForm.endDateId || 'endDateId',
               showClearDates: true,
               showDefaultInputIcon: true,
               displayFormat: dateFormat,
            },
         }),
         ...(serviceForm.hasQuantity && {
            hasQuantity: true,
            maxQuantity: serviceForm.maxQuantity,
            minQuantity: serviceForm.minQuantity,
         }),
         hasNumberPersons: serviceForm.hasNumberPersons,
         isLoading,
      }
   }

   function onSubmitEvent(data: any) {
      serviceForm.onSubmit && serviceForm.onSubmit(data)
      setResume(true)
   }

   function hideResume(event: React.MouseEvent) {
      event.preventDefault()
      setResume(false)
   }

   function formatDate(startDateString?: string, endDateString?: string) {
      const startDate = getMomentDate(startDateString, locale)
      const endDate = getMomentDate(endDateString, locale)
      if (!startDate || !endDate) {
         return null
      }
      const isSameYear = startDate.isSame(endDate, 'year')
      const isSameMonth = startDate.isSame(endDate, 'month')
      let result = ''
      if (isSameYear) {
         if (isSameMonth) {
            result = `${startDate.format(
               intl.formatMessage({ id: 'booker.resume.date.day.format' }),
            )} ${intl.formatMessage({ id: 'booker.to' })} ${endDate.format(
               intl.formatMessage({ id: 'booker.resume.date.format' }),
            )}`
         } else {
            result = `${startDate.format(
               intl.formatMessage({ id: 'booker.resume.date.month.format' }),
            )} ${intl.formatMessage({ id: 'booker.to' })} ${endDate.format(
               intl.formatMessage({ id: 'booker.resume.date.format' }),
            )}`
         }
      } else {
         result = `${startDate.format(intl.formatMessage({ id: 'booker.resume.date.format' }))} ${intl.formatMessage({
            id: 'booker.to',
         })} ${endDate.format(intl.formatMessage({ id: 'booker.resume.date.format' }))}`
      }
      return result.replace(/\./g, '')
   }

   const formValues = useWatch({ control: methods.control })

   return (
      <FormProvider {...methods}>
         {showResume && (
            <>
               <div
                  className={classNames({
                     'flex justify-between ': !useTailwindPrefix,
                     'tw-flex tw-justify-between ': useTailwindPrefix,
                  })}
               >
                  <Col>
                     {serviceForm.isRangeDate ? (
                        <>
                           <Col.Title>
                              {intl.formatMessage({
                                 id: 'booker.dayPass.dates',
                              })}
                           </Col.Title>
                           {formValues.dates && formatDate(formValues.dates.startDate, formValues.dates.endDate)}
                        </>
                     ) : (
                        <>
                           <Col.Title>
                              {intl.formatMessage({
                                 id: 'booker.dayPass.date',
                              })}
                           </Col.Title>
                           {formValues.date &&
                              getMomentDate(formValues.date)?.format(
                                 intl.formatMessage({
                                    id: 'booker.resume.date.format',
                                 }),
                              )}
                        </>
                     )}
                  </Col>
                  {serviceForm.hasNumberPersons && formValues.guests && (
                     <>
                        <Col>
                           <Col.Title>
                              {intl.formatMessage({
                                 id: serviceForm.maxChildren === 0 ? 'booker.persons' : 'booker.adults',
                              })}
                           </Col.Title>
                           {formValues.guests.adults}
                        </Col>
                        {serviceForm.maxChildren !== 0 && (
                           <Col>
                              <Col.Title>
                                 {intl.formatMessage({
                                    id: 'booker.minors',
                                 })}
                              </Col.Title>
                              {formValues.guests.children}
                           </Col>
                        )}
                     </>
                  )}
                  {serviceForm.hasQuantity && formValues.quantity && (
                     <Col>
                        <Col.Title>{intl.formatMessage({ id: 'booker.quantity' })}</Col.Title>
                        {formValues.quantity}
                     </Col>
                  )}
               </div>
               <small
                  onClick={hideResume}
                  className={classNames({
                     'text-link-blue cursor-pointer': !useTailwindPrefix,
                     'tw-text-link-blue tw-cursor-pointer': useTailwindPrefix,
                  })}
               >
                  {intl.formatMessage({ id: 'booker.change' })}
               </small>
            </>
         )}
         {!showResume && (
            <form onSubmit={methods.handleSubmit(onSubmitEvent)}>
               <ServiceForm {...getProps()} />
            </form>
         )}
      </FormProvider>
   )
}
