import { HotelBookerRatesDate } from "booker"
import { apiCall } from "../utils/api"

export type HotelBookerRatesRequest = {
   from: string
   to: string
   adults: number
   children: number
   hotelId?: number
   placeId?: number
   channelId?: number
}

export type HotelBookerRatesResponse = {
   rates: {
      [date: string]: HotelBookerRatesDate
   }
   lastProcessedDate: string
   currency: string
}

export async function getHotelBookerRates(request: HotelBookerRatesRequest): Promise<HotelBookerRatesResponse | null> {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_HOTELS_API}/rates`,
         method: 'GET',
         params: request,
      })
      if (result.status !== 200) {
         throw new Error(`error ${result.status}`)
      }
      return result.data
   } catch (error) {
      return null
   }
}

