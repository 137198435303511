import { useRef, useEffect } from 'react'

// useTransition :: Array a => (a -> Void, a) -> Void
//                              |_______|  |
//                                  |      |
//                              callback  deps
//
// The useTransition hook is similar to the useEffect hook. It requires
// a callback function and an array of dependencies. Unlike the useEffect
// hook, the callback function is only called when the dependencies change.
// Hence, it's not called when the component mounts because there is no change
// in the dependencies. The callback function is supplied the previous array of
// dependencies which it can use to perform transition-based effects.
export default function useTransition(callback: Function, deps: any[]) {
   const func = useRef<Function | null>(null)

   useEffect(() => {
      func.current = callback
   }, [callback])

   const args = useRef<any[] | null>(null)

   useEffect(() => {
      if (args.current !== null) func.current?.(...args.current)
      args.current = deps
   }, deps)
}